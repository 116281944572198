import {all, call, put, select, takeLatest} from 'redux-saga/effects';

import {
  addGeneralProductError,
  addGeneralProductSuccess,
  deleteGeneralProductError,
  deleteGeneralProductSuccess,
  getMyGeneralProductsSuccess,
  getQuickProductsError,
  getQuickProductsSuccess,
  getStoreCategoriesSuccess,
  getStoreSubCategoriesError,
  getStoreSubCategoriesSuccess,
  searchGeneralProductsError,
  searchGeneralProductsSuccess,
  updateGeneralProductAvailabilitySuccess,
  updateGeneralProductSuccess,
} from './productActions';
import {
  ADD_GENERAL_PRODUCT,
  DELETE_GENERAL_PRODUCT,
  GET_CATEGORY_METADATA,
  GET_MY_GENERAL_PRODUCTS,
  GET_STORE_CATEGORIES,
  GET_STORE_SUB_CATEGORIES,
  SEARCH_GENERAL_PRODUCTS,
  UPDATE_GENERAL_PRODUCT_AVAILABILITY,
  UPDATE_GENERAL_PRODUCT_DETAILS,
} from '../../utils/ApiList';
import Api from '../../middlewares/Api';
import productActionTypes from './productActionTypes';
import authActionTypes from '../auth/authActionTypes';
import bannerActionTypes from '../banner/bannerActionTypes';
import {getUserBasicInfo} from '../rootSelector';
import onBoardingActionTypes from '../onBoarding/onBoardingActionTypes';
import onStoreActionTypes from '../store/storeActionTypes';

const getStoreCategoriesApiCall = async (productType, payload) => {
  const response = await Api.post(
    `${GET_STORE_CATEGORIES}?product_type=${productType}`,
    payload,
  );
  return response.data;
};

const getStoreSubCategoriesApiCall = async (payload, basic_info) => {
  const response = await Api.post(
    `${GET_STORE_SUB_CATEGORIES}?product_type=${payload}`,
    basic_info,
  );
  return response.data;
};

const addGeneralProductApiCall = async payload => {
  const response = await Api.post(ADD_GENERAL_PRODUCT, payload);
  return response.data;
};

const updateGeneralProductApiCall = async payload => {
  const response = await Api.post(UPDATE_GENERAL_PRODUCT_DETAILS, payload);
  return response.data;
};

const updateGeneralProductAvailabiltyApiCall = async ({data, payload}) => {
  const response = await Api.post(
    `${UPDATE_GENERAL_PRODUCT_AVAILABILITY}?product_id=${payload.product_id}&product_subcategory=${payload.product_subcategory}&availability=${payload.availability}`,
    data,
  );
  return response.data;
};

const deleteGeneralProductApiCall = async ({data, ownerDetails}) => {
  const response = await Api.post(
    `${DELETE_GENERAL_PRODUCT}?product_id=${data.product_id}&product_category=${data.product_subcategory}`,
    ownerDetails,
  );
  return response.data;
};

const getMyGeneralProductsApiCall = async payload => {
  const response = await Api.post(GET_MY_GENERAL_PRODUCTS, payload);
  return response.data;
};

const searchGeneralProductsApiCall = async payload => {
  const response = await Api.post(SEARCH_GENERAL_PRODUCTS, payload);
  return response.data;
};

const getQuickProductsApiCall = async payload => {
  const response = await Api.post(`${GET_CATEGORY_METADATA}`, payload);
  return response.data;
};

export function* getStoreCategories() {
  try {
    const basic_info = yield select(getUserBasicInfo);
    if (basic_info?.LoB && basic_info?.LoB !== 'null') {
      const storeCategories = yield getStoreCategoriesApiCall(
        'catalog',
        basic_info,
      );
      yield put(getStoreCategoriesSuccess(storeCategories));
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* getStoreSubCategories({payload}) {
  try {
    const basic_info = yield select(getUserBasicInfo);
    if (basic_info?.LoB && basic_info?.LoB !== 'null') {
      const storeSubCategories = yield getStoreSubCategoriesApiCall(
        payload,
        basic_info,
      );
      yield put(
        getStoreSubCategoriesSuccess({
          type: payload,
          data: storeSubCategories,
        }),
      );
    }
  } catch (error) {
    yield put(getStoreSubCategoriesError(error));
  }
}

export function* addGeneralProduct({payload, action}) {
  try {
    const basic_info = yield select(getUserBasicInfo);
    yield addGeneralProductApiCall({
      ...payload,
      data: basic_info,
    });
    yield put(addGeneralProductSuccess());
    if (action) {
      action.navigate('Dashboard', {
        params: {tab: 'catalogs', subTab: 'products'},
      });
    }
  } catch (error) {
    yield put(addGeneralProductError(error));
  }
}

export function* updateGeneralProduct({payload, action}) {
  try {
    const basic_info = yield select(getUserBasicInfo);
    yield updateGeneralProductApiCall({
      ...payload,
      data: basic_info,
    });

    yield put(updateGeneralProductSuccess());

    if (action) {
      action.navigate('Dashboard', {
        params: {tab: 'catalogs', subTab: 'products'},
      });
    }
  } catch (error) {
    console.log('Error', error);
  }
}

export function* updateGeneralProductAvailability({payload}) {
  try {
    const basic_info = yield select(getUserBasicInfo);
    yield updateGeneralProductAvailabiltyApiCall({
      ...payload,
      data: basic_info,
    });
    yield put(updateGeneralProductAvailabilitySuccess());
  } catch (error) {
    console.log('Error', error);
  }
}

export function* deleteGeneralProduct({payload, action}) {
  try {
    yield deleteGeneralProductApiCall(payload);
    yield put(deleteGeneralProductSuccess());
  } catch (error) {
    yield put(deleteGeneralProductError(error));
  }
}

export function* getMyGeneralProducts({payload}) {
  try {
    let basic_info = yield select(getUserBasicInfo);

    if (payload?.LoB && payload?.LoB !== 'null') {
      basic_info = payload;
      payload = {};
    }

    const response = yield getMyGeneralProductsApiCall({
      data: basic_info,
      product: {
        sub_category: [],
        availability: '',
        min_discount_price: -1,
        max_discount_price: -1,
        brand_name: '',
        size: [],
        color: [],
        min_discount_percent: -1,
        offer_type: '',
        sort_by: {
          discount_price: '',
          views: '',
          create_time: '',
        },
        ...payload,
      },
    });
    yield put(getMyGeneralProductsSuccess(response));
  } catch (error) {
    console.log('Error', error);
  }
}

export function* searchGeneralProducts({payload}) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const banners = yield searchGeneralProductsApiCall({
      basic_info: {
        ...basicInfo,
        district: 'Gautam Buddha Nagar',
        LoB: 'Stationery',
        lat: 28.5355161,
        lon: 77.4910265,
        dp: '',
      },
      ...payload,
    });
    yield put(searchGeneralProductsSuccess(banners));
  } catch (error) {
    yield put(searchGeneralProductsError(error));
  }
}

export function* getQuickProducts({payload}) {
  try {
    const basic_info = yield select(getUserBasicInfo);
    const response = yield getQuickProductsApiCall({
      data: {biz_name: '', ...basic_info},
      ...payload,
    });
    yield put(getQuickProductsSuccess(response));
  } catch (error) {
    yield put(getQuickProductsError(error));
  }
}

export function* onGetStoreCategories() {
  yield takeLatest(
    [
      productActionTypes.GET_STORE_CATEGORIES,
      authActionTypes.VALIDATE_OTP_SUCCESS,
      bannerActionTypes.SAVE_DRAFT_BANNER,
    ],
    getStoreCategories,
  );
}

export function* onGetStoreSubCategories() {
  yield takeLatest(
    [
      productActionTypes.GET_STORE_SUB_CATEGORIES,
      authActionTypes.VALIDATE_OTP_SUCCESS,
    ],
    getStoreSubCategories,
  );
}

export function* onAddGeneralProduct() {
  yield takeLatest(productActionTypes.ADD_GENERAL_PRODUCT, addGeneralProduct);
}

export function* onUpdateGeneralProduct() {
  yield takeLatest(
    productActionTypes.UPDATE_GENERAL_PRODUCT,
    updateGeneralProduct,
  );
}

export function* onUpdateGeneralProductAvailability() {
  yield takeLatest(
    productActionTypes.UPDATE_GENERAL_PRODUCT_AVAILABILITY,
    updateGeneralProductAvailability,
  );
}

export function* onDeleteGeneralProduct() {
  yield takeLatest(
    productActionTypes.DELETE_GENERAL_PRODUCT,
    deleteGeneralProduct,
  );
}

export function* onGetMyGeneralProducts() {
  yield takeLatest(
    [
      productActionTypes.GET_MY_GENERAL_PRODUCTS,
      productActionTypes.ADD_GENERAL_PRODUCT_SUCCESS,
      productActionTypes.UPDATE_GENERAL_PRODUCT_SUCCESS,
      productActionTypes.UPDATE_GENERAL_PRODUCT_AVAILABILITY_SUCCESS,
      productActionTypes.DELETE_GENERAL_PRODUCT_SUCCESS,
      // onBoardingActionTypes.UPDATE_APP_LOB,
      authActionTypes.SET_OWNER_BASIC_INFO,
      onStoreActionTypes.ADD_QUICK_PRODUCTS_SUCCESS,
    ],
    getMyGeneralProducts,
  );
}

export function* onSearchGeneralProducts() {
  yield takeLatest(
    productActionTypes.SEARCH_GENERAL_PRODUCTS,
    searchGeneralProducts,
  );
}

export function* onGetQuickProducts() {
  yield takeLatest(productActionTypes.GET_QUICK_PRODUCTS, getQuickProducts);
}

export function* productSagas() {
  yield all([
    call(onGetStoreCategories),
    call(onGetStoreSubCategories),
    call(onAddGeneralProduct),
    call(onUpdateGeneralProduct),
    call(onUpdateGeneralProductAvailability),
    call(onDeleteGeneralProduct),
    call(onGetMyGeneralProducts),
    call(onSearchGeneralProducts),
    call(onGetQuickProducts),
  ]);
}
