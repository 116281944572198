const bannerActionTypes = {
  GET_OVERALL_STORE_STATS: 'GET_OVERALL_STORE_STATS',
  GET_OVERALL_STORE_STATS_SUCCESS: 'GET_OVERALL_STORE_STATS_SUCCESS',
  GET_OVERALL_STORE_STATS_ERROR: 'GET_OVERALL_STORE_STATS_ERROR',

  GET_CATALOG_DAILY_TRENDS: 'GET_CATALOG_DAILY_TRENDS',
  GET_CATALOG_DAILY_TRENDS_SUCCESS: 'GET_CATALOG_DAILY_TRENDS_SUCCESS',
  GET_CATALOG_DAILY_TRENDS_ERROR: 'GET_CATALOG_DAILY_TRENDS_ERROR',

  GET_FINANCIAL_DAILY_TRENDS: 'GET_FINANCIAL_DAILY_TRENDS',
  GET_FINANCIAL_DAILY_TRENDS_SUCCESS: 'GET_FINANCIAL_DAILY_TRENDS_SUCCESS',
  GET_FINANCIAL_DAILY_TRENDS_ERROR: 'GET_FINANCIAL_DAILY_TRENDS_ERROR',

  GET_BANNER_DAILY_TRENDS: 'GET_BANNER_DAILY_TRENDS',
  GET_BANNER_DAILY_TRENDS_SUCCESS: 'GET_BANNER_DAILY_TRENDS_SUCCESS',
  GET_BANNER_DAILY_TRENDS_ERROR: 'GET_BANNER_DAILY_TRENDS_ERROR',

  GET_STORE_SOCIAL_HEALTH: 'GET_STORE_SOCIAL_HEALTH',
  GET_STORE_SOCIAL_HEALTH_SUCCESS: 'GET_STORE_SOCIAL_HEALTH_SUCCESS',
  GET_STORE_SOCIAL_HEALTH_ERROR: 'GET_STORE_SOCIAL_HEALTH_ERROR',

  GET_CATALOG_INSIGHTS: 'GET_CATALOG_INSIGHTS',
  GET_CATALOG_INSIGHTS_SUCCESS: 'GET_CATALOG_INSIGHTS_SUCCESS',
  GET_CATALOG_INSIGHTS_ERROR: 'GET_CATALOG_INSIGHTS_ERROR',

  GET_CATALOG_TOP_PRODUCTS: 'GET_CATALOG_TOP_PRODUCTS',
  GET_CATALOG_TOP_PRODUCTS_SUCCESS: 'GET_CATALOG_TOP_PRODUCTS_SUCCESS',
  GET_CATALOG_TOP_PRODUCTS_ERROR: 'GET_CATALOG_TOP_PRODUCTS_ERROR',

  GET_BANNER_INSIGHTS: 'GET_BANNER_INSIGHTS',
  GET_BANNER_INSIGHTS_SUCCESS: 'GET_BANNER_INSIGHTS_SUCCESS',
  GET_BANNER_INSIGHTS_ERROR: 'GET_BANNER_INSIGHTS_ERROR',

  GET_ALL_CATALOG_DAILY_TRENDS: 'GET_ALL_CATALOG_DAILY_TRENDS',
  GET_ALL_CATALOG_DAILY_TRENDS_SUCCESS: 'GET_ALL_CATALOG_DAILY_TRENDS_SUCCESS',
  GET_ALL_CATALOG_DAILY_TRENDS_ERROR: 'GET_ALL_CATALOG_DAILY_TRENDS_ERROR',

  GET_ALL_BANNER_DAILY_TRENDS: 'GET_ALL_BANNER_DAILY_TRENDS',
  GET_ALL_BANNER_DAILY_TRENDS_SUCCESS: 'GET_ALL_BANNER_DAILY_TRENDS_SUCCESS',
  GET_ALL_BANNER_DAILY_TRENDS_ERROR: 'GET_ALL_BANNER_DAILY_TRENDS_ERROR',

  GET_OVERALL_STATS: 'GET_OVERALL_STATS',
  GET_OVERALL_STATS_SUCCESS: 'GET_OVERALL_STATS_SUCCESS',
  GET_OVERALL_STATS_ERROR: 'GET_OVERALL_STATS_ERROR',
};

export default bannerActionTypes;
