import {all, call, put, select, takeLatest} from 'redux-saga/effects';

import {
  createBannerError,
  createBannerSuccess,
  getBannerTemplatesSuccess,
  getMyBannersError,
  getMyBannersSuccess,
  getOfferingTypesSuccess,
  getOfferKindSuccess,
  getOfferKindError,
  updateBannerSuccess,
  deleteBannerError,
  disableBannerError,
  disableBannerSuccess,
  deleteBannerSuccess,
  enableBannerSuccess,
  enableBannerError,
  searchBannersSuccess,
  searchBannersError,
} from './bannerActions';
import {
  CREATE_BANNER,
  DELETE_BANNER,
  DISABLE_BANNER,
  GET_BANNER_TEMPLATES,
  GET_MY_BANNERS,
  GET_OFFERRING_TYPES,
  GET_OFFER_KIND,
  PUBLISH_BANNER,
  SEARCH_BANNERS,
  UPDATE_BANNER_DETAILS,
} from '../../utils/ApiList';
import Api from '../../middlewares/Api';
import bannerActionTypes from './bannerActionTypes';
import {getUserBasicInfo} from '../rootSelector';
import onBoardingActionTypes from '../onBoarding/onBoardingActionTypes';
import userActionTypes from '../auth/authActionTypes';

const getBannerTemplatesApiCall = async () => {
  const response = await Api.get(GET_BANNER_TEMPLATES);
  return response.data;
};

const createBannerApiCall = async payload => {
  const response = await Api.post(CREATE_BANNER, payload);
  return response.data;
};

const publishBannerApiCall = async (bannerId, data) => {
  const response = await Api.post(
    `${PUBLISH_BANNER}?banner_id=${bannerId}`,
    data,
  );
  return response.data;
};

const getMyBannersApiCall = async payload => {
  const response = await Api.post(`${GET_MY_BANNERS}?page=1&size=50`, payload);
  return response.data;
};

const getOfferingTypesApiCall = async () => {
  const response = await Api.post(GET_OFFERRING_TYPES);
  return response.data;
};

const getOfferKindApiCall = async () => {
  const response = await Api.get(GET_OFFER_KIND);
  return response.data;
};

const updateBannerApiCall = async (bannerId, data) => {
  const response = await Api.post(
    `${UPDATE_BANNER_DETAILS}?banner_id=${bannerId}`,
    data,
  );
  return response.data;
};

const deleteBannerApiCall = async (bannerId, data) => {
  const response = await Api.post(
    `${DELETE_BANNER}?banner_id=${bannerId}`,
    data,
  );
  return response.data;
};

const enableBannerApiCall = async (bannerId, data) => {
  const response = await Api.post(
    `${DISABLE_BANNER}?banner_id=${bannerId}&enabled=true`,
    data,
  );
  return response.data;
};

const disableBannerApiCall = async (bannerId, data) => {
  const response = await Api.post(
    `${DISABLE_BANNER}?banner_id=${bannerId}&enabled=false`,
    data,
  );
  return response.data;
};

const searchBannersApiCall = async (payload, owner_id) => {
  const response = await Api.post(
    `${SEARCH_BANNERS}?owner_id=${owner_id ? owner_id : -1}&page=1&size=50`,
    payload,
  );
  return response.data;
};

export function* getBannerTemplates() {
  try {
    const templates = yield getBannerTemplatesApiCall();
    yield put(getBannerTemplatesSuccess(templates));
  } catch (error) {
    console.log('Error', error);
  }
}

export function* createBanner({payload, action}) {
  try {
    const basic_info = yield select(getUserBasicInfo);
    const data = yield createBannerApiCall({
      ...payload,
      data: basic_info,
    });

    yield publishBannerApiCall(data, basic_info);
    if (action) {
      action.navigate('BannerCongratulation');
    }
    yield put(createBannerSuccess({bannerId: data, ...payload}));
  } catch (error) {
    yield put(createBannerError(error));
  }
}

export function* getMyBanners({payload}) {
  try {
    let basicInfo = yield select(getUserBasicInfo);
    if (payload?.LoB && payload?.LoB !== 'null') {
      basicInfo = payload;
    }
    const banners = yield getMyBannersApiCall(basicInfo);
    yield put(getMyBannersSuccess(banners));
  } catch (error) {
    yield put(getMyBannersError(error));
  }
}

export function* getOfferingTypes() {
  try {
    const offerringTypes = yield getOfferingTypesApiCall();
    yield put(getOfferingTypesSuccess(offerringTypes));
  } catch (error) {
    console.log('Error', error);
  }
}

export function* getOfferKind() {
  try {
    const offerKind = yield getOfferKindApiCall();
    yield put(getOfferKindSuccess(offerKind));
  } catch (error) {
    yield put(getOfferKindError(error));
  }
}

export function* updateBanner({payload, action}) {
  try {
    const basic_info = yield select(getUserBasicInfo);
    yield updateBannerApiCall(payload?.banner_id, {
      ...payload,
      data: basic_info,
    });
    if (action) {
      action.navigate('Dashboard', {params: {tab: 'banners'}});
    }
    yield put(updateBannerSuccess());
  } catch (error) {
    yield put(updateBannerSuccess(error));
  }
}

export function* deleteBanner({payload}) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    yield deleteBannerApiCall(payload, basicInfo);
    yield put(deleteBannerSuccess());
  } catch (error) {
    yield put(deleteBannerError(error));
  }
}

export function* enableBanner({payload}) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    yield enableBannerApiCall(payload, basicInfo);
    yield put(enableBannerSuccess());
  } catch (error) {
    yield put(enableBannerError(error));
  }
}

export function* disableBanner({payload}) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    yield disableBannerApiCall(payload, basicInfo);
    yield put(disableBannerSuccess());
  } catch (error) {
    yield put(disableBannerError(error));
  }
}

export function* searchBanners({payload}) {
  try {
    const mainPayload = payload?.payload;
    const ownerId = payload?.ownerId;
    const basicInfo = yield select(getUserBasicInfo);
    const banners = yield searchBannersApiCall(
      {
        data: {
          ...basicInfo,
          id: basicInfo?.id ?? '',
          district: basicInfo?.district ?? '',
          LoB: basicInfo?.LoB ?? '',
        },
        product_category: '',
        brand: '',
        geog_type: '',
        geog_name: '',
        distance: -1,
        offer_type: '',
        min_discount_percent: -1,
        sort: {
          distance_sort: '',
          discount_sort: '',
          likes_sort: '',
        },
        ...mainPayload,
      },
      ownerId,
    );
    yield put(searchBannersSuccess(banners));
  } catch (error) {
    yield put(searchBannersError(error));
  }
}

export function* onGetBannerTemplates() {
  yield takeLatest(bannerActionTypes.GET_BANNER_TEMPLATES, getBannerTemplates);
}

export function* onCreateBanner() {
  yield takeLatest(bannerActionTypes.CREATE_BANNER, createBanner);
}

export function* onGetMyBanners() {
  yield takeLatest(
    [
      bannerActionTypes.GET_MY_BANNERS,
      bannerActionTypes.CREATE_BANNER_SUCCESS,
      bannerActionTypes.UPDATE_BANNER_DETAILS_SUCCESS,
      bannerActionTypes.DELETE_BANNER_SUCCESS,
      bannerActionTypes.ENABLE_BANNER_SUCCESS,
      bannerActionTypes.DISABLE_BANNER_SUCCESS,
      // onBoardingActionTypes.UPDATE_APP_LOB,
      userActionTypes.SET_OWNER_BASIC_INFO,
    ],
    getMyBanners,
  );
}

export function* ongetOfferringTypes() {
  yield takeLatest(bannerActionTypes.GET_OFFERRING_TYPES, getOfferingTypes);
}

export function* onGetOfferKind() {
  yield takeLatest(bannerActionTypes.GET_OFFER_KIND, getOfferKind);
}

export function* onUpdateBanner() {
  yield takeLatest(bannerActionTypes.UPDATE_BANNER_DETAILS, updateBanner);
}

export function* onDeleteBanner() {
  yield takeLatest(bannerActionTypes.DELETE_BANNER, deleteBanner);
}

export function* onEnableBanner() {
  yield takeLatest(bannerActionTypes.ENABLE_BANNER, enableBanner);
}

export function* onDisableBanner() {
  yield takeLatest(bannerActionTypes.DISABLE_BANNER, disableBanner);
}

export function* onSearchBanners() {
  yield takeLatest(
    [bannerActionTypes.SEARCH_BANNERS, userActionTypes.CHANGE_LOB],
    searchBanners,
  );
}

export function* bannerSagas() {
  yield all([
    call(onGetBannerTemplates),
    call(onCreateBanner),
    call(onGetMyBanners),
    call(ongetOfferringTypes),
    call(onGetOfferKind),
    call(onUpdateBanner),
    call(onDeleteBanner),
    call(onEnableBanner),
    call(onDisableBanner),
    call(onSearchBanners),
  ]);
}
