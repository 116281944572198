import types from './followActionTypes';

export const bannerClicked = payload => {
  return {
    type: types.BANNER_CLICKED,
    payload: payload,
  };
};

export const bannerClickedSuccess = data => {
  return {
    type: types.BANNER_CLICKED_SUCCESS,
    payload: data,
  };
};

export const bannerClickedError = data => {
  return {
    type: types.BANNER_CLICKED_ERROR,
    payload: data,
  };
};

export const catalogClicked = payload => {
  return {
    type: types.CATALOG_CLICKED,
    payload: payload,
  };
};

export const catalogClickedSuccess = data => {
  return {
    type: types.CATALOG_CLICKED_SUCCESS,
    payload: data,
  };
};

export const catalogClickedError = data => {
  return {
    type: types.CATALOG_CLICKED_ERROR,
    payload: data,
  };
};

export const followStore = payload => {
  return {
    type: types.FOLLOW_STORE,
    payload: payload,
  };
};

export const followStoreSuccess = data => {
  return {
    type: types.FOLLOW_STORE_SUCCESS,
    payload: data,
  };
};

export const followStoreError = data => {
  return {
    type: types.FOLLOW_STORE_ERROR,
    payload: data,
  };
};

export const viewedStoreProfile = payload => {
  return {
    type: types.VIEWED_STORE_PROFILE,
    payload: payload,
  };
};

export const viewedStoreProfileSuccess = data => {
  return {
    type: types.VIEWED_STORE_PROFILE_SUCCESS,
    payload: data,
  };
};

export const viewedStoreProfileError = data => {
  return {
    type: types.VIEWED_STORE_PROFILE_ERROR,
    payload: data,
  };
};

export const viewedPhoneNumber = payload => {
  return {
    type: types.VIEWED_PHONE_NUMBER,
    payload: payload,
  };
};

export const viewedPhoneNumberSuccess = data => {
  return {
    type: types.VIEWED_PHONE_NUMBER_SUCCESS,
    payload: data,
  };
};

export const viewedPhoneNumberError = data => {
  return {
    type: types.VIEWED_PHONE_NUMBER_ERROR,
    payload: data,
  };
};

export const getStoreCoupon = payload => {
  return {
    type: types.GET_STORE_COUPON,
    payload: payload,
  };
};

export const getStoreCouponSuccess = data => {
  return {
    type: types.GET_STORE_COUPON_SUCCESS,
    payload: data,
  };
};

export const getStoreCouponError = data => {
  return {
    type: types.GET_STORE_COUPON_ERROR,
    payload: data,
  };
};
