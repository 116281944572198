import types from "./catalogActionTypes";

export const createCatalog = (body, navigation) => {
  return {
    type: types.CREATE_CATALOG,
    payload: body,
    action: navigation,
  };
};

export const createCatalogSuccess = (data) => {
  return {
    type: types.CREATE_CATALOG_SUCCESS,
    payload: data,
  };
};

export const createCatalogError = (data) => {
  return {
    type: types.CREATE_CATALOG_ERROR,
    payload: data,
  };
};

export const addCatalogProduct = (body, navigation) => {
  return {
    type: types.ADD_CATALOG_PRODUCT,
    payload: body,
    action: navigation,
  };
};

export const addCatalogProductSuccess = (data) => {
  return {
    type: types.ADD_CATALOG_PRODUCT_SUCCESS,
    payload: data,
  };
};

export const addCatalogProductError = (data) => {
  return {
    type: types.ADD_CATALOG_PRODUCT_ERROR,
    payload: data,
  };
};

export const getMyCatalogs = (body) => {
  return {
    type: types.GET_MY_CATALOGS,
    payload: body,
  };
};

export const getMyCatalogsSuccess = (data) => {
  return {
    type: types.GET_MY_CATALOGS_SUCCESS,
    payload: data,
  };
};

export const getMyCatalogsError = (data) => {
  return {
    type: types.GET_MY_CATALOGS_ERROR,
    payload: data,
  };
};

export const updateCatalogProduct = (body, navigation) => {
  return {
    type: types.UPDATE_CATALOG_PRODUCT,
    payload: body,
    action: navigation,
  };
};

export const updateCatalogProductSuccess = (body) => {
  return {
    type: types.UPDATE_CATALOG_PRODUCT_SUCCESS,
    payload: body,
  };
};

export const updateCatalogProductError = (body) => {
  return {
    type: types.UPDATE_CATALOG_PRODUCT_ERROR,
    payload: body,
  };
};

export const updateCatalogProductAvailability = (body) => {
  return {
    type: types.UPDATE_CATALOG_PRODUCT_AVAILABILITY,
    payload: body,
  };
};

export const updateCatalogProductAvailabilitySuccess = (body) => {
  return {
    type: types.UPDATE_CATALOG_PRODUCT_AVAILABILITY_SUCCESS,
    payload: body,
  };
};

export const updateCatalogProductAvailabilityError = (body) => {
  return {
    type: types.UPDATE_CATALOG_PRODUCT_AVAILABILITY_ERROR,
    payload: body,
  };
};

export const editCatalogSubCategory = (body, navigation) => {
  return {
    type: types.EDIT_CATALOG_SUBCATEGORY,
    payload: body,
    action: navigation,
  };
};

export const editCatalogSubCategorySuccess = (body) => {
  return {
    type: types.EDIT_CATALOG_SUBCATEGORY_SUCCESS,
    payload: body,
  };
};

export const editCatalogSubCategoryError = (body) => {
  return {
    type: types.EDIT_CATALOG_SUBCATEGORY_ERROR,
    payload: body,
  };
};

export const enableCatalog = (body) => {
  return {
    type: types.ENABLE_CATALOG,
    payload: body,
  };
};

export const enableCatalogSuccess = (body) => {
  return {
    type: types.ENABLE_CATALOG_SUCCESS,
    payload: body,
  };
};

export const enableCatalogError = (body) => {
  return {
    type: types.ENABLE_CATALOG_ERROR,
    payload: body,
  };
};

export const disableCatalog = (body) => {
  return {
    type: types.DISABLE_CATALOG,
    payload: body,
  };
};

export const disableCatalogSuccess = (body) => {
  return {
    type: types.DISABLE_CATALOG_SUCCESS,
    payload: body,
  };
};

export const disableCatalogError = (body) => {
  return {
    type: types.DISABLE_CATALOG_ERROR,
    payload: body,
  };
};

export const deleteCatalogProduct = (body, action) => {
  return {
    type: types.DELETE_CATALOG_PRODUCT,
    payload: body,
    action: action,
  };
};

export const deleteCatalogProductSuccess = (body) => {
  return {
    type: types.DELETE_CATALOG_PRODUCT_SUCCESS,
    payload: body,
  };
};

export const deleteCatalogProductError = (body) => {
  return {
    type: types.DELETE_CATALOG_PRODUCT_ERROR,
    payload: body,
  };
};

export const deleteCatalog = (body) => {
  return {
    type: types.DELETE_CATALOG,
    payload: body,
  };
};

export const deleteCatalogSuccess = (body) => {
  return {
    type: types.DELETE_CATALOG_SUCCESS,
    payload: body,
  };
};

export const deleteCatalogError = (body) => {
  return {
    type: types.DELETE_CATALOG_ERROR,
    payload: body,
  };
};

export const searchCatalogs = (payload, ownerId, pagination, lob) => {
  return {
    type: types.SEARCH_CATALOGS,
    payload: { payload, ownerId, pagination, lob },
  };
};

export const searchCatalogsSuccess = (body) => {
  return {
    type: types.SEARCH_CATALOGS_SUCCESS,
    payload: body,
  };
};

export const searchCatalogsError = (body) => {
  return {
    type: types.SEARCH_CATALOGS_ERROR,
    payload: body,
  };
};

export const getNearByStores = (payload, lob) => {
  return {
    type: types.GET_NEARBY_STORES,
    payload: { payload, lob },
  };
};

export const getNearByStoresSuccess = (body) => {
  return {
    type: types.GET_NEARBY_STORES_SUCCESS,
    payload: body,
  };
};

export const getNearByStoresError = (body) => {
  return {
    type: types.GET_NEARBY_STORES_ERROR,
    payload: body,
  };
};

export const getCustomerCatalogProducts = (body) => {
  return {
    type: types.GET_CUSTOMER_CATALOG_PRODUCTS,
    payload: body,
  };
};

export const getCustomerCatalogProductsSuccess = (body) => {
  return {
    type: types.GET_CUSTOMER_CATALOG_PRODUCTS_SUCCESS,
    payload: body,
  };
};

export const getCustomerCatalogProductsError = (body) => {
  return {
    type: types.GET_CUSTOMER_CATALOG_PRODUCTS_ERROR,
    payload: body,
  };
};

export const setSearchString = (data) => {
  return {
    type: types.SET_SEARCH_STRING,
    payload: data,
  };
};

export const getCustomerCatalogProduct = (body) => {
  return {
    type: types.GET_CUSTOMER_CATALOG_PRODUCT,
    payload: body,
  };
};

export const getCustomerCatalogProductSuccess = (body) => {
  return {
    type: types.GET_CUSTOMER_CATALOG_PRODUCT_SUCCESS,
    payload: body,
  };
};

export const getCustomerCatalogProductError = (body) => {
  return {
    type: types.GET_CUSTOMER_CATALOG_PRODUCT_ERROR,
    payload: body,
  };
};
