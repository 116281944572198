export const nth = (d) => {
  if (d > 3 && d < 21) {
    return "th";
  }
  switch (d % 10) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};

export const months = [
  "Jan",
  "Feb",
  "March",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const fullMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const getFormatedDate = (d) => {
  return d ? `${d.getDate()}${nth(d.getDate())} ${months[d.getMonth()]}` : "";
};

export const getFormatedFullDate = (d) => {
  return d
    ? `${d.getDate()}${nth(d.getDate())} ${
        fullMonths[d.getMonth()]
      }, ${d.getFullYear()}`
    : "";
};

const formatAMPM = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return `${hours}:${minutes} ${ampm}`;
};

export const getFormattedFullDateWithTime = (d) => {
  if (!d) return "";

  const date = `${d.getDate()}${nth(d.getDate())} ${
    fullMonths[d.getMonth()]
  }, ${d.getFullYear()}`;
  const time = formatAMPM(d);

  return `${date} ${time}`;
};

export const getDbFormatedDate = (d) => {
  return d
    ? `${d.getFullYear()}-${d.getMonth() + 1 < 10 ? "0" : ""}${
        d.getMonth() + 1
      }-${d.getDate() < 10 ? "0" : ""}${d.getDate()}`
    : "";
};

export const getDOYFromDate = (date) => {
  var now = date ?? new Date();
  var start = new Date(now.getFullYear(), 0, 0);
  var diff = now - start;
  var oneDay = 1000 * 60 * 60 * 24;
  var day = Math.floor(diff / oneDay);
  return day;
};

export const getDateFromDOY = (day, year = new Date().getFullYear()) => {
  var date = new Date(year, 0);
  return new Date(date.setDate(day));
};

export const formatDate = (inputDate) => {
  const currentDate = new Date();
  const date = new Date(inputDate);

  const options = {
    hour: "2-digit",
    minute: "2-digit",
  };

  // If it's today, return the time
  if (date.toDateString() === currentDate.toDateString()) {
    return date.toLocaleTimeString("en-US", options);
  }

  // If it's yesterday, return 'Yesterday'
  const yesterday = new Date(currentDate);
  yesterday.setDate(yesterday.getDate() - 1);
  if (date.toDateString() === yesterday.toDateString()) {
    return "Yesterday";
  }

  // If it's not today or yesterday, return the date in 'ddth Month' format
  const dateOptions = {
    day: "numeric",
    month: "short",
  };
  return date.toLocaleDateString("en-US", dateOptions);
};

const compareToNoon = () => {
  const now = new Date();
  const noon = new Date();
  noon.setHours(12, 0, 0, 0); // Set to 12:00:00.000

  if (now.getTime() === noon.getTime()) {
    return "It's exactly noon.";
  } else if (now.getTime() > noon.getTime()) {
    return "It's after noon.";
  } else {
    return "It's before noon.";
  }
};

export const getDateInDDMMYYYY = (days = 0) => {
  const now = new Date();

  // Add one day to the current date
  now.setUTCDate(now.getUTCDate() + days);

  // Get the day, month, and year in UTC
  const day = String(now.getUTCDate()).padStart(2, "0");
  const month = String(now.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-based
  const year = now.getUTCFullYear();

  // Format the date as ddmmyyyy
  const formattedDate = `${day}${month}${year}`;

  return formattedDate;
};
