import types from './productActionTypes';

const INITIAL_STATE = {
  storeCategories: {},
  error: null,
};

const productReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_STORE_CATEGORIES:
      return {
        ...state,
        storeCategories: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_STORE_CATEGORIES_SUCCESS:
      return {
        ...state,
        storeCategories: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.GET_STORE_SUB_CATEGORIES:
      return {
        ...state,
        storeSubCategories: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_STORE_SUB_CATEGORIES_SUCCESS:
      let data =
        state?.storeSubCategories?.data?.filter(
          d => d.type !== action.payload?.type,
        ) ?? [];
      if (data?.length > -1) {
        data = [...data, action.payload];
      }
      return {
        ...state,
        storeSubCategories: {
          isLoading: false,
          data: data,
          error: null,
        },
      };
    case types.GET_STORE_SUB_CATEGORIES_ERROR:
      return {
        ...state,
        storeSubCategories: {
          ...state.storeSubCategories,
          isLoading: false,
          error: action.payload,
        },
      };
    case types.SAVE_SELECTED_SUB_CATEGORY:
      return {
        ...state,
        savedSelectedSubCategory: {
          ...action.payload,
        },
        error: null,
      };
    case types.GET_MY_GENERAL_PRODUCTS:
      return {
        ...state,
        myProducts: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_MY_GENERAL_PRODUCTS_SUCCESS:
      return {
        ...state,
        myProducts: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.ADD_GENERAL_PRODUCT:
      return {
        ...state,
        productCreation: {
          isLoading: true,
          error: null,
        },
      };
    case types.ADD_GENERAL_PRODUCT_SUCCESS:
      return {
        ...state,
        productCreation: {
          isLoading: false,
          error: null,
        },
      };
    case types.ADD_GENERAL_PRODUCT_ERROR:
      return {
        ...state,
        productCreation: {
          isLoading: false,
          error: action.payload,
        },
      };
    case types.DELETE_GENERAL_PRODUCT:
      return {
        ...state,
        productDeletion: {
          isLoading: true,
          error: null,
        },
      };
    case types.DELETE_GENERAL_PRODUCT_SUCCESS:
      return {
        ...state,
        productDeletion: {
          isLoading: false,
          error: null,
        },
      };
    case types.DELETE_GENERAL_PRODUCT_ERROR:
      return {
        ...state,
        productDeletion: {
          isLoading: false,
          error: null,
        },
      };
    case types.SEARCH_GENERAL_PRODUCTS:
      return {
        ...state,
        customer: {
          generalProducts: {
            isLoading: true,
            error: null,
          },
        },
      };
    case types.SEARCH_GENERAL_PRODUCTS_SUCCESS:
      return {
        ...state,
        customer: {
          generalProducts: {
            isLoading: false,
            data: action.payload,
            error: null,
          },
        },
      };
    case types.SEARCH_GENERAL_PRODUCTS_ERROR:
      return {
        ...state,
        customer: {
          generalProducts: {
            isLoading: false,
            error: action.payload,
          },
        },
      };
    case types.GET_QUICK_PRODUCTS:
      return {
        ...state,
        quickProducts: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_QUICK_PRODUCTS_SUCCESS:
      return {
        ...state,
        quickProducts: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.GET_QUICK_PRODUCTS_ERROR:
      return {
        ...state,
        quickProducts: {
          isLoading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default productReducer;
