import types from './reviewActionTypes';

export const getReviews = payload => {
  return {
    type: types.GET_REVIEWS,
    payload: payload,
  };
};

export const getReviewsSuccess = data => {
  return {
    type: types.GET_REVIEWS_SUCCESS,
    payload: data,
  };
};

export const getReviewsError = data => {
  return {
    type: types.GET_REVIEWS_ERROR,
    payload: data,
  };
};

export const getReviewSummary = payload => {
  return {
    type: types.GET_REVIEW_SUMMARY,
    payload: payload,
  };
};

export const getReviewSummarySuccess = data => {
  return {
    type: types.GET_REVIEW_SUMMARY_SUCCESS,
    payload: data,
  };
};

export const getReviewSummaryError = data => {
  return {
    type: types.GET_REVIEW_SUMMARY_ERROR,
    payload: data,
  };
};

export const helpUsImprove = payload => {
  return {
    type: types.HELP_US_IMPROVE,
    payload: payload,
  };
};

export const helpUsImproveSuccess = data => {
  return {
    type: types.HELP_US_IMPROVE_SUCCESS,
    payload: data,
  };
};

export const helpUsImproveError = data => {
  return {
    type: types.HELP_US_IMPROVE_ERROR,
    payload: data,
  };
};

export const postReview = payload => {
  return {
    type: types.POST_REVIEW,
    payload: payload,
  };
};

export const postReviewSuccess = data => {
  return {
    type: types.POST_REVIEW_SUCCESS,
    payload: data,
  };
};

export const postReviewError = data => {
  return {
    type: types.POST_REVIEW_ERROR,
    payload: data,
  };
};
