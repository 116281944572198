const onStoreActionTypes = {
  GET_STORE_PROFILE: 'GET_STORE_PROFILE',
  GET_STORE_PROFILE_SUCCESS: 'GET_STORE_PROFILE_SUCCESS',
  GET_STORE_PROFILE_ERROR: 'GET_STORE_PROFILE_ERROR',

  GET_ALL_STORE_CATALOGS: 'GET_ALL_STORE_CATALOGS',
  GET_ALL_STORE_CATALOGS_SUCCESS: 'GET_ALL_STORE_CATALOGS_SUCCESS',
  GET_ALL_STORE_CATALOGS_ERROR: 'GET_ALL_STORE_CATALOGS_ERROR',

  GET_OWN_MARKET: 'GET_OWN_MARKET',
  GET_OWN_MARKET_SUCCESS: 'GET_OWN_MARKET_SUCCESS',
  GET_OWN_MARKET_ERROR: 'GET_OWN_MARKET_ERROR',

  ADD_QUICK_PRODUCTS: 'ADD_QUICK_PRODUCTS',
  ADD_QUICK_PRODUCTS_SUCCESS: 'ADD_QUICK_PRODUCTS_SUCCESS',
  ADD_QUICK_PRODUCTS_ERROR: 'ADD_QUICK_PRODUCTS_ERROR',
};

export default onStoreActionTypes;
