import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";

import rootSaga from "./rootSaga";
import rootReducer from "./rootReducer";
import { persistStore, persistReducer } from "redux-persist";

const sagaMiddleware = createSagaMiddleware();
const persistConfig = {
  key: "root",
  storage: "aa",
  //blacklist:[ 'wishList'/*  'cart' */] //Add reducer if you don`t want to presist it
};
const middleWares = [sagaMiddleware];

//1
// const persistedReducer = persistReducer(persistConfig, rootReducer);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middleWares))
);
let persistor = persistStore(store);
sagaMiddleware.run(rootSaga);

export default store;
