import types from "./authActionTypes";

export const selectRole = (payload) => {
  return {
    type: types.SELECT_ROLE,
    payload: payload,
  };
};

export const getOtp = (credentials, action) => {
  return {
    type: types.GET_OTP,
    payload: credentials,
    action: action,
  };
};

export const getOtpSuccess = (credentials) => {
  return {
    type: types.GET_OTP_SUCCESS,
    payload: credentials,
  };
};

export const getOtpError = (credentials) => {
  return {
    type: types.GET_OTP_ERROR,
    payload: credentials,
  };
};

export const getCustomerOtp = (body, action) => {
  return {
    type: types.GET_CUSTOMER_OTP,
    payload: body,
    action: action,
  };
};

export const getCustomerOtpSuccess = (body) => {
  return {
    type: types.GET_CUSTOMER_OTP_SUCCESS,
    payload: body,
  };
};

export const getCustomerOtpError = (body) => {
  return {
    type: types.GET_CUSTOMER_OTP_SUCCESS,
    payload: body,
  };
};

export const validateOtp = (body) => {
  return {
    type: types.VALIDATE_OTP,
    payload: body,
  };
};

export const validateOtpSuccess = (body) => {
  return {
    type: types.VALIDATE_OTP_SUCCESS,
    payload: body,
  };
};

export const generalValidateOtp = (body) => {
  return {
    type: types.GENERAL_VALIDATE_OTP,
    payload: body,
  };
};

export const generalValidateOtpSuccess = (body) => {
  return {
    type: types.GENERAL_VALIDATE_OTP_SUCCESS,
    payload: body,
  };
};

export const generalValidateOtpError = (body) => {
  return {
    type: types.GENERAL_VALIDATE_OTP_ERROR,
    payload: body,
  };
};

export const customerGeneralValidateOtp = (body, action) => {
  return {
    type: types.CUSTOMER_GENERAL_VALIDATE_OTP,
    payload: body,
    action,
  };
};

export const customerGeneralValidateOtpSuccess = (body) => {
  return {
    type: types.CUSTOMER_GENERAL_VALIDATE_OTP_SUCCESS,
    payload: body,
  };
};

export const customerGeneralValidateOtpError = (body) => {
  return {
    type: types.CUSTOMER_GENERAL_VALIDATE_OTP_ERROR,
    payload: body,
  };
};

export const validateCustomerOtp = (body) => {
  return {
    type: types.VALIDATE_CUSTOMER_OTP,
    payload: body,
  };
};

export const validateCustomerOtpSuccess = (body) => {
  return {
    type: types.VALIDATE_CUSTOMER_OTP_SUCCESS,
    payload: body,
  };
};

export const validateCustomerOtpError = (body) => {
  return {
    type: types.VALIDATE_CUSTOMER_OTP_ERROR,
    payload: body,
  };
};

export const changeRole = (body) => {
  return {
    type: types.CHANGE_ROLE,
    payload: body,
  };
};

export const setOwnerBasicInfo = (body) => {
  return {
    type: types.SET_OWNER_BASIC_INFO,
    payload: body,
  };
};

export const logInStart = (credentials) => ({
  type: types.LOG_IN_START,
  payload: credentials,
});

export const logInSuccess = (user) => ({
  type: types.LOG_IN_SUCCESS,
  payload: user,
});

export const logInFailure = (error) => ({
  type: types.LOG_IN_FAILURE,
  payload: error,
});

export const registerStart = (credentials) => ({
  type: types.REGISTER_START,
  payload: credentials,
});

export const registerSuccess = (user) => ({
  type: types.REGISTER_SUCCESS,
  payload: user,
});

export const registerFailure = (error) => ({
  type: types.REGISTER_FAILURE,
  payload: error,
});

export const logOut = () => ({
  type: types.LOG_OUT,
});

export const skipForNow = (body) => {
  return {
    type: types.SKIP_FOR_NOW,
    payload: body,
  };
};

export const selectDistrict = (body) => {
  return {
    type: types.SELECT_DISTRICT,
    payload: body,
  };
};

export const refreshToken = (body) => {
  return {
    type: types.REFRESH_TOKEN,
    payload: body,
  };
};

export const refreshTokenSuccess = (body) => {
  return {
    type: types.REFRESH_TOKEN_SUCCESS,
    payload: body,
  };
};

export const refreshTokenError = (body) => {
  return {
    type: types.REFRESH_TOKEN_ERROR,
    payload: body,
  };
};

export const changeLob = (body) => {
  return {
    type: types.CHANGE_LOB,
    payload: body,
  };
};

export const socialSignUp = (body) => {
  return {
    type: types.SOCIAL_SIGNUP,
    payload: body,
  };
};

export const socialSignUpSuccess = (body) => {
  return {
    type: types.SOCIAL_SIGNUP_SUCCESS,
    payload: body,
  };
};

export const socialSignUpError = (body) => {
  return {
    type: types.SOCIAL_SIGNUP_ERROR,
    payload: body,
  };
};

export const signUpBOfromCustomer = (body) => {
  return {
    type: types.SIGNUP_BO_FROM_CUSTOMER,
    payload: body,
  };
};

export const signUpBOfromCustomerSuccess = (body) => {
  return {
    type: types.SIGNUP_BO_FROM_CUSTOMER_SUCCESS,
    payload: body,
  };
};

export const signUpBOfromCustomerError = (body) => {
  return {
    type: types.SIGNUP_BO_FROM_CUSTOMER_ERROR,
    payload: body,
  };
};

export const signUpCustomerFromBO = (body) => {
  return {
    type: types.SIGNUP_CUSTOMER_FROM_BO,
    payload: body,
  };
};

export const signUpCustomerFromBOSuccess = (body) => {
  return {
    type: types.SIGNUP_CUSTOMER_FROM_BO_SUCCESS,
    payload: body,
  };
};

export const signUpCustomerFromBOError = (body) => {
  return {
    type: types.SIGNUP_CUSTOMER_FROM_BO_ERROR,
    payload: body,
  };
};

export const getCustomerDistrict = (body) => {
  return {
    type: types.GET_CUSTOMER_DISTRICT,
    payload: body,
  };
};

export const getCustomerDistrictSuccess = (body) => {
  return {
    type: types.GET_CUSTOMER_DISTRICT_SUCCESS,
    payload: body,
  };
};

export const getCustomerDistrictError = (body) => {
  return {
    type: types.GET_CUSTOMER_DISTRICT_ERROR,
    payload: body,
  };
};
