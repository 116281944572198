import types from './storeActionTypes';

const INITIAL_STATE = {};

const storeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_STORE_PROFILE:
      return {
        ...state,
        storeProfile: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_STORE_PROFILE_SUCCESS:
      return {
        ...state,
        storeProfile: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
        storeCatalogs: undefined,
      };
    case types.GET_STORE_PROFILE_ERROR:
      return {
        ...state,
        storeProfile: {
          isLoading: false,
          error: action.payload,
        },
      };
    case types.GET_ALL_STORE_CATALOGS:
      return {
        ...state,
        storeCatalogs: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_ALL_STORE_CATALOGS_SUCCESS:
      return {
        ...state,
        storeCatalogs: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.GET_ALL_STORE_CATALOGS_ERROR:
      return {
        ...state,
        storeCatalogs: {
          isLoading: false,
          error: action.payload,
        },
      };
    case types.GET_OWN_MARKET_SUCCESS:
      return {
        ...state,
        ownMarkets: {
          data: action.payload,
        },
      };
    default:
      return state;
  }
};

export default storeReducer;
