import types from './subscriptionActionTypes';

export const getSubscriptionPlans = body => {
  return {
    type: types.GET_SUBSCRIPTION_PLANS,
    payload: body,
  };
};

export const getSubscriptionPlansSuccess = data => {
  return {
    type: types.GET_SUBSCRIPTION_PLANS_SUCCESS,
    payload: data,
  };
};

export const getSubscriptionPlansError = error => {
  return {
    type: types.GET_SUBSCRIPTION_PLANS_ERROR,
    payload: error,
  };
};

export const getRemainingSubscriptions = body => {
  return {
    type: types.GET_REMAINING_SUBSCRIPTIONS,
    payload: body,
  };
};

export const getRemainingSubscriptionsSuccess = data => {
  return {
    type: types.GET_REMAINING_SUBSCRIPTIONS_SUCCESS,
    payload: data,
  };
};

export const getRemainingSubscriptionsError = error => {
  return {
    type: types.GET_REMAINING_SUBSCRIPTIONS_ERROR,
    payload: error,
  };
};
