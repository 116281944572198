export const GET_OTP = "owner/get_otp/";
export const VALIDATE_OTP = "owner/validate_otp/";
export const GENERAL_VALIDATE_OTP = "owner/validate_general_otp/";
export const CUSTOMER_GENERAL_VALIDATE_OTP = "customer/validate_general_otp/";
export const REFRESH_TOKEN = "owner/refresh_token";
export const CUSTOMER_REFRESH_TOKEN = "customer/refresh_token";
export const SOCIAL_SIGNUP = "/customer/social_signup/";
export const SIGNUP_BO_FROM_CUSTOMER = "/owner/bo_signup_fromcustomer/";
export const SIGNUP_CUSTOMER_FROM_BO = "/customer/customer_signup_frombo/";
export const GET_CUSTOMER_PROFILE = "/customer/get_customer_profile/";
export const CUSTOMER_COMPLETE_PROFILE = "/customer/complete_profile/";
export const GET_CUSTOMER_DISTRICT = "/customer/get_customer_district/";
export const GET_CUSTOMER_HOME_PRODUCTS = "/customer/home_products/";

export const GET_GOVT_ID_TYPES = "owner/get_govtid_types";
export const UPLOAD_GOVT_ID = "/owner/upload_govtid/";
export const GET_ALL_LOBS = "owner/get_LoBs";
export const GET_CATEGORIES = "owner/get_categories";
export const GET_SUB_CATEGORIES = "owner/get_subcategories";
export const GET_SUB_CATEGORY_SIZES = "owner/get_subcategory_sizes";
export const GET_CATEGORY_METADATA = "/owner/get_category_metadata";
export const OWNER_SIGNUP = "owner/sign_up_owner/";
export const GET_BASE_SUBSCRIPTION = "owner/get_base_subscription";
export const UPDATE_DELIVERY_DETAILS = "owner/update_delivery_details/";
export const GET_DELIVERY_DETAILS = "/owner/get_delivery_details/";
export const UPDATE_STORE_DETAILS = "owner/update_store_details/";
export const GET_STORE_DETAILS = "owner/get_store_details/";
export const UPDATE_DP = "owner/update_dp/";
export const GET_DP = "owner/get_dp/";
export const ADD_BUSINESS_CATEGORY = "owner/add_business_category/";
export const GET_STORE_LOBS = "owner/get_store_lobs/";
export const GET_STORE_BRANDS = "owner/get_store_brands/";
export const ADD_BRAND = "/owner/add_brand/";
export const ADD_QUICK_PRODUCTS = "/owner/add_multi_general_products/";

export const GET_STORE_CATEGORIES = "owner/get_store_categories/";
export const GET_STORE_SUB_CATEGORIES = "owner/get_store_subcategories/";

export const CREATE_CATALOG = "owner/create_catalog/";
export const ADD_CATALOG_PRODUCT = "owner/add_catalog_product/";
export const GET_MY_CATALOGS = "owner/get_my_catalogs/";
export const GET_CATALOG_PRODUCTS = "owner/get_catalog_products/";
export const GET_PRODUCT_IMAGE = "owner/get_product_image";
export const UPDATE_CATALOG_PRODUCT_DETAILS =
  "owner/update_catalog_product_details/";
export const UPDATE_CATALOG_PRODUCT_AVAILABILITY =
  "owner/update_catalog_product_availability/";
export const EDIT_CATALOG_SUBCATEGORY = "/owner/edit_catalog_subcategory/";

export const ADD_GENERAL_PRODUCT = "owner/add_general_product/";
export const UPDATE_GENERAL_PRODUCT_DETAILS =
  "owner/update_general_product_details/";
export const UPDATE_GENERAL_PRODUCT_AVAILABILITY =
  "owner/update_general_product_availability/";
export const DELETE_GENERAL_PRODUCT = "owner/remove_general_product/";
export const GET_MY_GENERAL_PRODUCTS = "owner/get_general_products/";

export const GET_BANNER_TEMPLATES = "banner/get_banner_templates/";
export const CREATE_BANNER = "banner/create_banner/";
export const ADD_BANNER = "banner/banner_dp/";
export const GET_BANNER_DP = "banner/get_banner_dp/";
export const PUBLISH_BANNER = "banner/banner_publish/";
export const GET_MY_BANNERS = "banner/get_my_banners/";
export const UPDATE_BANNER_DETAILS = "banner/update_banner_details/";
export const DELETE_BANNER = "banner/banner_delete/";
export const DISABLE_BANNER = "banner/banner_enabled/";
export const GET_OFFERRING_TYPES = "owner/get_offering_type";
export const GET_OFFER_KIND = "owner/get_offer_kind";

export const GET_OVERALL_STORE_STATS = "owner/get_overall_store_stats/";
export const GET_CATALOG_DAILY_TRENDS = "owner/get_catalog_daily_trends/";
export const GET_ALL_CATALOG_DAILY_TRENDS =
  "owner/get_all_catalog_daily_trends/";
export const GET_FINANCIAL_DAILY_TRENDS = "owner/store_daily_financial_health/";
export const GET_BANNER_DAILY_TRENDS = "owner/get_banner_daily_trends/";
export const GET_ALL_BANNER_DAILY_TRENDS =
  "owner/get_all_announcement_daily_trends/";
export const GET_STORE_SOCIAL_HEALTH = "owner/store_social_health/";
export const GET_CATALOG_INSIGHTS = "owner/get_catalog_insights";
export const GET_CATALOG_TOP_PRODUCTS = "owner/get_catalog_top_products";
export const ENABLE_DISABLE_CATALOG = "owner/enable_disable_catalog/";
export const DELETE_CATALOG_PRODUCT = "owner/remove_catalog_product/";
export const DELETE_CATALOG = "owner/delete_catalog/";
export const GET_BANNER_INSIGHTS = "owner/announcement_impact_orders";
export const GET_OVERALL_STATS = "owner/get_overall_stats/";

export const GET_SUBSCRIPTION_PLANS = "owner/get_plans/";
export const GET_REMAINING_SUBSCRIPTION = "owner/get_remaining_subscriptions/";

export const GET_ALL_ORDERS = "owner/get_all_orders/";
export const GET_REFERENCE_ORDER_STATUS = "owner/get_reference_order_status";
export const GET_ORDER_SUMMARY = "owner/get_order_summary";
export const SUBMIT_ORDER_RESPONSE = "owner/submit_order_response/";
export const GET_ORDER_STATUS = "owner/get_order_status/";
export const ORDER_READY_FOR_PICKUP = "owner/order_ready_for_pickup/";
export const ORDER_PICKED = "owner/order_picked/";

export const GET_ANALYSIS_TYPES = "owner/get_analysis_types";

export const GET_REVIEWS = "review/get_reviews";
export const GET_REVIEW_SUMMARY = "review/get_review_summary";
export const HELP_US_IMPROVE = "owner/help_us_improve";
export const CUSTOMER_HELP_US_IMPROVE = "customer/help_us_improve";

export const GET_CUSTOMER_OTP = "/customer/get_customer_otp/";
export const VALIDATE_CUSTOMER_OTP = "/customer/validate_customer_otp";

export const SEARCH_CATALOG = "/customer/search_catalog/";
export const SEARCH_GENERAL_PRODUCTS = "/customer/search_general_product/";
export const SEARCH_BANNERS = "/customer/search_banners/";
export const GET_NEARBY_STORES = "/customer/get_nearby_stores/";
export const GET_CUSTOMER_CATALOG_PRODUCTS = "/customer/get_catalog_products/";
export const GET_CUSTOMER_CATALOG_PRODUCT =
  "customer/get_catalog_products_byid/";

export const BANNER_CLICKED = "/customer/banner_clicked/";
export const CATALOG_CLICKED = "/customer/catalog_clicked/";
export const FOLLOW_STORE = "/customer/follow";
export const VIEWED_STORE_PROFILE = "/customer/viewed_profile";
export const VIEWED_PHONE_NUMBER = "/customer/viewed_phone_number";
export const GET_STORE_COUPON = "/customer/get_store_coupon";

export const GET_STORE_PROFILE = "/customer/get_store_profile/";
export const GET_ALL_STORE_CATALOGS = "/customer/get_all_catalogs/";
export const GET_ALL_OPERATIONAL_DISTRICTS =
  "/customer/get_operational_districts";
export const GET_SUB_CATEGORIES_SEARCH = "customer/get_subcategories_search";
export const GET_PRODUCT_METADATA = "customer/get_product_metadata/";
export const GET_BANNER_METADATA = "customer/get_banner_metadata/";
export const GET_STORE_METADATA = "customer/get_store_metadata/";
export const GET_OWN_MARKET = "/owner/get_owner_market";

export const SOCIAL_SHARE = "/owner/social_share/";

export const CREATE_CART = "/customer/create_cart/";
export const ADD_TO_CART = "/customer/add_to_cart/";
export const GET_CART_SUMMARY = "/customer/get_cart_summary/";
export const REMOVE_CART_PRODUCT = "/customer/remove_cart_product/";
export const GET_MY_ADDRESSES = "/customer/get_my_addresses/";
export const ADD_ADDRESSES = "/customer/add_addresses/";
export const PLACE_ORDER = "/customer/place_order/";
export const PROCEED_TO_PAYMENT = "/customer/proceed_to_payment/";
export const PAYMENT_DONE = "/customer/payment_done/";
export const UPDATE_CART_ITEM_QUANTITY = "/customer/update_cart_item_quantity/";
export const GET_LATEST_ORDER = "/customer/get_latest_order/";
export const POST_REVIEW = "/customer/post_review";
export const GET_ALL_CUSTOMER_ORDERS = "/customer/get_all_customer_orders/";
export const CALCULATE_SHIPMENT = "/customer/calculate_shipment/";
export const DISCARD_CART = "/customer/discard_cart/";
export const VERIFY_COUPON = "/customer/verify_coupon/";

export const BLOCK_CUSTOMER = "/owner/block_customer";
export const BLOCK_BO = "/customer/block_bo";
export const CHECK_IF_BLOCKED = "/owner/block_customer";
export const REGISTER_CUSTOMER = "/customer/register_customer/";
export const CHECK_PINCODE = "/customer/check_zip/";
export const TRACK_ORDER = "/customer/track_my_order/";

export const NON_ENCRYPTED_APIS = [
  GET_BANNER_TEMPLATES,
  GET_GOVT_ID_TYPES,
  GET_OFFER_KIND,
  GET_ALL_LOBS,
  GET_CATEGORIES,
  GET_SUB_CATEGORIES,
  GET_SUB_CATEGORIES_SEARCH,
  GET_ANALYSIS_TYPES,
  "get_brand_identity",
  GET_OFFER_KIND,
  SOCIAL_SIGNUP,
];

export const PUBLIC_APIS = [
  GET_OTP,
  GENERAL_VALIDATE_OTP,
  GET_CUSTOMER_OTP,
  CUSTOMER_GENERAL_VALIDATE_OTP,
  GET_ALL_OPERATIONAL_DISTRICTS,
];
