import {all, call, put, select, takeLatest} from 'redux-saga/effects';

import {
  getSubscriptionPlansSuccess,
  getSubscriptionPlansError,
  getRemainingSubscriptionsSuccess,
  getRemainingSubscriptionsError,
} from './subscriptionActions';
import {
  GET_REMAINING_SUBSCRIPTION,
  GET_SUBSCRIPTION_PLANS,
} from '../../utils/ApiList';
import Api from '../../middlewares/Api';
import subscriptionActionTypes from './subscriptionActionTypes';
import {getUserBasicInfo} from '../rootSelector';
import catalogActionTypes from '../catalog/catalogActionTypes';

const getSubscriptionPlansApiCall = async payload => {
  const response = await Api.post(GET_SUBSCRIPTION_PLANS, payload);
  return response.data;
};

const getRemainingSubscriptionsApiCall = async payload => {
  const response = await Api.post(GET_REMAINING_SUBSCRIPTION, payload);
  return response.data;
};

export function* getSubscriptionPlans({payload}) {
  try {
    const plans = yield getSubscriptionPlansApiCall(payload);
    yield put(
      getSubscriptionPlansSuccess({
        offerType: payload?.offering_type,
        plans: plans,
      }),
    );
  } catch (error) {
    yield put(getSubscriptionPlansError(error));
  }
}

export function* getRemainingSubscriptions() {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    if (basicInfo?.biz_type) {
      const subscriptions = yield getRemainingSubscriptionsApiCall(basicInfo);
      yield put(getRemainingSubscriptionsSuccess(subscriptions));
    } else {
      yield put(
        getRemainingSubscriptionsError({message: 'Biz Type Not Found'}),
      );
    }
  } catch (error) {
    yield put(getRemainingSubscriptionsError(error));
  }
}

export function* onGetSubscriptionPlans() {
  yield takeLatest(
    subscriptionActionTypes.GET_SUBSCRIPTION_PLANS,
    getSubscriptionPlans,
  );
}

export function* onGetRemainingSubscriptions() {
  yield takeLatest(
    [
      subscriptionActionTypes.GET_REMAINING_SUBSCRIPTIONS,
      catalogActionTypes.CREATE_CATALOG_SUCCESS,
    ],
    getRemainingSubscriptions,
  );
}

export function* subscriptionSagas() {
  yield all([call(onGetSubscriptionPlans), call(onGetRemainingSubscriptions)]);
}
