const onNotifyActionTypes = {
  GET_REVIEWS: 'GET_REVIEWS',
  GET_REVIEWS_SUCCESS: 'GET_REVIEWS_SUCCESS',
  GET_REVIEWS_ERROR: 'GET_REVIEWS_ERROR',

  GET_REVIEW_SUMMARY: 'GET_REVIEW_SUMMARY',
  GET_REVIEW_SUMMARY_SUCCESS: 'GET_REVIEW_SUMMARY_SUCCESS',
  GET_REVIEW_SUMMARY_ERROR: 'GET_REVIEW_SUMMARY_ERROR',

  HELP_US_IMPROVE: 'HELP_US_IMPROVE',
  HELP_US_IMPROVE_SUCCESS: 'HELP_US_IMPROVE_SUCCESS',
  HELP_US_IMPROVE_ERROR: 'HELP_US_IMPROVE_ERROR',

  POST_REVIEW: 'POST_REVIEW',
  POST_REVIEW_SUCCESS: 'POST_REVIEW_SUCCESS',
  POST_REVIEW_ERROR: 'POST_REVIEW_ERROR',
};

export default onNotifyActionTypes;
