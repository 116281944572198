const onNotifyActionTypes = {
  SOCIAL_SHARE: 'SOCIAL_SHARE',
  SOCIAL_SHARE_SUCCESS: 'SOCIAL_SHARE_SUCCESS',
  SOCIAL_SHARE_ERROR: 'SOCIAL_SHARE_ERROR',

  BLOCK_CUSTOMER: 'BLOCK_CUSTOMER',
  BLOCK_CUSTOMER_SUCCESS: 'BLOCK_CUSTOMER_SUCCESS',
  BLOCK_CUSTOMER_ERROR: 'BLOCK_CUSTOMER_ERROR',

  BLOCK_BO: 'BLOCK_BO',
  BLOCK_BO_SUCCESS: 'BLOCK_BO_SUCCESS',
  BLOCK_BO_ERROR: 'BLOCK_BO_ERROR',

  CHECK_IF_BLOCKED: 'CHECK_IF_BLOCKED',
  CHECK_IF_BLOCKED_SUCCESS: 'CHECK_IF_BLOCKED_SUCCESS',
  CHECK_IF_BLOCKED_ERROR: 'CHECK_IF_BLOCKED_ERROR',
};

export default onNotifyActionTypes;
