const onBoardingActionTypes = {
  GET_GOVT_ID_TYPES: "GET_GOVT_ID_TYPES",
  GET_GOVT_ID_TYPES_SUCCESS: "GET_GOVT_ID_TYPES_SUCCESS",
  UPLOAD_GOVT_ID_DATA: "UPLOAD_GOVT_ID_DATA",
  UPLOAD_GOVT_ID_DATA_SUCCESS: "UPLOAD_GOVT_ID_DATA_SUCCESS",
  GET_ALL_LOBS: "GET_ALL_LOBS",
  GET_ALL_LOBS_SUCCESS: "GET_ALL_LOBS_SUCCESS",
  SAVE_ALL_PRODUCT_LOBS: "SAVE_ALL_PRODUCT_LOBS",
  SAVE_ALL_SERVICE_LOBS: "SAVE_ALL_SERVICE_LOBS",
  SELECT_LOB: "SELECT_LOB",
  GET_CATEGORIES: "GET_CATEGORIES",
  GET_CATEGORIES_SUCCESS: "GET_CATEGORIES_SUCCESS",
  GET_SUB_CATEGORIES: "GET_SUB_CATEGORIES",
  GET_SUB_CATEGORIES_SUCCESS: "GET_SUB_CATEGORIES_SUCCESS",
  SAVE_ONBOARDING_DETAILS: "SAVE_ONBOARDING_DETAILS",
  SAVE_ONBOARDING_DETAILS_SUCCESS: "SAVE_ONBOARDING_DETAILS_SUCCESS",
  OWNER_SIGNUP: "OWNER_SIGNUP",
  OWNER_SIGNUP_SUCCESS: "OWNER_SIGNUP_SUCCESS",
  GET_BASE_SUBSCRIPTION: "GET_BASE_SUBSCRIPTION",
  GET_BASE_SUBSCRIPTION_SUCCESS: "GET_BASE_SUBSCRIPTION_SUCCESS",
  GET_BASE_SUBSCRIPTION_ERROR: "GET_BASE_SUBSCRIPTION_ERROR",

  GET_DELIVERY_DETAILS: "GET_DELIVERY_DETAILS",
  GET_DELIVERY_DETAILS_SUCCESS: "GET_DELIVERY_DETAILS_SUCCESS",
  GET_DELIVERY_DETAILS_ERROR: "GET_DELIVERY_DETAILS_ERROR",

  UPDATE_DELIVERY_DETAILS: "UPDATE_DELIVERY_DETAILS",
  UPDATE_DELIVERY_DETAILS_SUCCESS: "UPDATE_DELIVERY_DETAILS_SUCCESS",
  UPDATE_DELIVERY_DETAILS_ERROR: "UPDATE_DELIVERY_DETAILS_ERROR",

  GET_STORE_DETAILS: "GET_STORE_DETAILS",
  GET_STORE_DETAILS_SUCCESS: "GET_STORE_DETAILS_SUCCESS",
  GET_STORE_DETAILS_ERROR: "GET_STORE_DETAILS_ERROR",

  UPDATE_STORE_DETAILS: "UPDATE_STORE_DETAILS",
  UPDATE_STORE_DETAILS_SUCCESS: "UPDATE_STORE_DETAILS_SUCCESS",
  UPDATE_STORE_DETAILS_ERROR: "UPDATE_STORE_DETAILS_ERROR",

  UPDATE_DP: "UPDATE_DP",
  UPDATE_DP_SUCCESS: "UPDATE_DP_SUCCESS",
  UPDATE_DP_ERROR: "UPDATE_DP_ERROR",

  GET_DP: "GET_DP",
  GET_DP_SUCCESS: "GET_DP_SUCCESS",
  GET_DP_ERROR: "GET_DP_ERROR",

  ADD_BUSINESS_CATEGORY: "ADD_BUSINESS_CATEGORY",
  ADD_BUSINESS_CATEGORY_SUCCESS: "ADD_BUSINESS_CATEGORY_SUCCESS",
  ADD_BUSINESS_CATEGORY_ERROR: "ADD_BUSINESS_CATEGORY_ERROR",

  GET_STORE_LOBS: "GET_STORE_LOBS",
  GET_STORE_LOBS_SUCCESS: "GET_STORE_LOBS_SUCCESS",
  GET_STORE_LOBS_ERROR: "GET_STORE_LOBS_ERROR",

  UPDATE_APP_LOB: "UPDATE_APP_LOB",

  GET_SUB_CATEGORIES_SEARCH: "GET_SUB_CATEGORIES_SEARCH",
  GET_SUB_CATEGORIES_SEARCH_SUCCESS: "GET_SUB_CATEGORIES_SEARCH_SUCCESS",
  GET_SUB_CATEGORIES_SEARCH_ERROR: "GET_SUB_CATEGORIES_SEARCH_ERROR",

  GET_STORE_BRANDS: "GET_STORE_BRANDS",
  GET_STORE_BRANDS_SUCCESS: "GET_STORE_BRANDS_SUCCESS",
  GET_STORE_BRANDS_ERROR: "GET_STORE_BRANDS_ERROR",

  ADD_BRAND: "ADD_BRAND",
  ADD_BRAND_SUCCESS: "ADD_BRAND_SUCCESS",
  ADD_BRAND_ERROR: "ADD_BRAND_ERROR",

  CLEAR_OWNER_DETAILS: "CLEAR_OWNER_DETAILS",

  GET_CUSTOMER_DETAILS: "GET_CUSTOMER_DETAILS",
  GET_CUSTOMER_DETAILS_SUCCESS: "GET_CUSTOMER_DETAILS_SUCCESS",
  GET_CUSTOMER_DETAILS_ERROR: "GET_CUSTOMER_DETAILS_ERROR",

  UPDATE_CUSTOMER_DETAILS: "UPDATE_CUSTOMER_DETAILS",
  UPDATE_CUSTOMER_DETAILS_SUCCESS: "UPDATE_CUSTOMER_DETAILS_SUCCESS",
  UPDATE_CUSTOMER_DETAILS_ERROR: "UPDATE_CUSTOMER_DETAILS_ERROR",

  GET_CUSTOMER_HOME_PRODUCTS: "GET_CUSTOMER_HOME_PRODUCTS",
  GET_CUSTOMER_HOME_PRODUCTS_SUCCESS: "GET_CUSTOMER_HOME_PRODUCTS_SUCCESS",
  GET_CUSTOMER_HOME_PRODUCTS_ERROR: "GET_CUSTOMER_HOME_PRODUCTS_ERROR",
};

export default onBoardingActionTypes;
