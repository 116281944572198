import types from "./onBoardingActionTypes";

export const getGovtIdTypes = () => {
  return {
    type: types.GET_GOVT_ID_TYPES,
  };
};

export const getGovtIdTypesSuccess = (data) => {
  return {
    type: types.GET_GOVT_ID_TYPES_SUCCESS,
    payload: data,
  };
};

export const uploadGovtIdData = (data, navigation) => {
  return {
    type: types.UPLOAD_GOVT_ID_DATA,
    payload: data,
    action: navigation,
  };
};

export const uploadGovtIdDataSuccess = (data) => {
  return {
    type: types.UPLOAD_GOVT_ID_DATA_SUCCESS,
    payload: data,
  };
};

export const getAllLobs = (type) => {
  return {
    type: types.GET_ALL_LOBS,
    payload: type ?? "Product",
  };
};

export const getAllLobsSuccess = (data) => {
  return {
    type: types.GET_ALL_LOBS_SUCCESS,
    payload: data,
  };
};

export const saveAllProductLobs = (data) => {
  return {
    type: types.SAVE_ALL_PRODUCT_LOBS,
    payload: data,
  };
};

export const saveAllServiceLobs = (data) => {
  return {
    type: types.SAVE_ALL_SERVICE_LOBS,
    payload: data,
  };
};

export const getCategories = (payload) => {
  return {
    type: types.GET_CATEGORIES,
    payload: payload,
  };
};

export const getCategoriesSuccess = (data) => {
  return {
    type: types.GET_CATEGORIES_SUCCESS,
    payload: data,
  };
};

export const getSubCategories = (payload) => {
  return {
    type: types.GET_SUB_CATEGORIES,
    payload: payload,
  };
};

export const getSubCategoriesSuccess = (data) => {
  return {
    type: types.GET_SUB_CATEGORIES_SUCCESS,
    payload: data,
  };
};

export const signUpOwner = (data, navigation) => {
  return {
    type: types.OWNER_SIGNUP,
    payload: data,
    action: navigation,
  };
};

export const signUpOwnerSuccess = (data) => {
  return {
    type: types.OWNER_SIGNUP_SUCCESS,
    payload: data,
  };
};

export const saveOnBoardingDetails = (data, navigation) => {
  return {
    type: types.SAVE_ONBOARDING_DETAILS,
    payload: data,
    action: navigation,
  };
};

export const saveOnBoardingDetailsSuccess = (data, navigation) => {
  return {
    type: types.SAVE_ONBOARDING_DETAILS_SUCCESS,
    payload: data,
    action: navigation,
  };
};

export const getBaseSubscription = (body) => {
  return {
    type: types.GET_BASE_SUBSCRIPTION,
    payload: body,
  };
};

export const getBaseSubscriptionSuccess = (data) => {
  return {
    type: types.GET_BASE_SUBSCRIPTION_SUCCESS,
    payload: data,
  };
};

export const updateDeliveryDetails = (data, action) => {
  return {
    type: types.UPDATE_DELIVERY_DETAILS,
    payload: data,
    action,
  };
};

export const updateDeliveryDetailsSuccess = (data) => {
  return {
    type: types.UPDATE_DELIVERY_DETAILS_SUCCESS,
    payload: data,
  };
};

export const updateDeliveryDetailsError = (data) => {
  return {
    type: types.UPDATE_DELIVERY_DETAILS_ERROR,
    payload: data,
  };
};

export const getDeliveryDetails = (data) => {
  return {
    type: types.GET_DELIVERY_DETAILS,
    payload: data,
  };
};

export const getDeliveryDetailsSuccess = (data) => {
  return {
    type: types.GET_DELIVERY_DETAILS_SUCCESS,
    payload: data,
  };
};

export const getDeliveryDetailsError = (data) => {
  return {
    type: types.GET_DELIVERY_DETAILS_ERROR,
    payload: data,
  };
};

export const getStoreDetails = (data) => {
  return {
    type: types.GET_STORE_DETAILS,
    payload: data,
  };
};

export const getStoreDetailsSuccess = (data) => {
  return {
    type: types.GET_STORE_DETAILS_SUCCESS,
    payload: data,
  };
};

export const getStoreDetailsError = (data) => {
  return {
    type: types.GET_STORE_DETAILS_ERROR,
    payload: data,
  };
};

export const updateStoreDetails = (data, action) => {
  return {
    type: types.UPDATE_STORE_DETAILS,
    payload: data,
    action,
  };
};

export const updateStoreDetailsSuccess = (data) => {
  return {
    type: types.UPDATE_STORE_DETAILS_SUCCESS,
    payload: data,
  };
};

export const updateStoreDetailsError = (data) => {
  return {
    type: types.UPDATE_STORE_DETAILS_ERROR,
    payload: data,
  };
};

export const updateDp = (data, action) => {
  return {
    type: types.UPDATE_DP,
    payload: data,
    action,
  };
};

export const updateDpSuccess = (data) => {
  return {
    type: types.UPDATE_DP_SUCCESS,
    payload: data,
  };
};

export const updateDpError = (data) => {
  return {
    type: types.UPDATE_DP_ERROR,
    payload: data,
  };
};

export const getDp = (data) => {
  return {
    type: types.GET_DP,
    payload: data,
  };
};

export const getDpSuccess = (data) => {
  return {
    type: types.GET_DP_SUCCESS,
    payload: data,
  };
};

export const getDpError = (data) => {
  return {
    type: types.GET_DP_ERROR,
    payload: data,
  };
};

export const addBusinessCategory = (data, action) => {
  return {
    type: types.ADD_BUSINESS_CATEGORY,
    payload: data,
    action,
  };
};

export const addBusinessCategorySuccess = (data) => {
  return {
    type: types.ADD_BUSINESS_CATEGORY_SUCCESS,
    payload: data,
  };
};

export const addBusinessCategoryError = (data) => {
  return {
    type: types.ADD_BUSINESS_CATEGORY_ERROR,
    payload: data,
  };
};

export const getStoreLobs = (data) => {
  return {
    type: types.GET_STORE_LOBS,
    payload: data,
  };
};

export const getStoreLobsSuccess = (data) => {
  return {
    type: types.GET_STORE_LOBS_SUCCESS,
    payload: data,
  };
};

export const getStoreLobsError = (data) => {
  return {
    type: types.GET_STORE_LOBS_ERROR,
    payload: data,
  };
};

export const updateAppLob = (data) => {
  return {
    type: types.UPDATE_APP_LOB,
    payload: data,
  };
};

export const getSubCategoriesSearch = (payload) => {
  return {
    type: types.GET_SUB_CATEGORIES_SEARCH,
    payload: payload,
  };
};

export const getSubCategoriesSearchSuccess = (data) => {
  return {
    type: types.GET_SUB_CATEGORIES_SEARCH_SUCCESS,
    payload: data,
  };
};

export const getSubCategoriesSearchError = (data) => {
  return {
    type: types.GET_SUB_CATEGORIES_SEARCH_ERROR,
    payload: data,
  };
};

export const getStoreBrands = (data) => {
  return {
    type: types.GET_STORE_BRANDS,
    payload: data,
  };
};

export const getStoreBrandsSuccess = (data) => {
  return {
    type: types.GET_STORE_BRANDS_SUCCESS,
    payload: data,
  };
};

export const getStoreBrandsError = (data) => {
  return {
    type: types.GET_STORE_BRANDS_ERROR,
    payload: data,
  };
};

export const addBrand = (data, action) => {
  return {
    type: types.ADD_BRAND,
    payload: data,
    action,
  };
};

export const addBrandSuccess = (data) => {
  return {
    type: types.ADD_BRAND_SUCCESS,
    payload: data,
  };
};

export const addBrandError = (data) => {
  return {
    type: types.ADD_BRAND_ERROR,
    payload: data,
  };
};

export const clearOwnerDetails = (data) => {
  return {
    type: types.CLEAR_OWNER_DETAILS,
    payload: data,
  };
};

export const getCustomerDetails = (data, action) => {
  return {
    type: types.GET_CUSTOMER_DETAILS,
    payload: data,
    action,
  };
};

export const getCustomerDetailsSuccess = (data) => {
  return {
    type: types.GET_CUSTOMER_DETAILS_SUCCESS,
    payload: data,
  };
};

export const getCustomerDetailsError = (data) => {
  return {
    type: types.GET_CUSTOMER_DETAILS_ERROR,
    payload: data,
  };
};

export const updateCustomerDetails = (data, action, to) => {
  return {
    type: types.UPDATE_CUSTOMER_DETAILS,
    payload: data,
    action,
    to,
  };
};

export const updateCustomerDetailsSuccess = (data) => {
  return {
    type: types.UPDATE_CUSTOMER_DETAILS_SUCCESS,
    payload: data,
  };
};

export const updateCustomerDetailsError = (data) => {
  return {
    type: types.UPDATE_CUSTOMER_DETAILS_ERROR,
    payload: data,
  };
};

export const getHomeProducts = (type) => {
  return {
    type: types.GET_CUSTOMER_HOME_PRODUCTS,
  };
};

export const getHomeProductsSuccess = (data) => {
  return {
    type: types.GET_CUSTOMER_HOME_PRODUCTS_SUCCESS,
    payload: data,
  };
};

export const getHomeProductsError = (data) => {
  return {
    type: types.GET_CUSTOMER_HOME_PRODUCTS_ERROR,
    payload: data,
  };
};
