import React from "react";
import img from "../../../assets/img/ecom-cart.gif";
const Loader = () => {
  return (
    <>
      <div className="loader_wrapper">
        <img
          width="200px"
          src={
            "https://test-catalog-bucket1.s3.ap-south-1.amazonaws.com/web/quickgifting_web.png"
          }
          alt="loader"
        />
      </div>
    </>
  );
};

export default Loader;
