import {all, call, put, select, takeLatest} from 'redux-saga/effects';

import {
  socialShareSuccess,
  socialShareError,
  blockCustomerSuccess,
  blockCustomerError,
  blockBOSuccess,
  blockBOError,
  checkIfBlockedSuccess,
  checkIfBlockedError,
} from './notifyActions';
import {
  BLOCK_BO,
  BLOCK_CUSTOMER,
  CHECK_IF_BLOCKED,
  SOCIAL_SHARE,
} from '../../utils/ApiList';
import Api from '../../middlewares/Api';
import notifyActionTypes from './notifyActionTypes';
import {getUserBasicInfo} from '../rootSelector';

const socialShareApiCall = async payload => {
  const response = await Api.post(`${SOCIAL_SHARE}`, payload);
  return response.data;
};

const blockCustomerApiCall = async ({data, cus, block}) => {
  const response = await Api.post(
    `${BLOCK_CUSTOMER}?cus=${cus}&block=${block}`,
    data,
  );
  return response.data;
};

const blockBOApiCall = async ({data, bo, block}) => {
  const response = await Api.post(`${BLOCK_BO}?bo=${bo}&block=${block}`, data);
  return response.data;
};

const checkIfBlockedApiCall = async payload => {
  const response = await Api.post(`${CHECK_IF_BLOCKED}`, payload);
  return response.data;
};

export function* socialShare({payload}) {
  try {
    const basic_info = yield select(getUserBasicInfo);

    const response = yield socialShareApiCall({
      data: basic_info,
      data1: {
        media_name: 'string',
        service_type: 'string',
        service_id: 'string',
        ...payload,
      },
    });
    yield put(socialShareSuccess(response));
  } catch (error) {
    yield put(socialShareError(error));
  }
}

export function* blockCustomer({payload}) {
  try {
    const basic_info = yield select(getUserBasicInfo);

    const response = yield blockCustomerApiCall({
      data: basic_info,
      ...payload,
    });
    yield put(blockCustomerSuccess(response));
  } catch (error) {
    yield put(blockCustomerError(error));
  }
}

export function* blockBO({payload}) {
  try {
    const basic_info = yield select(getUserBasicInfo);

    const response = yield blockBOApiCall({
      data: basic_info,
      ...payload,
    });
    yield put(blockBOSuccess(response));
  } catch (error) {
    yield put(blockBOError(error));
  }
}

export function* checkIfBlocked({payload}) {
  try {
    const basic_info = yield select(getUserBasicInfo);

    const response = yield checkIfBlockedApiCall({
      data: basic_info,
      ...payload,
    });
    yield put(checkIfBlockedSuccess(response));
  } catch (error) {
    yield put(checkIfBlockedError(error));
  }
}

export function* onSocialShare() {
  yield takeLatest(notifyActionTypes.SOCIAL_SHARE, socialShare);
}

export function* onBlockCustomer() {
  yield takeLatest(notifyActionTypes.BLOCK_CUSTOMER, blockCustomer);
}

export function* onBlockBO() {
  yield takeLatest(notifyActionTypes.BLOCK_BO, blockBO);
}

export function* onCheckIfBlocked() {
  yield takeLatest(notifyActionTypes.CHECK_IF_BLOCKED, checkIfBlocked);
}

export function* notifySagas() {
  yield all([
    call(onSocialShare),
    call(onBlockCustomer),
    call(onBlockBO),
    call(onCheckIfBlocked),
  ]);
}
