import types from './subscriptionActionTypes';

const INITIAL_STATE = {};

const subscriptionReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_SUBSCRIPTION_PLANS:
      return {
        ...state,
        plans: {
          data: state?.plans?.data ? state?.plans?.data : [],
          isLoading: true,
          error: null,
        },
      };
    case types.GET_SUBSCRIPTION_PLANS_SUCCESS:
      return {
        ...state,
        plans: {
          isLoading: false,
          data: mapSubscritionPlansData(state?.plans?.data, action.payload),
          error: null,
        },
      };
    case types.GET_SUBSCRIPTION_PLANS_ERROR:
      return {
        ...state,
        plans: {
          ...state?.plans,
          isLoading: false,
          error: action.payload,
        },
      };
    case types.GET_REMAINING_SUBSCRIPTIONS:
      return {
        ...state,
        remaining: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_REMAINING_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        remaining: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.GET_REMAINING_SUBSCRIPTIONS_ERROR:
      return {
        ...state,
        remaining: {
          ...state?.remaining,
          isLoading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export const mapSubscritionPlansData = (plans, response) => {
  plans = plans?.filter(p => p.offerType !== response?.offerType) ?? [];
  return [...plans, response];
};

export default subscriptionReducer;
