import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import Api from '../../middlewares/Api';
import {
  GET_ALL_BANNER_DAILY_TRENDS,
  GET_ALL_CATALOG_DAILY_TRENDS,
  GET_BANNER_DAILY_TRENDS,
  GET_BANNER_INSIGHTS,
  GET_CATALOG_DAILY_TRENDS,
  GET_CATALOG_INSIGHTS,
  GET_CATALOG_TOP_PRODUCTS,
  GET_FINANCIAL_DAILY_TRENDS,
  GET_OVERALL_STATS,
  GET_OVERALL_STORE_STATS,
  GET_STORE_SOCIAL_HEALTH,
} from '../../utils/ApiList';
import {
  getAllBannerDailyTrendsError,
  getAllBannerDailyTrendsSuccess,
  getAllCatalogDailyTrendsError,
  getAllCatalogDailyTrendsSuccess,
  getBannerDailyTrendsError,
  getBannerDailyTrendsSuccess,
  getBannerInsightsError,
  getBannerInsightsSuccess,
  getCatalogDailyTrendsError,
  getCatalogDailyTrendsSuccess,
  getCatalogInsightsError,
  getCatalogInsightsSuccess,
  getCatalogTopProductsError,
  getCatalogTopProductsSuccess,
  getFinancialDailyTrendsError,
  getFinancialDailyTrendsSuccess,
  getOverallStatsError,
  getOverallStatsSuccess,
  getOverallStoreStatsError,
  getOverallStoreStatsSuccess,
  getStoreSocialHealthError,
  getStoreSocialHealthSuccess,
} from './insightsActions';
import insightsActionTypes from './insightsActionTypes';
import {getUserBasicInfo} from '../rootSelector';

const getOverallStoreStatsApiCall = async payload => {
  const response = await Api.post(`${GET_OVERALL_STORE_STATS}`, payload.data);
  return response.data;
};

const getOverallStatsApiCall = async payload => {
  const response = await Api.post(
    `${GET_OVERALL_STATS}?summary_by=${payload.summary_by}`,
    payload.data,
  );
  return response.data;
};

const getCatalogDailyTrendsApiCall = async payload => {
  const response = await Api.post(
    `${GET_CATALOG_DAILY_TRENDS}?summary_by=${payload.summary_by}`,
    payload.data,
  );
  return response.data;
};

const getAllCatalogDailyTrendsApiCall = async payload => {
  const response = await Api.post(
    `${GET_ALL_CATALOG_DAILY_TRENDS}?summary_by=${payload.summary_by}`,
    payload.data,
  );
  return response.data;
};

const getFinancialDailyTrendsApiCall = async payload => {
  const response = await Api.post(
    `${GET_FINANCIAL_DAILY_TRENDS}?summary_by=${payload.summary_by}`,
    payload.data,
  );
  return response.data;
};

const getBannerDailyTrendsApiCall = async payload => {
  const response = await Api.post(
    `${GET_BANNER_DAILY_TRENDS}?summary_by=${payload.summary_by}`,
    payload.data,
  );
  return response.data;
};

const getAllBannerDailyTrendsApiCall = async payload => {
  const response = await Api.post(
    `${GET_ALL_BANNER_DAILY_TRENDS}?summary_by=${payload.summary_by}`,
    payload.data,
  );
  return response.data;
};

const getStoreSocialHealthApiCall = async payload => {
  const response = await Api.post(
    `${GET_STORE_SOCIAL_HEALTH}?summary_by=${payload.summary_by}`,
    payload.data,
  );
  return response.data;
};

const getCatalogInsightsApiCall = async payload => {
  const response = await Api.post(`${GET_CATALOG_INSIGHTS}`, payload);
  return response.data;
};

const getCatalogTopProductsApiCall = async payload => {
  const response = await Api.post(`${GET_CATALOG_TOP_PRODUCTS}`, payload);
  return response.data;
};

const getBannerInsightsApiCall = async payload => {
  const response = await Api.post(`${GET_BANNER_INSIGHTS}`, payload);
  return response.data;
};

export function* getOverallStoreStats() {
  try {
    const data = yield select(getUserBasicInfo);
    if (data?.biz_type) {
      const stats = yield getOverallStoreStatsApiCall({
        data,
      });
      yield put(getOverallStoreStatsSuccess(stats));
    } else {
      yield put(getOverallStoreStatsError({message: 'Biz Type Not Found'}));
    }
  } catch (error) {
    yield put(getOverallStoreStatsError(error));
  }
}

export function* getOverallStats({payload}) {
  try {
    const data = yield select(getUserBasicInfo);
    if (data?.biz_type) {
      const stats = yield getOverallStatsApiCall({
        summary_by: payload,
        data,
      });
      yield put(getOverallStatsSuccess(stats));
    } else {
      yield put(getOverallStatsError({message: 'Biz Type Not Found'}));
    }
  } catch (error) {
    yield put(getOverallStatsError(error));
  }
}

export function* getCatalogDailyTrends({payload}) {
  try {
    const data = yield select(getUserBasicInfo);

    if (data?.biz_type) {
      const govtIds = yield getCatalogDailyTrendsApiCall({
        summary_by: payload,
        data,
      });
      yield put(getCatalogDailyTrendsSuccess(govtIds));
    } else {
      yield put(getCatalogDailyTrendsError({message: 'Biz Type Not Found'}));
    }
  } catch (error) {
    yield put(getCatalogDailyTrendsError(error));
  }
}

export function* getAllCatalogDailyTrends({payload}) {
  try {
    const data = yield select(getUserBasicInfo);

    if (data?.biz_type) {
      const response = yield getAllCatalogDailyTrendsApiCall({
        summary_by: payload,
        data,
      });
      yield put(getAllCatalogDailyTrendsSuccess(response));
    } else {
      yield put(getAllCatalogDailyTrendsError({message: 'Biz Type Not Found'}));
    }
  } catch (error) {
    yield put(getAllCatalogDailyTrendsError(error));
  }
}

export function* getFinancialDailyTrends({payload}) {
  try {
    const data = yield select(getUserBasicInfo);

    if (data?.biz_type) {
      const govtIds = yield getFinancialDailyTrendsApiCall({
        summary_by: payload,
        data,
      });
      yield put(getFinancialDailyTrendsSuccess(govtIds));
    } else {
      yield put(getFinancialDailyTrendsError({message: 'Biz Type Not Found'}));
    }
  } catch (error) {
    yield put(getFinancialDailyTrendsError(error));
  }
}

export function* getBannerDailyTrends({payload}) {
  try {
    const data = yield select(getUserBasicInfo);
    if (data?.biz_type) {
      const govtIds = yield getBannerDailyTrendsApiCall({
        summary_by: payload,
        data,
      });
      yield put(getBannerDailyTrendsSuccess(govtIds));
    } else {
      yield put(getBannerDailyTrendsError({message: 'Biz Type Not Found'}));
    }
  } catch (error) {
    yield put(getBannerDailyTrendsError(error));
  }
}

export function* getAllBannerDailyTrends({payload}) {
  try {
    const data = yield select(getUserBasicInfo);
    if (data?.biz_type) {
      const response = yield getAllBannerDailyTrendsApiCall({
        summary_by: payload,
        data,
      });
      yield put(getAllBannerDailyTrendsSuccess(response));
    } else {
      yield put(getAllBannerDailyTrendsError({message: 'Biz Type Not Found'}));
    }
  } catch (error) {
    yield put(getAllBannerDailyTrendsError(error));
  }
}

export function* getStoreSocialHealth({payload}) {
  try {
    const data = yield select(getUserBasicInfo);

    if (data?.biz_type) {
      const govtIds = yield getStoreSocialHealthApiCall({
        summary_by: payload,
        data,
      });
      yield put(getStoreSocialHealthSuccess(govtIds));
    } else {
      yield put(getStoreSocialHealthError({message: 'Biz Type Not Found'}));
    }
  } catch (error) {
    yield put(getStoreSocialHealthError(error));
  }
}

export function* getCatalogInsights({payload}) {
  try {
    const data = yield select(getUserBasicInfo);

    const catalogInsights = yield getCatalogInsightsApiCall({
      own: data,
      ...payload,
    });
    yield put(getCatalogInsightsSuccess(catalogInsights));
  } catch (error) {
    yield put(getCatalogInsightsError(error));
  }
}

export function* getCatalogTopProducts({payload}) {
  try {
    const data = yield select(getUserBasicInfo);

    const catalogInsights = yield getCatalogTopProductsApiCall({
      own: data,
      ...payload,
    });
    yield put(getCatalogTopProductsSuccess(catalogInsights));
  } catch (error) {
    yield put(getCatalogTopProductsError(error));
  }
}

export function* getBannerInsights({payload}) {
  try {
    const data = yield select(getUserBasicInfo);

    const bannerInsights = yield getBannerInsightsApiCall({
      own: data,
      ...payload,
    });
    yield put(getBannerInsightsSuccess(bannerInsights));
  } catch (error) {
    yield put(getBannerInsightsError(error));
  }
}

export function* onGetOverallStoreStats() {
  yield takeLatest(
    insightsActionTypes.GET_OVERALL_STORE_STATS,
    getOverallStoreStats,
  );
}

export function* onGetOverallStats() {
  yield takeLatest(insightsActionTypes.GET_OVERALL_STATS, getOverallStats);
}

export function* onGetCatalogDailyTrends() {
  yield takeLatest(
    insightsActionTypes.GET_CATALOG_DAILY_TRENDS,
    getCatalogDailyTrends,
  );
}

export function* onGetAllCatalogDailyTrends() {
  yield takeLatest(
    insightsActionTypes.GET_ALL_CATALOG_DAILY_TRENDS,
    getAllCatalogDailyTrends,
  );
}

export function* onGetFinancialDailyTrends() {
  yield takeLatest(
    insightsActionTypes.GET_FINANCIAL_DAILY_TRENDS,
    getFinancialDailyTrends,
  );
}

export function* onGetBannerDailyTrends() {
  yield takeLatest(
    insightsActionTypes.GET_BANNER_DAILY_TRENDS,
    getBannerDailyTrends,
  );
}

export function* onGetAllBannerDailyTrends() {
  yield takeLatest(
    insightsActionTypes.GET_ALL_BANNER_DAILY_TRENDS,
    getAllBannerDailyTrends,
  );
}

export function* onGetStoreSocialHealth() {
  yield takeLatest(
    insightsActionTypes.GET_STORE_SOCIAL_HEALTH,
    getStoreSocialHealth,
  );
}

export function* onGetCatalogInsights() {
  yield takeLatest(
    insightsActionTypes.GET_CATALOG_INSIGHTS,
    getCatalogInsights,
  );
}

export function* onGetCatalogTopProducts() {
  yield takeLatest(
    insightsActionTypes.GET_CATALOG_TOP_PRODUCTS,
    getCatalogTopProducts,
  );
}

export function* onGetBannerInsights() {
  yield takeLatest(insightsActionTypes.GET_BANNER_INSIGHTS, getBannerInsights);
}

export function* insightsSagas() {
  yield all([
    call(onGetOverallStoreStats),
    call(onGetOverallStats),
    call(onGetCatalogDailyTrends),
    call(onGetAllCatalogDailyTrends),
    call(onGetFinancialDailyTrends),
    call(onGetBannerDailyTrends),
    call(onGetAllBannerDailyTrends),
    call(onGetStoreSocialHealth),
    call(onGetCatalogInsights),
    call(onGetCatalogTopProducts),
    call(onGetBannerInsights),
  ]);
}
