import {all, call, put, select, takeLatest} from 'redux-saga/effects';

import {
  getReviewsSuccess,
  getReviewsError,
  getReviewSummarySuccess,
  getReviewSummaryError,
  helpUsImproveSuccess,
  helpUsImproveError,
  postReviewSuccess,
  postReviewError,
} from './reviewActions';
import {
  CUSTOMER_HELP_US_IMPROVE,
  GET_REVIEWS,
  GET_REVIEW_SUMMARY,
  HELP_US_IMPROVE,
  POST_REVIEW,
} from '../../utils/ApiList';
import Api from '../../middlewares/Api';
import reviewActionTypes from './reviewActionTypes';
import {getRole, getUserBasicInfo} from '../rootSelector';

const getReviewsApiCall = async payload => {
  const response = await Api.post(`${GET_REVIEWS}`, payload);
  return response.data;
};

const getReviewSummaryApiCall = async payload => {
  const response = await Api.post(`${GET_REVIEW_SUMMARY}`, payload);
  return response.data;
};

const helpUsImproveApiCall = async payload => {
  const response = await Api.post(
    `${
      payload?.profile_type === 'Customer'
        ? CUSTOMER_HELP_US_IMPROVE
        : HELP_US_IMPROVE
    }`,
    payload,
  );
  return response.data;
};

const postReviewApiCall = async payload => {
  const response = await Api.post(POST_REVIEW, payload);
  return response.data;
};

export function* getReviews({payload}) {
  try {
    const basic_info = yield select(getUserBasicInfo);
    const response = yield getReviewsApiCall({
      own: basic_info,
      catalog_id: 0,
      product_id: 0,
      review_filter: {
        rating_min_value: -1,
        date_filter: '',
      },
      review_sort: {
        rating: '',
        review_time: '',
      },
      ...payload,
    });
    yield put(getReviewsSuccess(response));
  } catch (error) {
    yield put(getReviewsError(error));
  }
}

export function* getReviewSummary({payload}) {
  try {
    const basic_info = yield select(getUserBasicInfo);
    if (basic_info?.biz_type) {
      const response = yield getReviewSummaryApiCall(basic_info);
      yield put(getReviewSummarySuccess(response));
    } else {
      yield put(getReviewSummaryError({message: 'Biz Type Not Found'}));
    }
  } catch (error) {
    yield put(getReviewSummaryError(error));
  }
}

export function* helpUsImprove({payload}) {
  try {
    const basic_info = yield select(getUserBasicInfo);
    const role = yield select(getRole);
    const response = yield helpUsImproveApiCall({
      basic_info,
      profile_type: role,
      ...payload,
    });
    yield put(helpUsImproveSuccess(response));
  } catch (error) {
    yield put(helpUsImproveError(error));
  }
}

export function* postReview({payload}) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const response = yield postReviewApiCall({
      cus: {
        ...basicInfo,
        id: basicInfo?.id ?? '',
        district: basicInfo?.district ?? 'Gautam Buddha Nagar',
        LoB: basicInfo?.LoB ?? 'Fashion',
      },
      ...payload,
    });
    yield put(postReviewSuccess(response));
  } catch (error) {
    yield put(postReviewError(error));
  }
}

export function* onGetReviews() {
  yield takeLatest(reviewActionTypes.GET_REVIEWS, getReviews);
}

export function* onGetReviewSummary() {
  yield takeLatest(reviewActionTypes.GET_REVIEW_SUMMARY, getReviewSummary);
}

export function* onHelpUsImprove() {
  yield takeLatest(reviewActionTypes.HELP_US_IMPROVE, helpUsImprove);
}

export function* onPostReview() {
  yield takeLatest(reviewActionTypes.POST_REVIEW, postReview);
}

export function* reviewSagas() {
  yield all([
    call(onGetReviews),
    call(onGetReviewSummary),
    call(onHelpUsImprove),
    call(onPostReview),
  ]);
}
