import {all, call, put, select, takeLatest} from 'redux-saga/effects';

import {
  getAllOperationalDistrictsError,
  getAllOperationalDistrictsSuccess,
  getBannerMetadataError,
  getBannerMetadataSuccess,
  getProductMetadataError,
  getProductMetadataSuccess,
  getStoreMetadataSuccess,
  getSubCategorySizesError,
  getSubCategorySizesSuccess,
} from './masterActions';
import {
  GET_ALL_OPERATIONAL_DISTRICTS,
  GET_BANNER_METADATA,
  GET_PRODUCT_METADATA,
  GET_STORE_METADATA,
  GET_SUB_CATEGORY_SIZES,
} from '../../utils/ApiList';
import Api from '../../middlewares/Api';
import masterActionTypes from './masterActionTypes';
import authActionTypes from '../auth/authActionTypes';
import {getUserBasicInfo} from '../rootSelector';

const getSubCategorySizesApiCall = async payload => {
  const response = await Api.post(`${GET_SUB_CATEGORY_SIZES}`, payload);
  return response.data;
};

const getAllOperationalDistrictsApiCall = async () => {
  const response = await Api.post(`${GET_ALL_OPERATIONAL_DISTRICTS}`, {
    country: 'India',
  });
  return response.data;
};

const getProductMetadataApiCall = async data => {
  const response = await Api.post(`${GET_PRODUCT_METADATA}`, data);
  return response?.data;
};

const getBannerMetadataApiCall = async data => {
  const response = await Api.post(`${GET_BANNER_METADATA}`, data);
  return response.data;
};

const getStoreMetadataApiCall = async data => {
  const response = await Api.post(`${GET_STORE_METADATA}?page=1&size=50`, data);
  return response.data;
};

export function* getSubCategorySizes({payload}) {
  try {
    const basic_info = yield select(getUserBasicInfo);
    const subCategorySizes = yield getSubCategorySizesApiCall({
      ...payload,
      data: basic_info,
      biz_type: basic_info?.biz_type[0],
      lob: basic_info?.LoB,
      category: 'string',
    });
    yield put(getSubCategorySizesSuccess(subCategorySizes));
  } catch (error) {
    yield put(getSubCategorySizesError(error));
  }
}

export function* getAllOperationalDistricts() {
  try {
    const subCategorySizes = yield getAllOperationalDistrictsApiCall();
    yield put(getAllOperationalDistrictsSuccess(subCategorySizes));
  } catch (error) {
    yield put(getAllOperationalDistrictsError(error));
  }
}

export function* getProductMetadata() {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const productMetadata = yield getProductMetadataApiCall({
      data: {
        ...basicInfo,
        id: basicInfo?.id ?? '',
        district: basicInfo?.district ?? 'Gautam Buddha Nagar',
        LoB: basicInfo?.LoB ?? 'Fashion',
      },
    });
    yield put(getProductMetadataSuccess(productMetadata));
  } catch (error) {
    yield put(getProductMetadataError(error));
  }
}

export function* getBannerMetadata() {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const bannerMetadata = yield getBannerMetadataApiCall({
      data: {
        ...basicInfo,
        id: basicInfo?.id ?? '',
        district: basicInfo?.district ?? 'Gautam Buddha Nagar',
        LoB: basicInfo?.LoB ?? 'Fashion',
      },
    });
    yield put(getBannerMetadataSuccess(bannerMetadata));
  } catch (error) {
    yield put(getBannerMetadataError(error));
  }
}

export function* getStoreMetadata() {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const storeMetadata = yield getStoreMetadataApiCall({
      data: {
        ...basicInfo,
        id: basicInfo?.id ?? '',
        district: basicInfo?.district ?? 'Gautam Buddha Nagar',
        LoB: basicInfo?.LoB ?? 'Fashion',
      },
    });
    yield put(getStoreMetadataSuccess(storeMetadata));
  } catch (error) {
    yield put(getStoreMetadataSuccess(error));
  }
}

export function* onGetSubCategorySizes() {
  yield takeLatest(
    masterActionTypes.GET_SUB_CATEGORY_SIZES,
    getSubCategorySizes,
  );
}

export function* onGetAllOperationalDistricts() {
  yield takeLatest(
    [
      masterActionTypes.GET_ALL_OPERATIONAL_DISTRICTS,
      authActionTypes.CUSTOMER_GENERAL_VALIDATE_OTP_SUCCESS,
      authActionTypes.SIGNUP_CUSTOMER_FROM_BO_SUCCESS,
      authActionTypes.SOCIAL_SIGNUP_SUCCESS,
      authActionTypes.SET_OWNER_BASIC_INFO,
      authActionTypes.CHANGE_ROLE,
    ],
    getAllOperationalDistricts,
  );
}

export function* onGetProductMetaData() {
  yield takeLatest(masterActionTypes.GET_PRODUCT_METADATA, getProductMetadata);
}

export function* onGetBannerMetaData() {
  yield takeLatest(masterActionTypes.GET_BANNER_METADATA, getBannerMetadata);
}

export function* onGetStoreMetaData() {
  yield takeLatest(masterActionTypes.GET_STORE_METADATA, getStoreMetadata);
}

export function* masterSagas() {
  yield all([
    call(onGetSubCategorySizes),
    call(onGetAllOperationalDistricts),
    call(onGetProductMetaData),
    call(onGetBannerMetaData),
    call(onGetStoreMetaData),
  ]);
}
