import {all, call} from 'redux-saga/effects';

import {authSagas} from './auth/authSagas';
import {onBoardingSagas} from './onBoarding/onBoardingSagas';
import {productSagas} from './product/productSagas';
import {catalogSagas} from './catalog/catalogSagas';
import {bannerSagas} from './banner/bannerSagas';
import {insightsSagas} from './insights/insightsSagas';
import {subscriptionSagas} from './subscription/subscriptionSagas';
import {masterSagas} from './master/masterSagas';
import {orderSagas} from './order/orderSagas';
import {appSagas} from './app/appSagas';
import {notifySagas} from './notify/notifySagas';
import {reviewSagas} from './review/reviewSagas';
import {followSagas} from './follow/followSagas';
import {storeSagas} from './store/storeSagas';

export default function* rootSaga() {
  yield all([
    call(appSagas),
    call(authSagas),
    call(onBoardingSagas),
    call(productSagas),
    call(catalogSagas),
    call(bannerSagas),
    call(insightsSagas),
    call(subscriptionSagas),
    call(masterSagas),
    call(orderSagas),
    call(notifySagas),
    call(reviewSagas),
    call(followSagas),
    call(storeSagas),
  ]);
}
