import types from './storeActionTypes';

export const getStoreProfile = payload => {
  return {
    type: types.GET_STORE_PROFILE,
    payload: payload,
  };
};

export const getStoreProfileSuccess = data => {
  return {
    type: types.GET_STORE_PROFILE_SUCCESS,
    payload: data,
  };
};

export const getStoreProfileError = data => {
  return {
    type: types.GET_STORE_PROFILE_ERROR,
    payload: data,
  };
};

export const getAllStoreCatalogs = payload => {
  return {
    type: types.GET_ALL_STORE_CATALOGS,
    payload: payload,
  };
};

export const getAllStoreCatalogsSuccess = data => {
  return {
    type: types.GET_ALL_STORE_CATALOGS_SUCCESS,
    payload: data,
  };
};

export const getAllStoreCatalogsError = data => {
  return {
    type: types.GET_ALL_STORE_CATALOGS_ERROR,
    payload: data,
  };
};

export const getOwnMarket = payload => {
  return {
    type: types.GET_OWN_MARKET,
    payload: payload,
  };
};

export const getOwnMarketSuccess = data => {
  return {
    type: types.GET_OWN_MARKET_SUCCESS,
    payload: data,
  };
};

export const getOwnMarketError = data => {
  return {
    type: types.GET_OWN_MARKET_ERROR,
    payload: data,
  };
};

export const addQuickProducts = (payload, action) => {
  return {
    type: types.ADD_QUICK_PRODUCTS,
    payload: payload,
    action: action,
  };
};

export const addQuickProductsSuccess = data => {
  return {
    type: types.ADD_QUICK_PRODUCTS_SUCCESS,
    payload: data,
  };
};

export const addQuickProductsError = data => {
  return {
    type: types.ADD_QUICK_PRODUCTS_ERROR,
    payload: data,
  };
};
