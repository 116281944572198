import types from "./orderActionTypes";

const INITIAL_STATE = {
  orders: {
    isLoading: false,
    data: [],
    error: null,
  },
};

const orderReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_ALL_ORDERS:
      return {
        ...state,
        orders: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_ALL_ORDERS_SUCCESS:
      return {
        ...state,
        orders: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.GET_ALL_ORDERS_ERROR:
      return {
        ...state,
        orders: {
          ...state?.orders,
          isLoading: false,
          error: action.payload,
        },
      };
    case types.GET_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        selectedOrder: {
          ...state?.selectedOrder,
          status: action.payload,
        },
      };
    case types.GET_REFERENCE_ORDER_STATUS_SUCCESS:
      return {
        ...state,
        referenceStatus: {
          data: action.payload,
        },
      };
    case types.CREATE_CART:
      return {
        ...state,
        cart: {
          isLoading: true,
          error: null,
        },
      };
    case types.CREATE_CART_SUCCESS:
      return {
        ...state,
        cart: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.CREATE_CART_ERROR:
      return {
        ...state,
        cart: {
          ...state.cart,
          isLoading: false,
          error: action.payload,
        },
      };
    case types.GET_CART_SUMMARY:
      return {
        ...state,
        cartSummary: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_CART_SUMMARY_SUCCESS:
      return {
        ...state,
        cartSummary: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.GET_CART_SUMMARY_ERROR:
      return {
        ...state,
        cartSummary: {
          ...state.cartSummary,
          isLoading: false,
          error: action.payload,
        },
      };
    case types.PROCEED_TO_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentDetails: action.payload,
      };
    case types.GET_MY_ADDRESSES:
      return {
        ...state,
        myAddresses: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_MY_ADDRESSES_SUCCESS:
      return {
        ...state,
        myAddresses: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.GET_MY_ADDRESSES_ERROR:
      return {
        ...state,
        myAddresses: {
          ...state.myAddresses,
          isLoading: false,
          error: action.payload,
        },
      };
    case types.GET_LATEST_ORDER:
      return {
        ...state,
        latestOrder: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_LATEST_ORDER_SUCCESS:
      return {
        ...state,
        latestOrder: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.GET_LATEST_ORDER_ERROR:
      return {
        ...state,
        latestOrder: {
          ...state.latestOrder,
          isLoading: false,
          error: action.payload,
        },
      };
    case types.GET_ALL_CUSTOMER_ORDERS:
      return {
        ...state,
        customerOrders: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_ALL_CUSTOMER_ORDERS_SUCCESS:
      return {
        ...state,
        customerOrders: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.GET_ALL_CUSTOMER_ORDERS_ERROR:
      return {
        ...state,
        customerOrders: {
          ...state?.customerOrders,
          isLoading: false,
          error: action.payload,
        },
      };
    case types.GET_MY_ADDRESSES:
      return {
        ...state,
        addresses: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_MY_ADDRESSES_SUCCESS:
      return {
        ...state,
        addresses: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.GET_MY_ADDRESSES_ERROR:
      return {
        ...state,
        addresses: {
          ...state?.addresses,
          isLoading: false,
          error: action.payload,
        },
      };
    case types.CLEAR_CART:
    case types.DISCARD_CART_SUCCESS:
      return {
        ...state,
        cartSummary: undefined,
        cart: undefined,
      };
    case types.CALCULATE_SHIPMENT:
      return {
        ...state,
        shipment: { isLoading: true, error: null },
      };
    case types.CALCULATE_SHIPMENT_SUCCESS:
      return {
        ...state,
        shipment: { data: action.payload, isLoading: false, error: null },
      };
    case types.CALCULATE_SHIPMENT_ERROR:
      return {
        ...state,
        shipment: {
          ...state?.shipment,
          error: action.payload,
          isLoading: false,
        },
      };
    case types.VERIFY_COUPON:
      return {
        ...state,
        couponDetails: {
          error: null,
          isLoading: true,
          data: { couponCode: action.payload },
        },
      };
    case types.VERIFY_COUPON_SUCCESS:
      return {
        ...state,
        couponDetails: {
          data: { ...state?.couponDetails?.data, ...action.payload },
          error: null,
          isLoading: false,
        },
      };
    case types.VERIFY_COUPON_ERROR:
      return {
        ...state,
        couponDetails: {
          error: action.payload,
          isLoading: false,
        },
      };
    case types.REGISTER_CUSTOMER:
      return {
        ...state,
        userDetailsPayload: action.payload,
      };
    case types.REGISTER_CUSTOMER_SUCCESS:
      return {
        ...state,
        userDetails: action.payload,
      };
    case types.CHECK_PINCODE:
      return {
        ...state,
        checkPincode: {
          ...(state?.checkPincode ?? {}),
          isLoading: true,
          error: null,
        },
      };
    case types.CHECK_PINCODE_ERROR:
      return {
        ...state,
        checkPincode: {
          ...state?.checkPincode,
          isLoading: false,
          error: action?.payload,
        },
      };
    case types.CHECK_PINCODE_SUCCESS:
      return {
        ...state,
        checkPincode: {
          data: state?.checkPincode?.data
            ? Array.from(
                [...state?.checkPincode?.data, ...action.payload]
                  .reduce((map, item) => map.set(item.unit, item), new Map())
                  .values()
              )
            : action.payload,
          isLoading: false,
        },
      };
    case types.TRACK_ORDER:
      return {
        ...state,
        trackOrder: {
          isLoading: true,
          error: null,
        },
      };
    case types.TRACK_ORDER_ERROR:
      return {
        ...state,
        trackOrder: {
          ...state?.checkPincode,
          isLoading: false,
          error: action?.payload,
        },
      };
    case types.TRACK_ORDER_SUCCESS:
      return {
        ...state,
        trackOrder: {
          data: state?.trackOrder?.data
            ? [...state?.trackOrder?.data, ...action.payload]
            : action.payload,
          isLoading: false,
        },
      };
    default:
      return state;
  }
};

export default orderReducer;
