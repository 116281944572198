import types from './reviewActionTypes';

const INITIAL_STATE = {
  summary: {},
  reviews: [],
};

const reviewReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_REVIEW_SUMMARY_SUCCESS:
      return {
        ...state,
        summary: {
          data: action.payload,
        },
      };
    case types.GET_REVIEWS_SUCCESS:
      return {
        ...state,
        reviews: {
          data: action.payload,
        },
      };
    default:
      return state;
  }
};

export default reviewReducer;
