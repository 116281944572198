import axios from "axios";
import { Buffer } from "buffer";
import store from "../store";
// import Toast from "react-native-toast-message";
import { logOut, refreshTokenSuccess } from "../store/auth/authActions";
import CryptoJS from "crypto-js";
import { NON_ENCRYPTED_APIS, PUBLIC_APIS } from "../utils/ApiList";

const auth = Buffer.from("admin:dD@!aK28@fhihayQ").toString("base64");

function buildHeadersInstance(isRefreshToken) {
  const state = store?.getState();
  const user = state.order?.userDetails
    ? state.order?.userDetails
    : state?.auth?.currentUser?.customer;
  const accessToken = user?.access_token;
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    Authorization: `Basic ${auth}`,
    token: accessToken
      ? "Bearer " + accessToken
      : "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMDEwMSIsImV4cCI6MTc0NjMyOTgyM30.XdHyBcscmdMpkrYs03twpnkaoFwWAgg4o581eLPtUJc",
  };
}

let isTokenRefreshing = false;
let requestQueue = [];

let Api = axios.create({
  baseURL: "https://248da6c2d47d7840.ngrok.app/",
});

Api.interceptors.request.use(
  (config) => {
    const headers = buildHeadersInstance(
      config?.url === "owner/refresh_token" ||
        config?.url === "customer/refresh_token"
    );
    config.headers = {
      ...config.headers,
      ...headers,
    };

    if (
      !PUBLIC_APIS.find((u) => config.url.includes(u)) &&
      !NON_ENCRYPTED_APIS.find((url) => config.url.includes(url))
    ) {
      const state = store?.getState();
      const user = state.order?.userDetails
        ? state.order?.userDetails
        : state?.auth?.currentUser?.customer;
      if (user?.user?.id) {
        if (!config.params) {
          config.params = {};
        }
        config.params.dl = false;
        let iv = CryptoJS.enc.Utf8.parse(user.db_verify[1]);
        let key = user.db_verify[0];
        key = CryptoJS.enc.Utf8.parse(key);
        let data =
          typeof config.data === "object" && !!config.data
            ? JSON.stringify(config.data)
            : JSON.stringify(user?.user);

        var encrypted = CryptoJS.AES.encrypt(data, key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
        });
        encrypted = encrypted.toString();
        config.data = {
          salt: user.salt,
          par: encrypted,
        };
      } else {
        if (!config.params) {
          config.params = {};
        }
        config.params.dl = true;
        let iv = CryptoJS.enc.Utf8.parse("DBDBDB0000010101");
        let key = "DBDBDB0000010101";
        key = CryptoJS.enc.Utf8.parse(key);
        let data =
          typeof config.data === "object" && !!config.data
            ? JSON.stringify(config.data)
            : JSON.stringify(user.owner?.user);

        var encrypted = CryptoJS.AES.encrypt(data, key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
        });
        encrypted = encrypted.toString();
        config.data = {
          salt: "qb2ELaiM4KrQvpcL5VyvXg==",
          par: encrypted,
        };
      }
      // if (
      //   config.url.includes('get_otp') ||
      //   config.url.includes('validate_otp') ||
      //   config.url.includes('get_customer_otp') ||
      //   config.url.includes('validate_customer_otp')
      // ) {
      //   config.headers = {
      //     ...config.headers,
      //     Authorization: `Basic ${auth}`,
      //   };
      // }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

Api.interceptors.response.use(
  (response) => {
    const state = store?.getState();
    const user = state.order?.userDetails
      ? state.order?.userDetails
      : state?.auth?.currentUser?.customer;

    if (
      !PUBLIC_APIS.find((u) => response.config.url.includes(u)) &&
      !NON_ENCRYPTED_APIS.find((url) => response.config.url.includes(url))
    ) {
      if (user?.user?.id) {
        let Base64CBC = response?.data?.data;
        let iv = CryptoJS.enc.Utf8.parse(user.db_verify[1]);
        let key = user.db_verify[0]; //key used in Python
        key = CryptoJS.enc.Utf8.parse(key);
        let decrypted = CryptoJS.AES.decrypt(Base64CBC, key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
        });
        decrypted = decrypted.toString(CryptoJS.enc.Utf8);
        response.data = JSON.parse(decrypted);
      } else {
        let Base64CBC = response?.data?.data;
        let iv = CryptoJS.enc.Utf8.parse("DBDBDB0000010101");
        let key = "DBDBDB0000010101"; //key used in Python
        key = CryptoJS.enc.Utf8.parse(key);
        let decrypted = CryptoJS.AES.decrypt(Base64CBC, key, {
          iv: iv,
          mode: CryptoJS.mode.CBC,
        });
        decrypted = decrypted.toString(CryptoJS.enc.Utf8);
        response.data = JSON.parse(decrypted);
      }
    }

    return response;
  },
  (error) => {
    if (error.response) {
      const originalRequest = error.config;
      if (error.response.status === 401) {
        let dispatch = store.dispatch;
        requestQueue.append(originalRequest);
        if (originalRequest?.url.includes("validate_general_otp")) {
          dispatch(logOut());
          // Toast.show({
          //   type: "error",
          //   text1: "You have entered wrong OTP",
          // });
        } else if (!isTokenRefreshing) {
          const state = store.getState();
          const basicInfo =
            state?.onBoarding?.ownerDetails?.data ??
            state?.auth?.currentUser?.customer?.user;
          const role = state?.auth?.role;
          if (!basicInfo) {
            dispatch(logOut());
          } else {
            isTokenRefreshing = true;
            Api.post(
              role === "Business Owner"
                ? "owner/refresh_token"
                : "customer/refresh_token",
              basicInfo
            )
              .then((res) => {
                if (res?.status === 200) {
                  dispatch(refreshTokenSuccess(res?.data));
                  for (let req of requestQueue) {
                    Api(req);
                  }
                  isTokenRefreshing = false;
                  return Api({
                    ...originalRequest,
                    headers: {
                      ...originalRequest.headers,
                      Accept: "application/json",
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${res?.data?.access_token}`,
                      token: `Bearer ${res?.data?.access_token}`,
                    },
                  });
                } else if (res?.status === 401) {
                  dispatch(logOut());
                  // Toast.show({
                  //   type: "error",
                  //   text1: "Session expired, please relogin",
                  // });
                } else if (res?.status === 422 || res?.status === 403) {
                  dispatch(logOut());
                } else {
                  dispatch(logOut());
                }
                isTokenRefreshing = false;
              })
              .catch((error1) => {
                console.log(error1);
                isTokenRefreshing = false;
              });
          }
        }
      } else {
        if (error.response?.data) {
          const errorDetail = error.response?.data?.detail;
          const message =
            typeof errorDetail === "object"
              ? JSON.stringify(errorDetail)
              : error.response?.data?.detail?.toString() ??
                error.response?.data?.toString();
          if (!message.includes("Unauthorized")) {
            // Toast.show({
            //   type: "error",
            //   text1: message,
            // });
          }
        }

        throw new Error(
          error.response?.data?.detail?.toString() ??
            error.response?.data?.toString() ??
            error
        );
      }
    } else {
      return Promise.reject(error);
    }
  }
);

export default Api;
