export const getRole = (state) => state?.auth?.role;

export const getCurrentUser = (state) => state?.auth?.currentUser;

export const getPhoneNumber = (state) =>
  state?.auth?.currentUser?.mobile_number;

export const getUserBasicInfo = (state) =>
  state?.order?.userDetails?.user
    ? state?.order?.userDetails?.user
    : {
        ...state?.auth?.currentUser?.customer?.user,
        LoB: "Gifting & Stationary",
      };

export const getCustomerDetails = (state) =>
  state?.onBoarding?.customerDetails?.data;

export const getOwnerDp = (state) => state?.onBoarding?.ownerDp;

export const getStoreCategoriesSelector = (state) =>
  state?.product?.storeCategories?.data;

export const getSubStoreCategoriesSelector = (state) =>
  state?.product?.storeSubCategories?.data;
