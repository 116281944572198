const userActionTypes = {
  SELECT_ROLE: 'SELECT_ROLE',

  GET_OTP: 'GET_OTP',
  GET_OTP_SUCCESS: 'GET_OTP_SUCCESS',
  GET_OTP_ERROR: 'GET_OTP_ERROR',
  VALIDATE_OTP: 'VALIDATE_OTP',
  VALIDATE_OTP_SUCCESS: 'VALIDATE_OTP_SUCCESS',
  VALIDATE_OTP_ERROR: 'VALIDATE_OTP_ERROR',

  GENERAL_VALIDATE_OTP: 'GENERAL_VALIDATE_OTP',
  GENERAL_VALIDATE_OTP_SUCCESS: 'GENERAL_VALIDATE_OTP_SUCCESS',
  GENERAL_VALIDATE_OTP_ERROR: 'GENERAL_VALIDATE_OTP_ERROR',

  CUSTOMER_GENERAL_VALIDATE_OTP: 'CUSTOMER_GENERAL_VALIDATE_OTP',
  CUSTOMER_GENERAL_VALIDATE_OTP_SUCCESS:
    'CUSTOMER_GENERAL_VALIDATE_OTP_SUCCESS',
  CUSTOMER_GENERAL_VALIDATE_OTP_ERROR: 'CUSTOMER_GENERAL_VALIDATE_OTP_ERROR',

  SOCIAL_SIGNUP: 'SOCIAL_SIGNUP',
  SOCIAL_SIGNUP_SUCCESS: 'SOCIAL_SIGNUP_SUCCESS',
  SOCIAL_SIGNUP_ERROR: 'SOCIAL_SIGNUP_ERROR',

  SIGNUP_BO_FROM_CUSTOMER: 'SIGNUP_BO_FROM_CUSTOMER',
  SIGNUP_BO_FROM_CUSTOMER_SUCCESS: 'SIGNUP_BO_FROM_CUSTOMER_SUCCESS',
  SIGNUP_BO_FROM_CUSTOMER_ERROR: 'SIGNUP_BO_FROM_CUSTOMER_ERROR',

  SIGNUP_CUSTOMER_FROM_BO: 'SIGNUP_CUSTOMER_FROM_BO',
  SIGNUP_CUSTOMER_FROM_BO_SUCCESS: 'SIGNUP_CUSTOMER_FROM_BO_SUCCESS',
  SIGNUP_CUSTOMER_FROM_BO_ERROR: 'SIGNUP_CUSTOMER_FROM_BO_ERROR',

  CHANGE_ROLE: 'CHANGE_ROLE',

  LOG_IN_START: 'LOG_IN_START',
  LOG_IN_SUCCESS: 'LOG_IN_SUCCESS',
  LOG_IN_FAILURE: 'LOG_IN_FAILURE',
  REGISTER_START: 'REGISTER_START',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAILURE: 'REGISTER_FAILURE',
  LOG_OUT: 'LOG_OUT',
  SKIP_FOR_NOW: 'SKIP_FOR_NOW',

  GET_CUSTOMER_OTP: 'GET_CUSTOMER_OTP',
  GET_CUSTOMER_OTP_SUCCESS: 'GET_CUSTOMER_OTP_SUCCESS',
  GET_CUSTOMER_OTP_ERROR: 'GET_CUSTOMER_OTP_ERROR',

  SELECT_DISTRICT: 'SELECT_DISTRICT',
  CHANGE_LOB: 'CHANGE_LOB',
  SET_OWNER_BASIC_INFO: 'SET_OWNER_BASIC_INFO',

  VALIDATE_CUSTOMER_OTP: 'VALIDATE_CUSTOMER_OTP',
  VALIDATE_CUSTOMER_OTP_SUCCESS: 'VALIDATE_CUSTOMER_OTP_SUCCESS',
  VALIDATE_CUSTOMER_OTP_ERROR: 'VALIDATE_CUSTOMER_OTP_ERROR',

  REFRESH_TOKEN: 'REFRESH_TOKEN',
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_ERROR: 'REFRESH_TOKEN_ERROR',

  GET_CUSTOMER_DISTRICT: 'GET_CUSTOMER_DISTRICT',
  GET_CUSTOMER_DISTRICT_SUCCESS: 'GET_CUSTOMER_DISTRICT_SUCCESS',
  GET_CUSTOMER_DISTRICT_ERROR: 'GET_CUSTOMER_DISTRICT_ERROR',
};

export default userActionTypes;
