import types from "./appActionTypes";

const INITIAL_STATE = {
  analysisTypes: {},
};

const masterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_ANALYSIS_TYPES_SUCCESS:
      return {
        ...state,
        analysisTypes: {
          data: action.payload,
        },
        error: null,
      };
    case types.GET_ANALYSIS_TYPES_ERROR:
      return {
        ...state,
        analysisTypes: {
          ...state.analysisTypes,
          error: action.payload,
        },
        error: null,
      };
    case types.SET_DASHBOARD_VISITED:
      return {
        ...state,
        dashboardVisited: true,
      };
    case types.SET_ZIPCODE:
      return {
        ...state,
        zipcode: action.payload,
      };
    default:
      return state;
  }
};

export default masterReducer;
