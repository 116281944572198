import types from './masterActionTypes';

const INITIAL_STATE = {
  currentUser: null,
  onBoarding: null,
  error: null,
};

const masterReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_SUB_CATEGORY_SIZES_SUCCESS:
      return {
        ...state,
        subCategorySizes: {
          data: action.payload,
        },
        error: null,
      };
    case types.GET_ALL_OPERATIONAL_DISTRICTS_SUCCESS:
      return {
        ...state,
        allOperationalDistricts: {
          data: action.payload,
        },
        error: null,
      };
    case types.GET_PRODUCT_METADATA_SUCCESS:
      return {
        ...state,
        productMetadata: {
          data: action.payload,
        },
      };
    case types.GET_BANNER_METADATA_SUCCESS:
      return {
        ...state,
        bannerMetadata: {
          data: action.payload,
        },
      };
    case types.GET_STORE_METADATA_SUCCESS:
      return {
        ...state,
        storeMetadata: {
          data: action.payload,
        },
      };
    default:
      return state;
  }
};

export default masterReducer;
