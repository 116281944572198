import types from "./orderActionTypes";

export const getAllOrders = (body) => {
  return {
    type: types.GET_ALL_ORDERS,
    payload: body,
  };
};

export const getAllOrdersSuccess = (body) => {
  return {
    type: types.GET_ALL_ORDERS_SUCCESS,
    payload: body,
  };
};

export const getAllOrdersError = (body) => {
  return {
    type: types.GET_ALL_ORDERS_ERROR,
    payload: body,
  };
};

export const getReferenceOrderStatus = (body) => {
  return {
    type: types.GET_REFERENCE_ORDER_STATUS,
    payload: body,
  };
};

export const getReferenceOrderStatusSuccess = (body) => {
  return {
    type: types.GET_REFERENCE_ORDER_STATUS_SUCCESS,
    payload: body,
  };
};

export const getReferenceOrderStatusError = (body) => {
  return {
    type: types.GET_REFERENCE_ORDER_STATUS_ERROR,
    payload: body,
  };
};

export const getOrderSummary = (body) => {
  return {
    type: types.GET_ORDER_SUMMARY,
    payload: body,
  };
};

export const getOrderSummarySuccess = (body) => {
  return {
    type: types.GET_ORDER_SUMMARY_SUCCESS,
    payload: body,
  };
};

export const getOrderSummaryError = (body) => {
  return {
    type: types.GET_ORDER_SUMMARY_ERROR,
    payload: body,
  };
};

export const submitOrderResponse = (body, action) => {
  return {
    type: types.SUBMIT_ORDER_RESPONSE,
    payload: body,
    action: action,
  };
};

export const submitOrderResponseSuccess = (body) => {
  return {
    type: types.SUBMIT_ORDER_RESPONSE_SUCCESS,
    payload: body,
  };
};

export const submitOrderResponseError = (body) => {
  return {
    type: types.SUBMIT_ORDER_RESPONSE_ERROR,
    payload: body,
  };
};

export const getOrderStatus = (body) => {
  return {
    type: types.GET_ORDER_STATUS,
    payload: body,
  };
};

export const getOrderStatusSuccess = (body) => {
  return {
    type: types.GET_ORDER_STATUS_SUCCESS,
    payload: body,
  };
};

export const getOrderStatusError = (body) => {
  return {
    type: types.GET_ORDER_STATUS_ERROR,
    payload: body,
  };
};

export const orderReadyForPickup = (body) => {
  return {
    type: types.ORDER_READY_FOR_PICKUP,
    payload: body,
  };
};

export const orderReadyForPickupSuccess = (body) => {
  return {
    type: types.ORDER_READY_FOR_PICKUP_SUCCESS,
    payload: body,
  };
};

export const orderReadyForPickupError = (body) => {
  return {
    type: types.ORDER_READY_FOR_PICKUP_ERROR,
    payload: body,
  };
};

export const orderPicked = (body) => {
  return {
    type: types.ORDER_PICKED,
    payload: body,
  };
};

export const orderPickedSuccess = (body) => {
  return {
    type: types.ORDER_PICKED_SUCCESS,
    payload: body,
  };
};

export const orderPickedError = (body) => {
  return {
    type: types.ORDER_PICKED_ERROR,
    payload: body,
  };
};

export const createCart = (body, action) => {
  return {
    type: types.CREATE_CART,
    payload: body,
    action: action,
  };
};

export const createCartSuccess = (body, action) => {
  return {
    type: types.CREATE_CART_SUCCESS,
    payload: body,
    action,
  };
};

export const createCartError = (body) => {
  return {
    type: types.CREATE_CART_ERROR,
    payload: body,
  };
};

export const addToCart = (body, action) => {
  return {
    type: types.ADD_TO_CART,
    payload: body,
    action: action,
  };
};

export const addToCartSuccess = (body) => {
  return {
    type: types.ADD_TO_CART_SUCCESS,
    payload: body,
  };
};

export const addToCartError = (body) => {
  return {
    type: types.ADD_TO_CART_ERROR,
    payload: body,
  };
};

export const getCartSummary = (body) => {
  return {
    type: types.GET_CART_SUMMARY,
    payload: body,
  };
};

export const getCartSummarySuccess = (body) => {
  return {
    type: types.GET_CART_SUMMARY_SUCCESS,
    payload: body,
  };
};

export const getCartSummaryError = (body) => {
  return {
    type: types.GET_CART_SUMMARY_ERROR,
    payload: body,
  };
};

export const removeCartProduct = (body) => {
  return {
    type: types.REMOVE_CART_PRODUCT,
    payload: body,
  };
};

export const removeCartProductSuccess = (body) => {
  return {
    type: types.REMOVE_CART_PRODUCT_SUCCESS,
    payload: body,
  };
};

export const removeCartProductError = (body) => {
  return {
    type: types.REMOVE_CART_PRODUCT_ERROR,
    payload: body,
  };
};

export const getMyAddresses = (body) => {
  return {
    type: types.GET_MY_ADDRESSES,
    payload: body,
  };
};

export const getMyAddressesSuccess = (body) => {
  return {
    type: types.GET_MY_ADDRESSES_SUCCESS,
    payload: body,
  };
};

export const getMyAddressesError = (body) => {
  return {
    type: types.GET_MY_ADDRESSES_ERROR,
    payload: body,
  };
};

export const addAddresses = (body) => {
  return {
    type: types.ADD_ADDRESSES,
    payload: body,
  };
};

export const addAddressesSuccess = (body) => {
  return {
    type: types.ADD_ADDRESSES_SUCCESS,
    payload: body,
  };
};

export const addAddressesError = (body) => {
  return {
    type: types.ADD_ADDRESSES_ERROR,
    payload: body,
  };
};

export const placeOrder = (body, action) => {
  return {
    type: types.PLACE_ORDER,
    payload: body,
    action: action,
  };
};

export const placeOrderSuccess = (body) => {
  return {
    type: types.PLACE_ORDER_SUCCESS,
    payload: body,
  };
};

export const placeOrderError = (body) => {
  return {
    type: types.PLACE_ORDER_ERROR,
    payload: body,
  };
};

export const proceedToPayment = (body, action) => {
  return {
    type: types.PROCEED_TO_PAYMENT,
    payload: body,
    action: action,
  };
};

export const proceedToPaymentSuccess = (body) => {
  return {
    type: types.PROCEED_TO_PAYMENT_SUCCESS,
    payload: body,
  };
};

export const proceedToPaymentError = (body) => {
  return {
    type: types.PROCEED_TO_PAYMENT_ERROR,
    payload: body,
  };
};

export const paymentDone = (body) => {
  return {
    type: types.PAYMENT_DONE,
    payload: body,
  };
};

export const paymentDoneSuccess = (body) => {
  return {
    type: types.PAYMENT_DONE_SUCCESS,
    payload: body,
  };
};

export const paymentDoneError = (body) => {
  return {
    type: types.PAYMENT_DONE_ERROR,
    payload: body,
  };
};

export const updateCartItemQuantity = (body) => {
  return {
    type: types.UPDATE_CART_ITEM_QUANTITY,
    payload: body,
  };
};

export const updateCartItemQuantitySuccess = (body) => {
  return {
    type: types.UPDATE_CART_ITEM_QUANTITY_SUCCESS,
    payload: body,
  };
};

export const updateCartItemQuantityError = (body) => {
  return {
    type: types.UPDATE_CART_ITEM_QUANTITY_ERROR,
    payload: body,
  };
};

export const getLatestOrder = (body) => {
  return {
    type: types.GET_LATEST_ORDER,
    payload: body,
  };
};

export const getLatestOrderSuccess = (body) => {
  return {
    type: types.GET_LATEST_ORDER_SUCCESS,
    payload: body,
  };
};

export const getLatestOrderError = (body) => {
  return {
    type: types.GET_LATEST_ORDER_ERROR,
    payload: body,
  };
};

export const getAllCustomerOrders = (body) => {
  return {
    type: types.GET_ALL_CUSTOMER_ORDERS,
    payload: body,
  };
};

export const getAllCustomerOrdersSuccess = (body) => {
  return {
    type: types.GET_ALL_CUSTOMER_ORDERS_SUCCESS,
    payload: body,
  };
};

export const getAllCustomerOrdersError = (body) => {
  return {
    type: types.GET_ALL_CUSTOMER_ORDERS_ERROR,
    payload: body,
  };
};

export const clearCart = (body) => {
  return {
    type: types.CLEAR_CART,
    payload: body,
  };
};

export const calculateShipment = (body) => {
  return {
    type: types.CALCULATE_SHIPMENT,
    payload: body,
  };
};

export const calculateShipmentSuccess = (body) => {
  return {
    type: types.CALCULATE_SHIPMENT_SUCCESS,
    payload: body,
  };
};

export const calculateShipmentError = (body) => {
  return {
    type: types.CALCULATE_SHIPMENT_ERROR,
    payload: body,
  };
};

export const discardCart = (body, action) => {
  return {
    type: types.DISCARD_CART,
    payload: body,
    action: action,
  };
};

export const discardCartSuccess = (body) => {
  return {
    type: types.DISCARD_CART_SUCCESS,
    payload: body,
  };
};

export const discardCartError = (body) => {
  return {
    type: types.DISCARD_CART_ERROR,
    payload: body,
  };
};

export const verifyCoupon = (body, action) => {
  return {
    type: types.VERIFY_COUPON,
    payload: body,
    action: action,
  };
};

export const verifyCouponSuccess = (body) => {
  return {
    type: types.VERIFY_COUPON_SUCCESS,
    payload: body,
  };
};

export const verifyCouponError = (body) => {
  return {
    type: types.VERIFY_COUPON_ERROR,
    payload: body,
  };
};

export const registerCustomer = (body, action) => {
  return {
    type: types.REGISTER_CUSTOMER,
    payload: body,
    action,
  };
};

export const registerCustomerSuccess = (body) => {
  return {
    type: types.REGISTER_CUSTOMER_SUCCESS,
    payload: body,
  };
};

export const registerCustomerError = (body) => {
  return {
    type: types.REGISTER_CUSTOMER_ERROR,
    payload: body,
  };
};

export const checkPincode = (body) => {
  return {
    type: types.CHECK_PINCODE,
    payload: body,
  };
};

export const checkPincodeSuccess = (body) => {
  return {
    type: types.CHECK_PINCODE_SUCCESS,
    payload: body,
  };
};

export const checkPincodeError = (body) => {
  return {
    type: types.CHECK_PINCODE_ERROR,
    payload: body,
  };
};

export const trackOrder = (body) => {
  return {
    type: types.TRACK_ORDER,
    payload: body,
  };
};

export const trackOrderSuccess = (body) => {
  return {
    type: types.TRACK_ORDER_SUCCESS,
    payload: body,
  };
};

export const trackOrderError = (body) => {
  return {
    type: types.TRACK_ORDER_ERROR,
    payload: body,
  };
};
