import { all, call, put, select, takeLatest } from "redux-saga/effects";
import Api from "../../middlewares/Api";
import {
  ADD_ADDRESSES,
  ADD_TO_CART,
  CALCULATE_SHIPMENT,
  CHECK_PINCODE,
  CREATE_CART,
  DISCARD_CART,
  GET_ALL_CUSTOMER_ORDERS,
  GET_ALL_ORDERS,
  GET_CART_SUMMARY,
  GET_LATEST_ORDER,
  GET_MY_ADDRESSES,
  GET_ORDER_STATUS,
  GET_REFERENCE_ORDER_STATUS,
  ORDER_PICKED,
  ORDER_READY_FOR_PICKUP,
  PAYMENT_DONE,
  PLACE_ORDER,
  PROCEED_TO_PAYMENT,
  REGISTER_CUSTOMER,
  REMOVE_CART_PRODUCT,
  SUBMIT_ORDER_RESPONSE,
  TRACK_ORDER,
  UPDATE_CART_ITEM_QUANTITY,
  VERIFY_COUPON,
} from "../../utils/ApiList";
import {
  addAddressesError,
  addAddressesSuccess,
  addToCartError,
  addToCartSuccess,
  calculateShipmentError,
  calculateShipmentSuccess,
  checkPincodeError,
  checkPincodeSuccess,
  clearCart,
  createCart as createCartAction,
  createCartError,
  createCartSuccess,
  discardCartError,
  discardCartSuccess,
  getAllCustomerOrdersError,
  getAllCustomerOrdersSuccess,
  getAllOrdersError,
  getAllOrdersSuccess,
  getCartSummaryError,
  getCartSummarySuccess,
  getLatestOrderError,
  getLatestOrderSuccess,
  getMyAddressesError,
  getMyAddressesSuccess,
  getOrderStatusError,
  getOrderStatusSuccess,
  getReferenceOrderStatusError,
  getReferenceOrderStatusSuccess,
  orderPickedError,
  orderPickedSuccess,
  orderReadyForPickupError,
  orderReadyForPickupSuccess,
  paymentDone as paymentDoneAction,
  paymentDoneError,
  paymentDoneSuccess,
  placeOrder as placeOrderAction,
  placeOrderError,
  placeOrderSuccess,
  proceedToPaymentError,
  proceedToPaymentSuccess,
  registerCustomerError,
  registerCustomerSuccess,
  removeCartProductError,
  removeCartProductSuccess,
  submitOrderResponseError,
  submitOrderResponseSuccess,
  trackOrderError,
  trackOrderSuccess,
  updateCartItemQuantityError,
  updateCartItemQuantitySuccess,
  verifyCouponError,
  verifyCouponSuccess,
} from "./orderActions";
import orderActionTypes from "./orderActionTypes";
import authActionTypes from "../auth/authActionTypes";
import { getUserBasicInfo } from "../rootSelector";
import CryptoJS from "crypto-js";
import store from "../../store";
import Swal from "sweetalert2";
import { getDateInDDMMYYYY } from "../../utils/Dates";
import { pushToDataLayer } from "../../App";

const getAllOrdersApiCall = async (page, payload) => {
  const response = await Api.post(
    `${GET_ALL_ORDERS}?page=${page?.no ?? 1}&size=${page?.size ?? 50}`,
    payload
  );
  return response.data;
};

const submitOrderResponseApiCall = async (payload) => {
  const response = await Api.post(
    `${SUBMIT_ORDER_RESPONSE}?packing_time=1`,
    payload
  );
  return response.data;
};

const getOrderStatusApiCall = async (orderId, payload) => {
  const response = await Api.post(
    `${GET_ORDER_STATUS}?order_id=${orderId}`,
    payload
  );
  return response.data;
};

const getReferenceOrderStatusApiCall = async () => {
  const response = await Api.get(`${GET_REFERENCE_ORDER_STATUS}`);
  return response.data;
};

const orderReadyForPickupApiCall = async (orderId, payload) => {
  const response = await Api.post(
    `${ORDER_READY_FOR_PICKUP}?order_id=${orderId}`,
    payload
  );
  return response.data;
};

const orderPickedApiCall = async (orderId, payload) => {
  const response = await Api.post(
    `${ORDER_PICKED}?order_id=${orderId}`,
    payload
  );
  return response.data;
};

const createCartApiCall = async (payload) => {
  const response = await Api.post(
    `/customer/create_cart_personalized/`,
    payload
  );
  return response.data;
};

const addToCartApiCall = async (payload) => {
  const response = await Api.post(`${ADD_TO_CART}`, payload);
  return response.data;
};

const getCartSummaryApiCall = async (orderId, payload) => {
  const response = await Api.post(
    `${GET_CART_SUMMARY}?order_id=${orderId}`,
    payload
  );
  return response.data;
};

const removeCartProductApiCall = async (payload) => {
  const response = await Api.post(`${REMOVE_CART_PRODUCT}`, payload);
  return response.data;
};

const getMyAddressesApiCall = async (payload) => {
  const response = await Api.post(`${GET_MY_ADDRESSES}`, payload);
  return response.data;
};

const addAddressesApiCall = async (payload) => {
  const response = await Api.post(`${ADD_ADDRESSES}`, payload);
  return response.data;
};

const placeOrderApiCall = async (payload, data) => {
  const response = await Api.post(`${PLACE_ORDER}?dl=false`, {
    data: data,
    order_id: payload.orderId,
    address_delivery: payload.addressDelivery,
    coupon_code: payload?.couponCode ?? "",
    coupon_value: payload?.couponValue ?? -1,
    delivery_charge: payload?.deliveryCharge ?? -1,
  });
  return response.data;
};

const proceedToPaymentApiCall = async (payload, data) => {
  const response = await Api.post(
    `${PROCEED_TO_PAYMENT}?order_id=${payload?.orderId}&purchase_value=${payload?.purchaseValue}`,
    data
  );
  return response.data;
};

const paymentDoneApiCall = async (payload, data) => {
  const response = await Api.post(
    `${PAYMENT_DONE}?order_id=${payload.orderId}&payment_method=${payload.paymentMethod}`,
    data
  );
  return response.data;
};

const updateCartItemQuantityApiCall = async (payload) => {
  const response = await Api.post(`${UPDATE_CART_ITEM_QUANTITY}`, payload);
  return response.data;
};

const getLatestOrderApiCall = async (payload) => {
  const response = await Api.post(`${GET_LATEST_ORDER}`, payload);
  return response.data;
};

const getAllCustomerOrdersApiCall = async (payload) => {
  const response = await Api.post(`${GET_ALL_CUSTOMER_ORDERS}`, payload);
  return response.data;
};

const calculateShipmentApiCall = async (orderId, payload) => {
  const response = await Api.post(
    `${CALCULATE_SHIPMENT}?order_id=${orderId}`,
    payload
  );
  return response.data;
};

const discardCartApiCall = async (orderId, payload) => {
  const response = await Api.post(
    `${DISCARD_CART}?order_id=${orderId}`,
    payload
  );
  return response.data;
};

const verifyCouponApiCall = async (couponCode, payload) => {
  const response = await Api.post(
    `${VERIFY_COUPON}?coupon_code=${couponCode}&dl=false`,
    payload
  );
  return response.data;
};

const registerCustomerApiCall = async (payload) => {
  const response = await Api.post(`${REGISTER_CUSTOMER}`, payload);
  return response.data;
};

const checkPincodeApiCall = async (payload) => {
  const response = await Api.post(
    `${CHECK_PINCODE}?zip1=${payload?.pincode}`,
    payload?.data
  );
  return response.data;
};

const trackOrderApiCall = async (payload) => {
  const response = await Api.post(`${TRACK_ORDER}`, payload?.data);
  return response.data;
};

export function* getAllOrders({ payload }) {
  try {
    const ownerDetails = yield select(
      (state) => state?.onBoarding?.ownerDetails?.data
    );
    const user = yield select((state) => state?.auth?.currentUser?.user);
    const data = ownerDetails ?? user;
    const orders = yield getAllOrdersApiCall(payload?.page, {
      ...payload?.filter,
      data: data,
    });
    yield put(getAllOrdersSuccess(orders));
  } catch (error) {
    yield put(getAllOrdersError(error));
  }
}

export function* submitOrderResponse({ payload, action }) {
  try {
    const data = yield select(getUserBasicInfo);

    const orders = yield submitOrderResponseApiCall({
      ...payload,
      data,
    });
    yield put(submitOrderResponseSuccess(orders));
    if (action) {
      action.navigate("Dashboard", { params: { tab: "orderMessages" } });
    }
  } catch (error) {
    yield put(submitOrderResponseError(error));
  }
}

export function* getOrderStatus({ payload }) {
  try {
    const user = yield select(getUserBasicInfo);

    const orders = yield getOrderStatusApiCall(payload, user);
    yield put(getOrderStatusSuccess(orders));
  } catch (error) {
    yield put(getOrderStatusError(error));
  }
}

export function* getReferenceOrderStatus() {
  try {
    const statuses = yield getReferenceOrderStatusApiCall();
    yield put(getReferenceOrderStatusSuccess(statuses));
  } catch (error) {
    yield put(getReferenceOrderStatusError(error));
  }
}

export function* orderReadyForPickup({ payload }) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const orders = yield orderReadyForPickupApiCall(payload, basicInfo);
    yield put(orderReadyForPickupSuccess(orders));
  } catch (error) {
    yield put(orderReadyForPickupError(error));
  }
}

export function* orderPicked({ payload }) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const orders = yield orderPickedApiCall(payload, basicInfo);
    yield put(orderPickedSuccess(orders));
  } catch (error) {
    yield put(orderPickedError(error));
  }
}

export function* createCart({ payload, action }) {
  try {
    const basicInfo = yield select(getUserBasicInfo);

    let {
      reqPayload,
      totalAmountToPay,
      isCOD,
      deliveryCharge,
      couponCode,
      couponValue,
      address,
      phone,
    } = payload || {};
    const cart = yield createCartApiCall({
      cus: {
        ...basicInfo,
        id: basicInfo?.id ?? "",
        district: basicInfo?.district ?? "",
        LoB: payload?.owner?.LoB ? payload?.owner?.LoB : basicInfo?.LoB ?? "",
      },
      ...reqPayload,
    });

    const mobileNumber = yield select(
      (state) => state?.auth?.currentUser?.mobile_number
    );

    yield put(
      createCartSuccess(
        {
          ...cart,
          totalAmountToPay,
          isCOD,
          deliveryCharge,
          couponCode,
          couponValue,
          address,
          phone: phone ? phone : mobileNumber,
        },
        action
      )
    );
    // if (action) {
    //   action.navigate("Bag");
    // }
    return cart;
  } catch (error) {
    yield put(createCartError(error));
    Swal.fire({
      title: "Error",
      text: "Cart Creation Failed",
      icon: "error",
      showConfirmButton: true,
    });
  }
}

export function* addToCart({ payload, action }) {
  try {
    const basicInfo = yield select(getUserBasicInfo);

    const cart = yield addToCartApiCall({
      cus: {
        ...basicInfo,
        id: payload?.req?.ownerId ? payload.req?.ownerId : basicInfo?.id ?? "",
        district: basicInfo?.district ?? "",
        LoB: payload?.req?.lob ? payload?.req?.lob : basicInfo?.LoB ?? "",
      },
      ...payload?.actual,
    });
    yield put(addToCartSuccess(cart));
    if (action) {
      action.navigate("Bag");
    }
  } catch (error) {
    yield put(addToCartError(error));
  }
}

export function* getCartSummary({ payload }) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const latestOrder = yield select((state) => state.order?.latestOrder?.data);
    if (
      (latestOrder?.[0]?.order_id && latestOrder?.[0]?.order_status === "0") ||
      payload?.order_id
    ) {
      const cartSummary = yield getCartSummaryApiCall(
        payload?.order_id ? payload?.order_id : latestOrder?.[0]?.order_id,
        {
          ...basicInfo,
          id: basicInfo?.id ?? "",
          district: basicInfo?.district ?? "Gautam Buddha Nagar",
          LoB: basicInfo?.LoB ?? "Fashion",
        }
      );

      yield put(getCartSummarySuccess(cartSummary));
    }
  } catch (error) {
    yield put(getCartSummaryError(error));
  }
}

export function* removeCartProduct({ payload }) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const cart = yield removeCartProductApiCall({
      cus: basicInfo,
      ...payload,
    });
    yield put(removeCartProductSuccess(cart));
  } catch (error) {
    yield put(removeCartProductError(error));
  }
}

export function* getMyAddresses() {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const cartSummary = yield getMyAddressesApiCall(basicInfo);
    yield put(getMyAddressesSuccess(cartSummary));
  } catch (error) {
    yield put(getMyAddressesError(error));
  }
}

export function* addAddresses({ payload }) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const addresses = yield addAddressesApiCall({
      data: basicInfo,
      addr: payload,
    });
    yield put(addAddressesSuccess(addresses));
  } catch (error) {
    yield put(addAddressesError(error));
  }
}

export function* placeOrder({ payload, action }) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const order = yield placeOrderApiCall(payload, {
      ...basicInfo,
      id: basicInfo?.id ?? "",
      district: basicInfo?.district ?? "Gautam Buddha Nagar",
      LoB: basicInfo?.LoB ?? "Gifting & Stationary",
    });
    yield put(placeOrderSuccess(order));
    yield put({
      type: "products/clearCart",
    });

    Swal.fire({
      title: "Success!",
      text: "Successfully Order Placed",
      icon: "success",
      showConfirmButton: false,
      timer: 2500,
    });
    setTimeout(() => {
      action.push("/order-success");
    }, 1000);
    // if (action) {
    //   action.navigate("CustomerHomeScreen");
    // }
  } catch (error) {
    yield put(placeOrderError(error));
  }
}

export function* clearCartSaga() {
  yield put(clearCart());
}

export function* proceedToPayment({ payload, action }) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const requestedPayload = yield select(
      (state) => state?.order?.userDetailsPayload
    );

    if (!payload?.isCOD && payload?.totalAmountToPay) {
      const order = yield proceedToPaymentApiCall(
        {
          orderId: payload?.order_id,
          purchaseValue: (
            (payload?.totalAmountToPay ? payload?.totalAmountToPay : 10) * 100
          )?.toFixed(2),
        },
        basicInfo
      );

      var options = {
        description: "Placing Order",
        image:
          "https://test-catalog-bucket1.s3.ap-south-1.amazonaws.com/web/dblogonew.png",
        currency: "INR",
        key: order?.mid, // Your api key
        order_id: order?.order_id,
        amount: (
          (payload?.totalAmountToPay ? payload?.totalAmountToPay : 0) * 100
        )?.toFixed(2),
        name: `#${payload?.orderId}`,
        prefill: {
          email: "void@razorpay.com",
          contact: payload?.customer_phone,
          name: "",
        },
        modal: { backdropclose: true, animation: true },
        theme: { color: "#113" },
      };

      const userOrderPhone = requestedPayload?.reqPayload?.phone;
      pushToDataLayer("add_payment_info", {
        orderId: payload?.order_id,
        addressDelivery: payload?.address,
        deliveryCharge: payload?.deliveryCharge,
        couponCode: payload?.couponCode,
        couponValue: payload?.couponValue,
        amount: payload?.totalAmountToPay * 100,
      });
      const rzp = new window.Razorpay({
        key: order?.mid ? order?.mid : "rzp_test_qWQGSKnYsYNwYP",
        key_id: order?.mid ? order?.mid : "rzp_test_qWQGSKnYsYNwYP",
        amount: payload?.totalAmountToPay * 100, // Amount in paise
        currency: "INR",
        name: "Quick Gifting",
        description: "Test Transaction",
        image:
          "https://test-catalog-bucket1.s3.ap-south-1.amazonaws.com/web/quickgifting_web.png",
        handler: function (response) {
          pushToDataLayer("purchase", {
            order_id: payload?.order_id,
            order_value: payload?.totalAmountToPay,
            transaction_id: response?.razorpay_payment_id,
            value: payload?.totalAmountToPay,
            currency: "INR",
          });
          store.dispatch(
            placeOrderAction(
              {
                orderId: payload?.order_id,
                addressDelivery: payload?.address,
                deliveryCharge: payload?.deliveryCharge,
                couponCode: payload?.couponCode,
                couponValue: payload?.couponValue,
              },
              action
            )
          );
          store.dispatch(
            paymentDoneAction({
              orderId: payload?.order_id,
              paymentMethod: response?.razorpay_payment_id,
            })
          );
          store.dispatch(
            proceedToPaymentSuccess({ order_id: payload?.order_id })
          );
        },
        order_id: order?.order_id,
        prefill: {
          // name: "Gaurav Kumar",
          // email: "gaurav.kumar@example.com",
          contact: payload?.phone ? payload?.phone : "9205546825",
        },
        readonly: { contact: true },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#F37254",
        },
      });
      rzp.open();
    } else {
      yield paymentDoneApiCall(
        { orderId: payload?.order_id, paymentMethod: "COD" },
        basicInfo
      );
      yield put(
        placeOrderAction(
          {
            orderId: payload?.order_id,
            addressDelivery: requestedPayload?.address ?? "",
          },
          action
        )
      );
    }

    // const data = yield RazorpayCheckout.open(options);
    // yield put(
    //   paymentDoneAction({
    //     orderId: payload?.orderId,
    //     paymentMethod: data?.razorpay_payment_id,
    //   }),
    // );
    // yield paymentDoneApiCall(
    //   {
    //     orderId: payload?.orderId,
    //     paymentMethod: data?.razorpay_payment_id,
    //   },
    //   basicInfo
    // );

    // if (action) {
    //   action.navigate("CustomerHomeScreen");
    // }
    // Toast.show({ type: "success", text1: "Order placed successfully" });
  } catch (error) {
    yield put(proceedToPaymentError(error));
  }
}

export function* paymentDone({ payload }) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const order = yield paymentDoneApiCall(payload, basicInfo);
    yield put(paymentDoneSuccess(order));
  } catch (error) {
    yield put(paymentDoneError(error));
  }
}

export function* updateCartItemQuantity({ payload }) {
  try {
    const basicInfo = yield select(getUserBasicInfo);

    const item = yield updateCartItemQuantityApiCall({
      basic_info: {
        ...basicInfo,
        id: basicInfo?.id ?? "",
        district: basicInfo?.district ?? "Gautam Buddha Nagar",
        LoB: basicInfo?.LoB ?? "Fashion",
      },
      ...payload,
    });
    yield put(updateCartItemQuantitySuccess(item));
  } catch (error) {
    yield put(updateCartItemQuantityError(error));
  }
}

export function* getLatestOrder({ payload }) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const item = yield getLatestOrderApiCall({
      ...basicInfo,
      id: basicInfo?.id ?? "",
      district: basicInfo?.district ?? "Gautam Buddha Nagar",
      LoB: basicInfo?.LoB ?? "Fashion",
    });
    yield put(getLatestOrderSuccess(item));
  } catch (error) {
    yield put(getLatestOrderError(error));
  }
}

export function* getAllCustomerOrders() {
  try {
    const data = yield select(getUserBasicInfo);
    const orders = yield getAllCustomerOrdersApiCall(data);
    yield put(getAllCustomerOrdersSuccess(orders));
  } catch (error) {
    yield put(getAllCustomerOrdersError(error));
  }
}

export function* calculateShipment({ payload }) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const order = yield calculateShipmentApiCall(payload?.orderId, {
      data: basicInfo,
      ...payload?.payload,
    });
    yield put(calculateShipmentSuccess(order));
  } catch (error) {
    yield put(calculateShipmentError(error));
  }
}

export function* discardCart({ payload }) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const cart = yield discardCartApiCall(payload, basicInfo);
    yield put(discardCartSuccess(cart));
  } catch (error) {
    yield put(discardCartError(error));
  }
}

export function* verifyCoupon({ payload }) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const couponDetails = yield verifyCouponApiCall(payload, basicInfo);
    yield put(verifyCouponSuccess(couponDetails));
  } catch (error) {
    yield put(verifyCouponError(error));
  }
}

export function* registerCustomer({ payload, action }) {
  try {
    let {
      reqPayload,
      totalAmountToPay,
      isCOD,
      deliveryCharge,
      couponCode,
      couponValue,
      address,
    } = payload || {};
    const customerDetails = yield registerCustomerApiCall(reqPayload);

    customerDetails.user = customerDetails.user?.slice(
      2,
      customerDetails.user?.length - 1
    );

    customerDetails.db_verify[1] = customerDetails.db_verify[1]?.slice(
      2,
      customerDetails.db_verify[1]?.length - 1
    );
    customerDetails.db_verify[0] = customerDetails.db_verify[0]?.slice(
      2,
      customerDetails.db_verify[0]?.length - 1
    );
    customerDetails.salt = customerDetails.salt?.slice(
      2,
      customerDetails.salt?.length - 1
    );

    var Base64CBC = customerDetails.user;

    var iv = CryptoJS.enc.Utf8.parse(customerDetails.db_verify[1]);
    var key = customerDetails.db_verify[0]; //key used in Python
    key = CryptoJS.enc.Utf8.parse(key);
    var decrypted = CryptoJS.AES.decrypt(Base64CBC, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
    });
    decrypted = decrypted.toString(CryptoJS.enc.Utf8);
    customerDetails.user = JSON.parse(decrypted);

    yield put(
      registerCustomerSuccess({
        ...customerDetails,
        user: {
          id: customerDetails?.user?.id,
          district: "Gautam Buddha Nagar",
          LoB: "Gifting & Stationary",
          lat: 0,
          lon: 0,
          dp: "",
        },
      })
    );

    const carts = yield select((state) => state?.products?.carts);
    const d = yield put(
      createCartAction(
        {
          reqPayload: {
            cus: {
              id: customerDetails?.user?.id,
              district: "Gautam Buddha Nagar",
              LoB: "Gifting & Stationary",
              lat: 0,
              lon: 0,
              dp: "",
            },
            owner: {
              id: carts?.[0]?.owner_id + "",
              district: "Gautam Buddha Nagar",
              LoB: customerDetails?.user?.LoB
                ? customerDetails?.user?.LoB
                : "Gifting & Stationary",
              lat: 28.608585,
              lon: 0.427029,
              dp: "",
              biz_type: [],
              store_id: 0,
              is_premium: true,
              biz_name: carts?.[0]?.biz_name,
            },
            cart_item: carts?.map((p) => {
              return {
                color: "",
                size: "",
                item_count: 1,
                mrp: p.mrp + "",
                discount_price: p.discount_price + "",
                product_subcategory: p.sub_category ?? p.catalog_name,
                item_id: p?.item_id,
                unit: p.unit ?? "",
                unit_count: p?.unit_count ? p?.unit_count + "" : "0",
                brand_name: p?.brand_name ?? "",
                product_name: p?.product_name ? p?.product_name : "",
                per_image: p?.per_image ? p?.per_image : undefined,
                per_name: p?.per_name ? p?.per_name : undefined,
                delivery_type:
                  p?.delivery_type === "Sameday"
                    ? getDateInDDMMYYYY(0)
                    : p?.delivery_type === "Nextday"
                    ? getDateInDDMMYYYY(1)
                    : p?.delivery_type === "ECom"
                    ? getDateInDDMMYYYY(5)
                    : "",
              };
            }),
          },
          totalAmountToPay,
          isCOD,
          deliveryCharge,
          couponCode,
          couponValue,
          address,
          phone: reqPayload?.phone,
        },
        action
      )
    );
  } catch (error) {
    yield put(registerCustomerError(error));
  }
}

export function* checkPincode({ payload }) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const item = yield checkPincodeApiCall({
      pincode: payload?.pincode,
      data: {
        basic: basicInfo?.id
          ? {
              ...basicInfo,
              id: basicInfo?.id ?? "",
              district: basicInfo?.district ?? "Gautam Buddha Nagar",
              LoB: basicInfo?.LoB ?? "Gifting & Stationary",
            }
          : {
              id: "10101",
              district: "Gautam Buddha Nagar",
              LoB: "Gifting & Stationary",
              lat: 28.608585,
              lon: 77.427029,
              dp: "aaa",
            },
        sku: payload?.sku ? payload?.sku : [],
      },
    });
    yield put(checkPincodeSuccess(item));
  } catch (error) {
    yield put(checkPincodeError(error));
  }
}

export function* trackOrder({ payload }) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const item = yield trackOrderApiCall({
      pincode: payload?.pincode,
      data: {
        basic: basicInfo?.id
          ? {
              ...basicInfo,
              id: basicInfo?.id ?? "",
              district: basicInfo?.district ?? "Gautam Buddha Nagar",
              LoB: basicInfo?.LoB ?? "Gifting & Stationary",
            }
          : {
              id: "10101",
              district: "Gautam Buddha Nagar",
              LoB: "Gifting & Stationary",
              lat: 28.608585,
              lon: 77.427029,
              dp: "aaa",
            },
        phone: payload?.phone ? payload?.phone : "",
        order_id: payload?.order_id ? payload?.order_id : "",
      },
    });
    if (item?.length < 1) {
      Swal.fire({
        title: "Error",
        text: "Please provide correct details",
        icon: "error",
        showConfirmButton: true,
      });
    }
    yield put(trackOrderSuccess(item));
  } catch (error) {
    yield put(trackOrderError(error));
  }
}

export function* onGetAllOrders() {
  yield takeLatest(
    [
      orderActionTypes.GET_ALL_ORDERS,
      orderActionTypes.SUBMIT_ORDER_RESPONSE_SUCCESS,
      orderActionTypes.ORDER_READY_FOR_PICKUP_SUCCESS,
      orderActionTypes.ORDER_PICKED_SUCCESS,
    ],
    getAllOrders
  );
}

export function* onClearCart() {
  yield takeLatest([orderActionTypes.PLACE_ORDER_SUCCESS], clearCartSaga);
}

export function* onSubmitOrderResonse() {
  yield takeLatest(orderActionTypes.SUBMIT_ORDER_RESPONSE, submitOrderResponse);
}

export function* onGetOrderStatus() {
  yield takeLatest(orderActionTypes.GET_ORDER_STATUS, getOrderStatus);
}

export function* onGetReferenceOrderStatus() {
  yield takeLatest(
    orderActionTypes.GET_REFERENCE_ORDER_STATUS,
    getReferenceOrderStatus
  );
}

export function* onOrderReadyForPickup() {
  yield takeLatest(
    orderActionTypes.ORDER_READY_FOR_PICKUP,
    orderReadyForPickup
  );
}

export function* onOrderPicked() {
  yield takeLatest(orderActionTypes.ORDER_PICKED, orderPicked);
}

export function* onCreateCart() {
  yield takeLatest(orderActionTypes.CREATE_CART, createCart);
}

export function* onAddToCart() {
  yield takeLatest(orderActionTypes.ADD_TO_CART, addToCart);
}

export function* onGetCartSummary() {
  yield takeLatest(
    [
      orderActionTypes.GET_CART_SUMMARY,
      orderActionTypes.UPDATE_CART_ITEM_QUANTITY_SUCCESS,
      orderActionTypes.ADD_TO_CART_SUCCESS,
      orderActionTypes.REMOVE_CART_PRODUCT_SUCCESS,
    ],
    getCartSummary
  );
}

export function* onRemoveCartProduct() {
  yield takeLatest(orderActionTypes.REMOVE_CART_PRODUCT, removeCartProduct);
}

export function* onGetMyAddresses() {
  yield takeLatest(
    [orderActionTypes.GET_MY_ADDRESSES, orderActionTypes.ADD_ADDRESSES_SUCCESS],
    getMyAddresses
  );
}

export function* onAddAddresses() {
  yield takeLatest(orderActionTypes.ADD_ADDRESSES, addAddresses);
}

export function* onPlaceOrder() {
  yield takeLatest(orderActionTypes.PLACE_ORDER, placeOrder);
}

export function* onProceedToPayment() {
  yield takeLatest(
    [orderActionTypes.PROCEED_TO_PAYMENT, orderActionTypes.CREATE_CART_SUCCESS],
    proceedToPayment
  );
}

export function* onPaymentDone() {
  yield takeLatest(orderActionTypes.PAYMENT_DONE, paymentDone);
}

export function* onUpdateCartItemQuantity() {
  yield takeLatest(
    orderActionTypes.UPDATE_CART_ITEM_QUANTITY,
    updateCartItemQuantity
  );
}

export function* onGetLatestOrder() {
  yield takeLatest(
    [
      orderActionTypes.GET_LATEST_ORDER,
      authActionTypes.CUSTOMER_GENERAL_VALIDATE_OTP_SUCCESS,
      orderActionTypes.ADD_TO_CART_SUCCESS,
      orderActionTypes.PLACE_ORDER_SUCCESS,
    ],
    getLatestOrder
  );
}

export function* onGetAllCustomerOrders() {
  yield takeLatest(
    [
      orderActionTypes.GET_ALL_CUSTOMER_ORDERS,
      orderActionTypes.PAYMENT_DONE_SUCCESS,
      orderActionTypes.PLACE_ORDER_SUCCESS,
    ],
    getAllCustomerOrders
  );
}

export function* onCalculateShipment() {
  yield takeLatest(orderActionTypes.CALCULATE_SHIPMENT, calculateShipment);
}

export function* onDiscardCart() {
  yield takeLatest(orderActionTypes.DISCARD_CART, discardCart);
}

export function* onVerifyCoupon() {
  yield takeLatest(orderActionTypes.VERIFY_COUPON, verifyCoupon);
}

export function* onRegisterCustomer() {
  yield takeLatest(orderActionTypes.REGISTER_CUSTOMER, registerCustomer);
}

export function* onCheckPincode() {
  yield takeLatest(orderActionTypes.CHECK_PINCODE, checkPincode);
}

export function* onTrackOrder() {
  yield takeLatest(orderActionTypes.TRACK_ORDER, trackOrder);
}

export function* orderSagas() {
  yield all([
    call(onGetAllOrders),
    call(onSubmitOrderResonse),
    call(onGetOrderStatus),
    call(onGetReferenceOrderStatus),
    call(onOrderReadyForPickup),
    call(onOrderPicked),
    call(onCreateCart),
    call(onAddToCart),
    call(onGetCartSummary),
    call(onRemoveCartProduct),
    call(onGetMyAddresses),
    call(onAddAddresses),
    call(onPlaceOrder),
    call(onProceedToPayment),
    call(onPaymentDone),
    call(onUpdateCartItemQuantity),
    call(onGetLatestOrder),
    call(onGetAllCustomerOrders),
    call(onClearCart),
    call(onCalculateShipment),
    call(onDiscardCart),
    call(onVerifyCoupon),
    call(onRegisterCustomer),
    call(onCheckPincode),
    call(onTrackOrder),
  ]);
}
