import { all, call, put, select, takeLatest } from "redux-saga/effects";

import {
  getStoreProfileSuccess,
  getStoreProfileError,
  getAllStoreCatalogsSuccess,
  getAllStoreCatalogsError,
  getOwnMarketSuccess,
  getOwnMarketError,
  addQuickProductsSuccess,
  addQuickProductsError,
} from "./storeActions";
import {
  ADD_QUICK_PRODUCTS,
  GET_ALL_STORE_CATALOGS,
  GET_OWN_MARKET,
  GET_STORE_PROFILE,
} from "../../utils/ApiList";
import Api from "../../middlewares/Api";
import storeActionTypes from "./storeActionTypes";
import followActionTypes from "../follow/followActionTypes";
import { getUserBasicInfo } from "../rootSelector";
// import Toast from 'react-native-toast-message';

const getStoreProfileApiCall = async (ownerId, payload) => {
  const response = await Api.post(
    `${GET_STORE_PROFILE}?owner_id=${ownerId}`,
    payload
  );
  return response.data;
};

const getAllStoreCatalogsApiCall = async (ownerId, payload) => {
  const response = await Api.post(
    `${GET_ALL_STORE_CATALOGS}?owner_id=${ownerId}&page=1&size=50`,
    payload
  );
  return response.data;
};

const getOwnMarketApiCall = async (payload) => {
  const response = await Api.post(`${GET_OWN_MARKET}`, payload);
  return response.data;
};

const addQuickProductsApiCall = async (payload) => {
  const response = await Api.post(`${ADD_QUICK_PRODUCTS}`, payload);
  return response.data;
};

export function* getStoreProfile({ payload }) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    let ownerId = payload;
    let lob = basicInfo?.LoB;
    if (typeof payload === "object") {
      ownerId = payload?.ownerId;
      lob = payload?.lob;
    }
    const response = yield getStoreProfileApiCall(ownerId, {
      ...basicInfo,
      id: basicInfo?.id ?? "",
      district: basicInfo?.district ?? "",
      LoB: lob ?? "",
    });
    yield put(getStoreProfileSuccess(response));
  } catch (error) {
    yield put(getStoreProfileError(error));
  }
}

export function* getAllStoreCatalogs({ payload }) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const response = yield getAllStoreCatalogsApiCall(payload?.ownerId, {
      ...basicInfo,
      id: basicInfo?.id ?? "",
      district: basicInfo?.district ?? "Gautam Buddha Nagar",
      LoB: payload?.lob ? payload?.lob : basicInfo?.LoB ?? "Fashion",
    });
    yield put(getAllStoreCatalogsSuccess(response));
  } catch (error) {
    yield put(getAllStoreCatalogsError(error));
  }
}

export function* getOwnMarket() {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const response = yield getOwnMarketApiCall(basicInfo);
    yield put(getOwnMarketSuccess(response));
  } catch (error) {
    yield put(getOwnMarketError(error));
  }
}

export function* addQuickProducts({ payload, action }) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const response = yield addQuickProductsApiCall({
      data: basicInfo,
      items: payload,
    });
    if (response?.failed_count > 0) {
      // Toast.show({
      //   type: 'error',
      //   text1: `${response?.failed_count} products are unable to get added.`,
      // });
    } else if (response?.success_count > 0) {
      // Toast.show({
      //   type: 'success',
      //   text1: `Great, you have added ${response?.success_count} products.`,
      // });
    }
    yield put(addQuickProductsSuccess(response));
    if (action) {
      action.navigate("Dashboard", { params: { tab: "products" } });
    }
  } catch (error) {
    yield put(addQuickProductsError(error));
  }
}

export function* onGetStoreProfile() {
  yield takeLatest(
    [
      storeActionTypes.GET_STORE_PROFILE,
      followActionTypes.FOLLOW_STORE_SUCCESS,
    ],
    getStoreProfile
  );
}

export function* onGetAllStoreCatalogs() {
  yield takeLatest(
    storeActionTypes.GET_ALL_STORE_CATALOGS,
    getAllStoreCatalogs
  );
}

export function* onGetOwnMarket() {
  yield takeLatest(storeActionTypes.GET_OWN_MARKET, getOwnMarket);
}

export function* onAddQuickProducts() {
  yield takeLatest(storeActionTypes.ADD_QUICK_PRODUCTS, addQuickProducts);
}

export function* storeSagas() {
  yield all([
    call(onGetStoreProfile),
    call(onGetAllStoreCatalogs),
    call(onGetOwnMarket),
    call(onAddQuickProducts),
  ]);
}
