const onBoardingActionTypes = {
  APP_LOAD: "APP_LOAD",

  GET_ANALYSIS_TYPES: "GET_ANALYSIS_TYPES",
  GET_ANALYSIS_TYPES_SUCCESS: "GET_ANALYSIS_TYPES_SUCCESS",
  GET_ANALYSIS_TYPES_ERROR: "GET_ANALYSIS_TYPES_ERROR",

  SET_DASHBOARD_VISITED: "SET_DASHBOARD_VISITED",

  SET_ZIPCODE: "SET_ZIPCODE",
};

export default onBoardingActionTypes;
