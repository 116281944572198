import types from "./onBoardingActionTypes";

const INITIAL_STATE = {
  currentUser: null,
  onBoarding: null,
  error: null,
};

const onBoardingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_GOVT_ID_TYPES:
      return {
        ...state,
        govtIdTypes: { country: "India", isLoading: true },
      };
    case types.GET_GOVT_ID_TYPES_SUCCESS:
      return {
        ...state,
        govtIdTypes: {
          data: action.payload,
          isLoading: false,
        },
        error: null,
      };
    case types.GET_ALL_LOBS_SUCCESS:
      const allLobsData = action.payload?.lobs?.map((lob) => ({
        ...lob,
        categories: action.payload?.cat_subcat
          ?.filter((cat) => cat.lob === lob.lob)
          .map((cat) => ({
            category: cat.category,
            sub_categories: cat.sub_category.map((sc, index) => {
              return {
                sub_category: sc,
                subcat_url: cat.subcat_url?.[index],
                is_trending: cat?.trending?.[index] === "Yes",
              };
            }),
            cat_url: cat.cat_url,
            subcat_url: cat.subcat_url,
            trending: cat.trending,
          })),
      }));
      const banners = action.payload?.banners?.images;
      const shopnow = action.payload?.banners?.shopnow;
      return {
        ...state,
        lobs: {
          data: allLobsData,
        },
        banners: banners,
        shopnow: shopnow,
        highlights: action.payload?.highlights,
        faqs: action.payload?.faqs,
        error: null,
      };
    case types.SAVE_ALL_PRODUCT_LOBS:
      const groupedData = action?.payload?.cat_subcat?.reduce((acc, item) => {
        const {
          lob,
          category,
          sub_category,
          trending,
          cat_url,
          subcat_url,
          tag,
          cat_content,
        } = item;

        const categoryData = {
          category,
          cat_url,
          tag,
          cat_content,
          sub_categories: sub_category.map((subcat, index) => ({
            subcat,
            trending: trending[index] || "No",
            url: subcat_url[index],
          })),
        };

        const existingLob = acc.find((group) => group.lob === lob);

        if (existingLob) {
          existingLob.categories.push(categoryData);
        } else {
          acc.push({
            lob,
            categories: [categoryData],
          });
        }

        return acc;
      }, []);

      const allProductLobsData = action.payload?.lobs?.map((lob) => ({
        ...lob,
        categories: action.payload?.cat_subcat
          ?.filter((cat) => cat.lob === lob.lob)
          .map((cat) => ({
            category: cat.category,
            sub_categories: cat.sub_category,
            cat_url: cat.cat_url,
            subcat_url: cat.subcat_url,
          })),
      }));
      return {
        ...state,
        productLobs: {
          data: allProductLobsData,
          newData: groupedData,
        },
      };
    case types.SAVE_ALL_SERVICE_LOBS:
      const allServiceLobsData = action.payload?.lobs?.map((lob) => ({
        ...lob,
        categories: action.payload?.cat_subcat
          ?.filter((cat) => cat.lob === lob.lob)
          .map((cat) => ({
            category: cat.category,
            sub_categories: cat.sub_category,
            cat_url: cat.cat_url,
            subcat_url: cat.subcat_url,
          })),
      }));
      return {
        ...state,
        serviceLobs: {
          data: allServiceLobsData,
        },
      };
    case types.GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: {
          data: action.payload,
        },
        error: null,
      };
    case types.GET_SUB_CATEGORIES_SUCCESS:
      return {
        ...state,
        subCategories: {
          data: action.payload,
        },
        error: null,
      };
    case types.UPLOAD_GOVT_ID_DATA:
      return {
        ...state,
        ownerDetails: {
          ...state.ownerDetails,
          govtIdDetails: action.payload,
          isUploading: true,
          error: null,
        },
        error: null,
      };
    case types.UPLOAD_GOVT_ID_DATA_SUCCESS:
      return {
        ...state,
        ownerDetails: {
          ...state.ownerDetails,
          govtIdDetails: action.payload,
          isUploading: false,
          error: null,
        },
        error: null,
      };
    case types.OWNER_SIGNUP:
      return {
        ...state,
        ownerDetails: {
          isLoading: true,
          error: null,
        },
        error: null,
      };
    case types.OWNER_SIGNUP_SUCCESS:
      return {
        ...state,
        ownerDetails: {
          data: action.payload,
          isLoading: false,
          error: null,
        },
        error: null,
      };
    case types.SAVE_ONBOARDING_DETAILS:
      if (action.action) {
        action.action.navigate("OutletDetails");
      }
      return {
        ...state,
        ownerDetails: {
          ...state.ownerDetails,
          draftData: action.payload,
        },
        error: null,
      };
    case types.GET_BASE_SUBSCRIPTION:
      return {
        ...state,
        baseSubscriptionDetails: {
          isLoading: true,
          error: null,
        },
        error: null,
      };
    case types.GET_BASE_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        baseSubscriptionDetails: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
        error: null,
      };
    case types.UPDATE_DELIVERY_DETAILS:
      return {
        ...state,
        deliveryDetails: {
          isLoading: true,
          error: null,
        },
      };
    case types.UPDATE_DELIVERY_DETAILS_SUCCESS:
      return {
        ...state,
        deliveryDetails: {
          data: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case types.UPDATE_DELIVERY_DETAILS_ERROR:
      return {
        ...state,
        deliveryDetails: {
          ...state?.deliveryDetails,
          isLoading: false,
          error: action.payload,
        },
      };
    case types.UPDATE_STORE_DETAILS:
      return {
        ...state,
        storeDetails: {
          isLoading: true,
          error: null,
        },
      };
    case types.UPDATE_STORE_DETAILS_SUCCESS:
      return {
        ...state,
        storeDetails: {
          data: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case types.UPDATE_STORE_DETAILS_ERROR:
      return {
        ...state,
        storeDetails: {
          ...state?.storeDetails,
          isLoading: false,
          error: action.payload,
        },
      };
    case types.GET_DP_SUCCESS:
      return {
        ...state,
        ownerDp: action.payload,
      };
    case types.GET_STORE_LOBS:
      return {
        ...state,
        storeLobs: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_STORE_LOBS_SUCCESS:
      return {
        ...state,
        storeLobs: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.GET_STORE_LOBS_ERROR:
      return {
        ...state,
        storeLobs: {
          ...state.storeLobs,
          isLoading: false,
          error: action.payload,
        },
      };
    case types.UPDATE_APP_LOB:
      let data = state.ownerDetails?.data;
      data = data?.id ? { ...data, LoB: action.payload } : data;
      return {
        ...state,
        ownerDetails: {
          ...state.ownerDetails,
          data: data,
        },
      };
    case types.GET_STORE_DETAILS:
      return {
        ...state,
        storeDetails: {
          ...state?.storeDetails,
          isLoading: true,
          error: null,
        },
      };
    case types.GET_STORE_DETAILS_SUCCESS:
      return {
        ...state,
        storeDetails: {
          data: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case types.GET_BASE_SUBSCRIPTION_ERROR:
      return {
        ...state,
        storeDetails: {
          ...state?.storeDetails,
          isLoading: false,
          error: action.payload,
        },
      };
    case types.GET_DELIVERY_DETAILS:
      return {
        ...state,
        deliveryDetails: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_DELIVERY_DETAILS_SUCCESS:
      return {
        ...state,
        deliveryDetails: {
          data: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case types.GET_DELIVERY_DETAILS_ERROR:
      return {
        ...state,
        deliveryDetails: {
          ...state?.deliveryDetails,
          isLoading: false,
          error: action.payload,
        },
      };
    case types.GET_SUB_CATEGORIES_SEARCH:
      return {
        ...state,
        subCategoriesSearch: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_SUB_CATEGORIES_SEARCH_SUCCESS:
      return {
        ...state,
        subCategoriesSearch: {
          data: action.payload,
          isLoading: false,
          error: null,
        },
      };
    case types.GET_SUB_CATEGORIES_SEARCH_ERROR:
      return {
        ...state,
        subCategoriesSearch: {
          ...state.subCategoriesSearch,
          isLoading: false,
          error: action.payloa,
        },
      };
    case types.GET_STORE_BRANDS:
      return {
        ...state,
        storeBrands: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_STORE_BRANDS_SUCCESS:
      return {
        ...state,
        storeBrands: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.GET_STORE_BRANDS_ERROR:
      return {
        ...state,
        storeBrands: {
          ...state.storeBrands,
          isLoading: false,
          error: action.payload,
        },
      };
    case types.CLEAR_OWNER_DETAILS:
      return {
        ...state,
        ownerDetails: {
          data: undefined,
          isLoading: false,
          error: null,
        },
      };
    case types.GET_CUSTOMER_DETAILS:
      return {
        ...state,
        customerDetails: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_CUSTOMER_DETAILS_SUCCESS:
      return {
        ...state,
        customerDetails: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.GET_CUSTOMER_DETAILS_ERROR:
      return {
        ...state,
        customerDetails: {
          ...state.storeBrands,
          isLoading: false,
          error: action.payload,
        },
      };
    case types.GET_CUSTOMER_HOME_PRODUCTS:
      return {
        ...state,
        homeProducts: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_CUSTOMER_HOME_PRODUCTS_SUCCESS:
      return {
        ...state,
        homeProducts: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.GET_CUSTOMER_HOME_PRODUCTS_ERROR:
      return {
        ...state,
        homeProducts: {
          ...state?.homeProducts?.data,
          isLoading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default onBoardingReducer;
