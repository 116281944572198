import types from './notifyActionTypes';

export const socialShare = payload => {
  return {
    type: types.SOCIAL_SHARE,
    payload: payload,
  };
};

export const socialShareSuccess = data => {
  return {
    type: types.SOCIAL_SHARE_SUCCESS,
    payload: data,
  };
};

export const socialShareError = data => {
  return {
    type: types.SOCIAL_SHARE_ERROR,
    payload: data,
  };
};

export const blockCustomer = payload => {
  return {
    type: types.BLOCK_CUSTOMER,
    payload: payload,
  };
};

export const blockCustomerSuccess = data => {
  return {
    type: types.BLOCK_CUSTOMER_SUCCESS,
    payload: data,
  };
};

export const blockCustomerError = data => {
  return {
    type: types.BLOCK_CUSTOMER_ERROR,
    payload: data,
  };
};

export const blockBO = payload => {
  return {
    type: types.BLOCK_BO,
    payload: payload,
  };
};

export const blockBOSuccess = data => {
  return {
    type: types.BLOCK_BO_SUCCESS,
    payload: data,
  };
};

export const blockBOError = data => {
  return {
    type: types.BLOCK_BO_ERROR,
    payload: data,
  };
};

export const checkIfBlocked = payload => {
  return {
    type: types.CHECK_IF_BLOCKED,
    payload: payload,
  };
};

export const checkIfBlockedSuccess = data => {
  return {
    type: types.CHECK_IF_BLOCKED_SUCCESS,
    payload: data,
  };
};

export const checkIfBlockedError = data => {
  return {
    type: types.CHECK_IF_BLOCKED_ERROR,
    payload: data,
  };
};
