import types from "./appActionTypes";

export const loadApp = () => {
  return {
    type: types.APP_LOAD,
  };
};

export const getAnalysisTypes = () => {
  return {
    type: types.GET_ANALYSIS_TYPES,
  };
};

export const getAnalysisTypesSuccess = (body) => {
  return {
    type: types.GET_ANALYSIS_TYPES_SUCCESS,
    payload: body,
  };
};

export const getAnalysisTypesError = (body) => {
  return {
    type: types.GET_ANALYSIS_TYPES_ERROR,
    payload: body,
  };
};

export const setDashboardVisited = () => {
  return {
    type: types.SET_DASHBOARD_VISITED,
  };
};

export const setZipcode = (body) => {
  return {
    type: types.SET_ZIPCODE,
    payload: body,
  };
};
