import types from "./catalogActionTypes";

const INITIAL_STATE = {
  storeCategories: {},
  error: null,
};

const catalogReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_MY_CATALOGS:
      return {
        ...state,
        myCatalogs: {
          isLoading: true,
          error: null,
        },
        error: null,
      };
    case types.GET_MY_CATALOGS_SUCCESS:
      return {
        ...state,
        myCatalogs: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
        error: null,
      };
    case types.GET_MY_CATALOGS_ERROR:
      return {
        ...state,
        myCatalogs: {
          ...state?.myCatalogs,
          isLoading: false,
          error: action.payload,
        },
      };
    case types.DELETE_CATALOG_PRODUCT_SUCCESS:
      return {
        ...state,
        catalogDeletion: {
          isLoading: false,
          error: null,
        },
        myCatalogs: {
          ...state.myCatalogs,
          data: removeCatalogProduct(state.myCatalogs.data, action.payload),
        },
      };
    case types.CREATE_CATALOG:
    case types.ADD_CATALOG_PRODUCT:
    case types.UPDATE_CATALOG_PRODUCT:
      return {
        ...state,
        catalogCreation: {
          isLoading: true,
          error: null,
        },
      };
    case types.CREATE_CATALOG_SUCCESS:
    case types.ADD_CATALOG_PRODUCT_SUCCESS:
    case types.UPDATE_CATALOG_PRODUCT_SUCCESS:
      return {
        ...state,
        catalogCreation: {
          isLoading: false,
          error: null,
        },
      };
    case types.CREATE_CATALOG_ERROR:
    case types.ADD_CATALOG_PRODUCT_ERROR:
    case types.UPDATE_CATALOG_PRODUCT_ERROR:
      return {
        ...state,
        catalogCreation: {
          isLoading: false,
          error: action.payload,
        },
      };
    case types.DELETE_CATALOG_PRODUCT:
      return {
        ...state,
        catalogDeletion: {
          isLoading: true,
          error: null,
        },
      };
    case types.DELETE_CATALOG_PRODUCT_SUCCESS:
      return {
        ...state,
        catalogDeletion: {
          isLoading: false,
          error: null,
        },
      };
    case types.DELETE_CATALOG_PRODUCT_ERROR:
      return {
        ...state,
        catalogDeletion: {
          isLoading: false,
          error: action.payload,
        },
      };
    case types.SEARCH_CATALOGS:
      return {
        ...state,
        customer: {
          ...state.customer,
          catalogs: {
            ...state?.customer?.catalogs,
            isLoading: true,
            error: null,
            loading: true,
            currentPage: {
              ...state.currentPage,
              [action?.payload?.pagination?.itemName]:
                action?.payload?.pagination?.page,
            },
            currentItemName: action?.payload?.pagination?.currentItemName,
            data:
              action?.payload?.pagination?.page > 1
                ? state?.customer?.catalogs?.data
                : undefined,
          },
        },
      };
    case types.SET_SEARCH_STRING:
      return {
        ...state,
        customer: {
          ...state.customer,
          searchString: action.payload,
        },
      };
    case types.SEARCH_CATALOGS_SUCCESS:
      let data = [];
      let itemName = action.payload?.pagination?.itemName;
      let page = action.payload?.pagination?.page;
      let items = action.payload?.data;
      let renew = action.payload?.pagination?.renew;
      let isLastSet =
        items?.length % action.payload?.pagination?.size > 0 ||
        items?.length === 0;
      if (action.payload?.pagination?.page > 1) {
        data = [
          ...(state?.customer?.catalogs?.data ?? []),
          ...action.payload?.data,
        ];
      } else {
        data = action.payload?.data ?? [];
      }

      return {
        ...state,
        customer: {
          ...state.customer,
          catalogs: {
            ...state?.customer?.catalogs,
            isLoading: false,
            error: null,
            data: data,
            loading: false,
            lastPage: {
              ...state?.customer?.catalogs?.lastPage,
              [itemName]: isLastSet,
            },
            items: renew
              ? {
                  [itemName]: items,
                }
              : {
                  ...state?.customer?.catalogs?.items,
                  [itemName]:
                    page === 1
                      ? items
                      : [
                          ...(state?.customer?.catalogs?.items?.[itemName] ||
                            []),
                          ...items,
                        ],
                },
            currentPage: {
              ...state?.customer?.catalogs?.currentPage,
              [itemName]: page,
            },
          },
        },
      };
    case types.SEARCH_CATALOGS_ERROR:
      return {
        ...state,
        customer: {
          ...state.customer,
          catalogs: {
            ...state?.customer?.catalogs,
            loading: false,
            isLoading: false,
            error: action.payload,
          },
        },
      };
    case types.GET_NEARBY_STORES:
      return {
        ...state,
        customer: {
          ...state.customer,
          stores: {
            isLoading: true,
            error: null,
          },
        },
      };
    case types.GET_NEARBY_STORES_SUCCESS:
      return {
        ...state,
        customer: {
          ...state.customer,
          stores: {
            isLoading: false,
            data: action.payload,
            error: null,
          },
        },
      };
    case types.GET_NEARBY_STORES_ERROR:
      return {
        ...state,
        customer: {
          ...state.customer,
          stores: {
            isLoading: false,
            error: action.payload,
          },
        },
      };
    case types.GET_CUSTOMER_CATALOG_PRODUCTS:
      return {
        ...state,
        customer: {
          ...state.customer,
          products: {
            isLoading: true,
            error: null,
          },
        },
      };
    case types.GET_CUSTOMER_CATALOG_PRODUCTS_SUCCESS:
      return {
        ...state,
        customer: {
          ...state.customer,
          products: {
            isLoading: false,
            data: action.payload,
            error: null,
          },
        },
      };
    case types.GET_CUSTOMER_CATALOG_PRODUCTS_ERROR:
      return {
        ...state,
        customer: {
          ...state.customer,
          products: {
            isLoading: false,
            error: action.payload,
          },
        },
      };
    case types.GET_CUSTOMER_CATALOG_PRODUCT:
      return {
        ...state,
        customer: {
          ...state.customer,
          currentProduct: {
            isLoading: true,
            error: null,
          },
        },
      };
    case types.GET_CUSTOMER_CATALOG_PRODUCT_SUCCESS:
      return {
        ...state,
        customer: {
          ...state.customer,
          currentProduct: {
            isLoading: false,
            data: action.payload,
            error: null,
          },
        },
      };
    case types.GET_CUSTOMER_CATALOG_PRODUCT_ERROR:
      return {
        ...state,
        customer: {
          ...state.customer,
          currentProduct: {
            isLoading: false,
            error: action.payload,
          },
        },
      };
    default:
      return state;
  }
};

export const removeCatalogProduct = (data, { catalogId, productId }) => {
  const catalog = data?.find((c) => c.catalog_id === catalogId);
  if (catalog && catalog.products) {
    catalog.products = catalog.products.filter((p) => p.item_id !== productId);
  }
  return data;
};

export default catalogReducer;
