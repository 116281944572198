import types from "./authActionTypes";

const INITIAL_STATE = {
  currentUser: null,
  error: null,
};

const authReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.SELECT_ROLE:
      return {
        ...state,
        role: action.payload,
      };
    case types.GET_OTP:
    case types.GET_CUSTOMER_OTP:
    case types.GENERAL_VALIDATE_OTP:
    case types.CUSTOMER_GENERAL_VALIDATE_OTP:
      return {
        ...state,
        currentUser: { ...state.currentUser, ...action.payload },
        otp: { isLoading: true },
        error: null,
      };
    case types.GET_OTP_SUCCESS:
    case types.GET_CUSTOMER_OTP_SUCCESS:
      return {
        ...state,
        currentUser: { ...state.currentUser, ...action.payload },
        otp: { isLoading: false },
        error: null,
      };
    case types.GET_OTP_ERROR:
    case types.GET_CUSTOMER_OTP_ERROR:
      return {
        ...state,
        otp: { isLoading: false },
      };
    case types.VALIDATE_OTP_SUCCESS:
    case types.VALIDATE_CUSTOMER_OTP_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload,
          otpVerified: true,
        },
        error: null,
      };
    case types.GENERAL_VALIDATE_OTP_SUCCESS:
    case types.CUSTOMER_GENERAL_VALIDATE_OTP_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload,
          ...(state?.role === "Customer"
            ? action.payload?.customer
            : action.payload?.owner),
          otpVerified: true,
        },
        error: null,
      };

    case types.SET_OWNER_BASIC_INFO:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          owner: { ...state.currentUser.owner, user: action.payload },
          user: action.payload,
        },
        error: null,
      };
    case types.CHANGE_ROLE:
      const user =
        action.payload === "Customer"
          ? state.currentUser?.customer
          : state.currentUser?.owner;
      return {
        ...state,
        role: action.payload,
        currentUser: {
          ...state.currentUser,
          ...user,
          id: user?.user?.id,
          otpVerified:
            (!!user?.id && user?.id !== "null") ||
            (!!user?.user?.id && user?.user?.id !== "null"),
        },
        error: null,
      };
    case types.LOG_IN_SUCCESS:
      return {
        ...state,
        currentUser: action.payload,
        error: null,
      };
    case types.LOG_IN_FAILURE:
    case types.REGISTER_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    case types.LOG_OUT:
      return INITIAL_STATE;
    case types.SKIP_FOR_NOW:
      return {
        ...state,
        skipForNow: true,
      };
    case types.SELECT_DISTRICT:
      return {
        ...state,
        selectedDistrict: action.payload,
        currentUser: {
          ...state.currentUser,
          user: {
            ...state.currentUser.user,
            district: action.payload,
          },
        },
      };
    case types.CHANGE_LOB:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          user: {
            ...state.currentUser.user,
            LoB: action.payload,
          },
        },
      };
    case types.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload,
        },
      };
    case types.SOCIAL_SIGNUP_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...action.payload,
        },
      };
    case types.SIGNUP_BO_FROM_CUSTOMER_SUCCESS:
      return {
        ...state,
        role: "Business Owner",
        currentUser: {
          ...state.currentUser,
          ...action.payload,
          id: action?.payload?.user?.id,
          owner: action.payload,
        },
      };
    case types.SIGNUP_CUSTOMER_FROM_BO_SUCCESS:
      return {
        ...state,
        role: "Customer",
        currentUser: {
          ...state.currentUser,
          ...action.payload,
          id: action?.payload?.user?.id,
          customer: action.payload,
        },
      };
    default:
      return state;
  }
};

export default authReducer;
