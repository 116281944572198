const subscriptionActionTypes = {
  GET_SUBSCRIPTION_PLANS: 'GET_SUBSCRIPTION_PLANS',
  GET_SUBSCRIPTION_PLANS_SUCCESS: 'GET_SUBSCRIPTION_PLANS_SUCCESS',
  GET_SUBSCRIPTION_PLANS_ERROR: 'GET_SUBSCRIPTION_PLANS_ERROR',

  GET_REMAINING_SUBSCRIPTIONS: 'GET_REMAINING_SUBSCRIPTIONS',
  GET_REMAINING_SUBSCRIPTIONS_SUCCESS: 'GET_REMAINING_SUBSCRIPTIONS_SUCCESS',
  GET_REMAINING_SUBSCRIPTIONS_ERROR: 'GET_REMAINING_SUBSCRIPTIONS_ERROR',
};

export default subscriptionActionTypes;
