import types from './masterActionTypes';

export const getSubCategorySizes = payload => {
  return {
    type: types.GET_SUB_CATEGORY_SIZES,
    payload: payload,
  };
};

export const getSubCategorySizesSuccess = data => {
  return {
    type: types.GET_SUB_CATEGORY_SIZES_SUCCESS,
    payload: data,
  };
};

export const getSubCategorySizesError = data => {
  return {
    type: types.GET_SUB_CATEGORY_SIZES_ERROR,
    payload: data,
  };
};

export const getAllOperationalDistricts = payload => {
  return {
    type: types.GET_ALL_OPERATIONAL_DISTRICTS,
    payload: payload,
  };
};

export const getAllOperationalDistrictsSuccess = data => {
  return {
    type: types.GET_ALL_OPERATIONAL_DISTRICTS_SUCCESS,
    payload: data,
  };
};

export const getAllOperationalDistrictsError = data => {
  return {
    type: types.GET_ALL_OPERATIONAL_DISTRICTS_ERROR,
    payload: data,
  };
};

export const getProductMetadata = payload => {
  return {
    type: types.GET_PRODUCT_METADATA,
    payload: payload,
  };
};

export const getProductMetadataSuccess = data => {
  return {
    type: types.GET_PRODUCT_METADATA_SUCCESS,
    payload: data,
  };
};

export const getProductMetadataError = data => {
  return {
    type: types.GET_PRODUCT_METADATA_ERROR,
    payload: data,
  };
};

export const getBannerMetadata = payload => {
  return {
    type: types.GET_BANNER_METADATA,
    payload: payload,
  };
};

export const getBannerMetadataSuccess = data => {
  return {
    type: types.GET_BANNER_METADATA_SUCCESS,
    payload: data,
  };
};

export const getBannerMetadataError = data => {
  return {
    type: types.GET_BANNER_METADATA_ERROR,
    payload: data,
  };
};

export const getStoreMetadata = payload => {
  return {
    type: types.GET_STORE_METADATA,
    payload: payload,
  };
};

export const getStoreMetadataSuccess = data => {
  return {
    type: types.GET_STORE_METADATA_SUCCESS,
    payload: data,
  };
};

export const getStoreMetadataError = data => {
  return {
    type: types.GET_STORE_METADATA_ERROR,
    payload: data,
  };
};
