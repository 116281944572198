import { all, call, put, select, takeLatest } from "redux-saga/effects";

import {
  addCatalogProductError,
  addCatalogProductSuccess,
  createCatalogError,
  createCatalogSuccess,
  deleteCatalogError,
  deleteCatalogProductError,
  deleteCatalogProductSuccess,
  deleteCatalogSuccess,
  disableCatalogError,
  disableCatalogSuccess,
  editCatalogSubCategoryError,
  editCatalogSubCategorySuccess,
  enableCatalogError,
  enableCatalogSuccess,
  getCustomerCatalogProductError,
  getCustomerCatalogProductSuccess,
  getCustomerCatalogProductsError,
  getCustomerCatalogProductsSuccess,
  getMyCatalogs as getMyCatalogsAction,
  getMyCatalogsError,
  getMyCatalogsSuccess,
  getNearByStoresError,
  getNearByStoresSuccess,
  searchCatalogsError,
  searchCatalogsSuccess,
  updateCatalogProductError,
  updateCatalogProductSuccess,
} from "./catalogActions";
import {
  ADD_CATALOG_PRODUCT,
  CREATE_CATALOG,
  DELETE_CATALOG,
  DELETE_CATALOG_PRODUCT,
  EDIT_CATALOG_SUBCATEGORY,
  ENABLE_DISABLE_CATALOG,
  GET_CATALOG_PRODUCTS,
  GET_CUSTOMER_CATALOG_PRODUCT,
  GET_CUSTOMER_CATALOG_PRODUCTS,
  GET_MY_CATALOGS,
  GET_NEARBY_STORES,
  SEARCH_CATALOG,
  UPDATE_CATALOG_PRODUCT_AVAILABILITY,
  UPDATE_CATALOG_PRODUCT_DETAILS,
} from "../../utils/ApiList";
import Api from "../../middlewares/Api";
import catalogActionTypes from "./catalogActionTypes";
import bannerActionTypes from "../banner/bannerActionTypes";
import { getUserBasicInfo } from "../rootSelector";
import onBoardingActionTypes from "../onBoarding/onBoardingActionTypes";
import userActionTypes from "../auth/authActionTypes";

const createCatalogApiCall = async (payload) => {
  const response = await Api.post(CREATE_CATALOG, payload);
  return response.data;
};

const addCatalogProductApiCall = async (payload) => {
  const response = await Api.post(ADD_CATALOG_PRODUCT, payload);
  return response.data;
};

const getMyCatalogsApiCall = async (payload) => {
  const { body, page, size } = payload;
  const response = await Api.post(
    `${GET_MY_CATALOGS}?page=${page ?? 1}&size=${size ?? 50}`,
    body
  );
  return response.data;
};

const getMyCatalogProductsApiCall = async (payload) => {
  const { body, catalogId } = payload;
  const response = await Api.post(
    `${GET_CATALOG_PRODUCTS}?catalog_id=${catalogId}&page=1&size=50`,
    body
  );
  return response.data;
};

const updateCatalogProductApiCall = async (payload) => {
  const response = await Api.post(UPDATE_CATALOG_PRODUCT_DETAILS, payload);
  return response.data;
};

const updateCatalogProductAvailabiltyApiCall = async ({ data, payload }) => {
  const response = await Api.post(
    `${UPDATE_CATALOG_PRODUCT_AVAILABILITY}?product_id=${payload.product_id}&catalog_id=${payload.catalog_id}&availability=${payload.availability}`,
    data
  );
  return response.data;
};

const enableCatalogApiCall = async (catalogId, data) => {
  const response = await Api.post(
    `${ENABLE_DISABLE_CATALOG}?catalog_id=${catalogId}&enable=true`,
    data
  );
  return response.data;
};

const editCatalogSubCategoryApiCall = async (catalogId, data) => {
  const response = await Api.post(
    `${EDIT_CATALOG_SUBCATEGORY}?catalog_id=${catalogId}`,
    data
  );
  return response.data;
};

const disableCatalogApiCall = async (catalogId, data) => {
  const response = await Api.post(
    `${ENABLE_DISABLE_CATALOG}?catalog_id=${catalogId}&enable=false`,
    data
  );
  return response.data;
};

const deleteCatalogProductApiCall = async ({ catalogId, productId }, data) => {
  const response = await Api.post(
    `${DELETE_CATALOG_PRODUCT}?catalog_id=${catalogId}&product_id=${productId}`,
    data
  );
  return response.data;
};

const deleteCatalogApiCall = async (catalogId, data) => {
  const response = await Api.post(
    `${DELETE_CATALOG}?catalog_id=${catalogId}`,
    data
  );
  return response.data;
};

const searchCatalogsApiCall = async (
  payload,
  owner_id,
  pagination = { page: 1, size: 16, top_products: true }
) => {
  const response = await Api.post(
    `${SEARCH_CATALOG}?page=${pagination?.page ?? 1}&size=${
      pagination?.size ?? 50
    }${owner_id ? `&owner_id=${owner_id}` : ""}${
      pagination?.top_products !== undefined
        ? `&top_products=${pagination?.top_products}`
        : ""
    }`,
    payload
  );
  return response.data;
};

const getNearByStoresApiCall = async (payload) => {
  const response = await Api.post(
    `${GET_NEARBY_STORES}?page=1&size=10`,
    payload
  );
  return response.data;
};

const getCustomerCatalogProductsApiCall = async (body) => {
  const response = await Api.post(`${GET_CUSTOMER_CATALOG_PRODUCTS}`, body);
  return response.data;
};

const getCustomerCatalogProductApiCall = async (body) => {
  const response = await Api.post(`${GET_CUSTOMER_CATALOG_PRODUCT}`, body);
  return response.data;
};

export function* createCatalog({
  payload: { catalog, catalogProduct },
  action,
}) {
  try {
    const basic_info = yield select(getUserBasicInfo);
    const response = yield createCatalogApiCall({
      basic_info,
      ...catalog,
    });
    yield addCatalogProductApiCall({
      basic_info,
      ...catalogProduct,
      catalog_id: response,
    });
    yield put(
      getMyCatalogsAction({
        body: catalogProduct.basic_info,
        page: 1,
        size: 50,
      })
    );
    yield put(createCatalogSuccess());
    if (action) {
      action.navigate("Dashboard", { params: { tab: "catalogs" } });
    }
  } catch (error) {
    yield put(createCatalogError(error));
  }
}

export function* addCatalogProduct({ payload, action }) {
  try {
    const basic_info = yield select(getUserBasicInfo);
    payload = typeof payload === "object" ? payload : {};
    yield addCatalogProductApiCall({
      basic_info,
      ...payload,
    });
    yield put(addCatalogProductSuccess());
    if (action) {
      action.navigate("Dashboard", { params: { tab: "catalogs" } });
    }
    yield put(
      getMyCatalogsAction({ body: payload.basic_info, page: 1, size: 50 })
    );
  } catch (error) {
    yield put(addCatalogProductError(error));
  }
}

export function* getMyCatalogs({ payload }) {
  try {
    let basic_info = yield select(getUserBasicInfo);

    if (payload?.LoB && payload?.LoB !== "null") {
      basic_info = payload;
      payload = {};
    }

    const response = yield getMyCatalogsApiCall({
      body: {
        data: basic_info,
        sub_category: [],
        availability: "",
        active: "",
        sort_by: {
          product_count: "",
          views: "",
          create_time: "",
        },
        ...payload,
      },
      page: 1,
      size: 50,
    });

    for (let catalog of response) {
      let products = yield getMyCatalogProductsApiCall({
        body: basic_info,
        catalogId: catalog.catalog_id,
      });
      catalog.products = products;
    }
    yield put(getMyCatalogsSuccess(response));
  } catch (error) {
    yield put(getMyCatalogsError(error));
  }
}

export function* updateCatalogProduct({ payload, action }) {
  try {
    const basic_info = yield select(getUserBasicInfo);
    payload = typeof payload === "object" ? payload : {};
    yield updateCatalogProductApiCall({
      ...payload,
      basic_info,
    });
    yield put(updateCatalogProductSuccess());
    if (action) {
      action.navigate("Dashboard", { params: { tab: "catalogs" } });
    }
    yield put(
      getMyCatalogsAction({ body: payload.basic_info, page: 1, size: 50 })
    );
  } catch (error) {
    yield put(updateCatalogProductError(error));
  }
}

export function* updateCatalogProductAvailability({ payload }) {
  try {
    const basic_info = yield select(getUserBasicInfo);
    yield updateCatalogProductAvailabiltyApiCall({
      payload: payload?.payload,
      data: basic_info,
    });

    yield put(getMyCatalogsAction({ body: payload?.data, page: 1, size: 50 }));
  } catch (error) {
    console.log("Error", error);
  }
}

export function* enableCatalog({ payload }) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    yield enableCatalogApiCall(payload, basicInfo);
    yield put(enableCatalogSuccess());
  } catch (error) {
    yield put(enableCatalogError(error));
  }
}

export function* editCatalogSubCategory({ payload, action }) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    yield editCatalogSubCategoryApiCall(payload?.catalogId, {
      basic_info: basicInfo,
      catalog_desc: "string",
      availability: true,
      active: true,
      sub_category: payload?.subCategory,
    });
    yield put(editCatalogSubCategorySuccess());
    if (action) {
      action.navigate("Dashboard", { params: { tab: "catalogs" } });
    }
  } catch (error) {
    yield put(editCatalogSubCategoryError(error));
  }
}

export function* disableCatalog({ payload }) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    yield disableCatalogApiCall(payload, basicInfo);
    yield put(disableCatalogSuccess());
  } catch (error) {
    yield put(disableCatalogError(error));
  }
}

export function* deleteCatalogProduct({ payload, action }) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    yield deleteCatalogProductApiCall(payload, basicInfo);
    yield put(deleteCatalogProductSuccess(payload));
    if (action) {
      action.navigate("Dashboard", { params: { tab: "catalogs" } });
    }
  } catch (error) {
    yield put(deleteCatalogProductError(error));
  }
}

export function* deleteCatalog({ payload }) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    yield deleteCatalogApiCall(payload, basicInfo);
    yield put(deleteCatalogSuccess());
  } catch (error) {
    yield put(deleteCatalogError(error));
  }
}

export function* onCreateCatalog() {
  yield takeLatest(catalogActionTypes.CREATE_CATALOG, createCatalog);
}

export function* onAddCatalogProduct() {
  yield takeLatest(catalogActionTypes.ADD_CATALOG_PRODUCT, addCatalogProduct);
}

export function* onGetMyCatalogs() {
  yield takeLatest(
    [
      catalogActionTypes.GET_MY_CATALOGS,
      catalogActionTypes.ENABLE_CATALOG_SUCCESS,
      catalogActionTypes.DISABLE_CATALOG_SUCCESS,
      bannerActionTypes.SAVE_DRAFT_BANNER,
      catalogActionTypes.DELETE_CATALOG_SUCCESS,
      // onBoardingActionTypes.UPDATE_APP_LOB,
      userActionTypes.SET_OWNER_BASIC_INFO,
      catalogActionTypes.EDIT_CATALOG_SUBCATEGORY_SUCCESS,
      catalogActionTypes.DELETE_CATALOG_PRODUCT_SUCCESS,
    ],
    getMyCatalogs
  );
}

export function* onUpdateCatalogProduct() {
  yield takeLatest(
    catalogActionTypes.UPDATE_CATALOG_PRODUCT,
    updateCatalogProduct
  );
}

export function* searchCatalogs({ payload }) {
  try {
    const mainPayload = payload?.payload;
    const ownerId = payload?.ownerId;
    const basicInfo = yield select(getUserBasicInfo);
    if (basicInfo?.id) {
      mainPayload.basic_info = basicInfo;
    }
    const owner_id = ownerId ? ownerId : "-1";
    const catalogs = yield searchCatalogsApiCall(
      mainPayload,
      owner_id,
      payload?.pagination
    );

    yield put(
      searchCatalogsSuccess({
        data: catalogs,
        pagination: {
          ...payload?.pagination,
          itemName: mainPayload?.item_name,
          isLastItem:
            payload?.pagination?.isLastItem &&
            catalogs?.length < payload?.pagination?.size,
        },
      })
    );
  } catch (error) {
    yield put(searchCatalogsError(error));
  }
}

export function* getNearByStores({ payload }) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const mainPayload =
      typeof payload?.payload === "object" ? payload?.payload : {};
    const stores = yield getNearByStoresApiCall({
      data: {
        ...basicInfo,
        id: basicInfo?.id ?? "",
        district: basicInfo?.district ?? "",
        LoB: payload?.lob ? payload?.lob : basicInfo?.LoB ?? "",
      },
      biz_mode: "",
      biz_name: "",
      biz_type: "",
      brand: "",
      category: mainPayload?.item_name ?? "",
      distance: -1,
      geog_name: "",
      geog_type: "",
      offer_type: "",
      rating: -1,
      sort: { discount_sort: "", distance_sort: "", likes_sort: "" },
      ...mainPayload,
    });
    yield put(getNearByStoresSuccess(stores));
  } catch (error) {
    yield put(getNearByStoresError(error));
  }
}

export function* getCustomerCatalogProducts({ payload }) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const response = yield getCustomerCatalogProductsApiCall({
      catalog_id: payload.catalogId,
      owner_id: payload.ownerId,
      data: {
        ...basicInfo,
        id: basicInfo?.id ?? "",
        district: basicInfo?.district ?? "",
        LoB: payload?.lob ? payload?.lob : basicInfo?.LoB ?? "",
      },
    });
    yield put(getCustomerCatalogProductsSuccess(response));
  } catch (error) {
    yield put(getCustomerCatalogProductsError(error));
  }
}

export function* getCustomerCatalogProduct({ payload }) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const response = yield getCustomerCatalogProductApiCall({
      catalog_id: payload.catalogId,
      owner_id: payload.ownerId,
      item_id: payload.itemId,
      data: basicInfo?.id
        ? {
            ...basicInfo,
            id: basicInfo?.id ?? "",
            district: basicInfo?.district ?? "",
            LoB: payload?.lob ? payload?.lob : basicInfo?.LoB ?? "",
          }
        : {
            id: "10101",
            district: "Gautam Buddha Nagar",
            LoB: "Gifting & Stationary",
            lat: 28.608585,
            lon: 77.427029,
            dp: "aaa",
          },
    });
    yield put(getCustomerCatalogProductSuccess(response));
  } catch (error) {
    yield put(getCustomerCatalogProductError(error));
  }
}

export function* onUpdateCatalogProductAvailability() {
  yield takeLatest(
    catalogActionTypes.UPDATE_CATALOG_PRODUCT_AVAILABILITY,
    updateCatalogProductAvailability
  );
}

export function* onEnableCatalog() {
  yield takeLatest(catalogActionTypes.ENABLE_CATALOG, enableCatalog);
}

export function* onEditCatalogSubCategory() {
  yield takeLatest(
    catalogActionTypes.EDIT_CATALOG_SUBCATEGORY,
    editCatalogSubCategory
  );
}

export function* onDisableCatalog() {
  yield takeLatest(catalogActionTypes.DISABLE_CATALOG, disableCatalog);
}

export function* onDeleteCatalogProduct() {
  yield takeLatest(
    catalogActionTypes.DELETE_CATALOG_PRODUCT,
    deleteCatalogProduct
  );
}

export function* onDeleteCatalog() {
  yield takeLatest(catalogActionTypes.DELETE_CATALOG, deleteCatalog);
}

export function* onSearchCatalogs() {
  yield takeLatest(catalogActionTypes.SEARCH_CATALOGS, searchCatalogs);
}

export function* onGetNearByStores() {
  yield takeLatest(
    [catalogActionTypes.GET_NEARBY_STORES, userActionTypes.CHANGE_LOB],
    getNearByStores
  );
}

export function* onGetCatalogProducts() {
  yield takeLatest(
    catalogActionTypes.GET_CUSTOMER_CATALOG_PRODUCTS,
    getCustomerCatalogProducts
  );
}

export function* onGetCatalogProduct() {
  yield takeLatest(
    catalogActionTypes.GET_CUSTOMER_CATALOG_PRODUCT,
    getCustomerCatalogProduct
  );
}

export function* catalogSagas() {
  yield all([
    call(onCreateCatalog),
    call(onAddCatalogProduct),
    call(onGetMyCatalogs),
    call(onUpdateCatalogProduct),
    call(onUpdateCatalogProductAvailability),
    call(onEnableCatalog),
    call(onEditCatalogSubCategory),
    call(onDisableCatalog),
    call(onDeleteCatalogProduct),
    call(onDeleteCatalog),
    call(onSearchCatalogs),
    call(onGetNearByStores),
    call(onGetCatalogProducts),
    call(onGetCatalogProduct),
  ]);
}
