import types from './followActionTypes';

const INITIAL_STATE = {};

const followReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_STORE_COUPON_SUCCESS:
      return {
        ...state,
        storeCoupon: action.payload,
      };
    default:
      return state;
  }
};

export default followReducer;
