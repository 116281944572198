const productActionTypes = {
  GET_STORE_CATEGORIES: 'GET_STORE_CATEGORIES',
  GET_STORE_CATEGORIES_SUCCESS: 'GET_STORE_CATEGORIES_SUCCESS',
  GET_STORE_SUB_CATEGORIES: 'GET_STORE_SUB_CATEGORIES',
  GET_STORE_SUB_CATEGORIES_SUCCESS: 'GET_STORE_SUB_CATEGORIES_SUCCESS',
  GET_STORE_SUB_CATEGORIES_ERROR: 'GET_STORE_SUB_CATEGORIES_ERROR',
  SAVE_SELECTED_SUB_CATEGORY: 'SAVE_SELECTED_SUB_CATEGORY',
  ADD_GENERAL_PRODUCT: 'ADD_GENERAL_PRODUCT',
  ADD_GENERAL_PRODUCT_SUCCESS: 'ADD_GENERAL_PRODUCT_SUCCESS',
  ADD_GENERAL_PRODUCT_ERROR: 'ADD_GENERAL_PRODUCT_ERROR',
  GET_MY_GENERAL_PRODUCTS: 'GET_MY_GENERAL_PRODUCTS',
  GET_MY_GENERAL_PRODUCTS_SUCCESS: 'GET_MY_GENERAL_PRODUCTS_SUCCESS',
  UPDATE_GENERAL_PRODUCT: 'UPDATE_GENERAL_PRODUCT',
  UPDATE_GENERAL_PRODUCT_SUCCESS: 'UPDATE_GENERAL_PRODUCT_SUCCESS',
  UPDATE_GENERAL_PRODUCT_AVAILABILITY: 'UPDATE_GENERAL_PRODUCT_AVAILABILITY',
  UPDATE_GENERAL_PRODUCT_AVAILABILITY_SUCCESS:
    'UPDATE_GENERAL_PRODUCT_AVAILABILITY_SUCCESS',
  DELETE_GENERAL_PRODUCT: 'DELETE_GENERAL_PRODUCT',
  DELETE_GENERAL_PRODUCT_SUCCESS: 'DELETE_GENERAL_PRODUCT_SUCCESS',
  DELETE_GENERAL_PRODUCT_ERROR: 'DELETE_GENERAL_PRODUCT_ERROR',

  SEARCH_GENERAL_PRODUCTS: 'SEARCH_GENERAL_PRODUCTS',
  SEARCH_GENERAL_PRODUCTS_SUCCESS: 'SEARCH_GENERAL_PRODUCTS_SUCCESS',
  SEARCH_GENERAL_PRODUCTS_ERROR: 'SEARCH_GENERAL_PRODUCTS_ERROR',

  GET_QUICK_PRODUCTS: 'GET_QUICK_PRODUCTS',
  GET_QUICK_PRODUCTS_SUCCESS: 'GET_QUICK_PRODUCTS_SUCCESS',
  GET_QUICK_PRODUCTS_ERROR: 'GET_QUICK_PRODUCTS_ERROR',
};

export default productActionTypes;
