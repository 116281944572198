const orderActionTypes = {
  GET_ALL_ORDERS: "GET_ALL_ORDERS",
  GET_ALL_ORDERS_SUCCESS: "GET_ALL_ORDERS_SUCCESS",
  GET_ALL_ORDERS_ERROR: "GET_ALL_ORDERS_ERROR",

  GET_REFERENCE_ORDER_STATUS: "GET_REFERENCE_ORDER_STATUS",
  GET_REFERENCE_ORDER_STATUS_SUCCESS: "GET_REFERENCE_ORDER_STATUS_SUCCESS",
  GET_REFERENCE_ORDER_STATUS_ERROR: "GET_REFERENCE_ORDER_STATUS_ERROR",

  GET_ORDER_SUMMARY: "GET_ORDER_SUMMARY",
  GET_ORDER_SUMMARY_SUCCESS: "GET_ORDER_SUMMARY_SUCCESS",
  GET_ORDER_SUMMARY_ERROR: "GET_ORDER_SUMMARY_ERROR",

  SUBMIT_ORDER_RESPONSE: "SUBMIT_ORDER_RESPONSE",
  SUBMIT_ORDER_RESPONSE_SUCCESS: "SUBMIT_ORDER_RESPONSE_SUCCESS",
  SUBMIT_ORDER_RESPONSE_ERROR: "SUBMIT_ORDER_RESPONSE_ERROR",

  GET_ORDER_STATUS: "GET_ORDER_STATUS",
  GET_ORDER_STATUS_SUCCESS: "GET_ORDER_STATUS_SUCCESS",
  GET_ORDER_STATUS_ERROR: "GET_ORDER_STATUS_ERROR",

  ORDER_READY_FOR_PICKUP: "ORDER_READY_FOR_PICKUP",
  ORDER_READY_FOR_PICKUP_SUCCESS: "ORDER_READY_FOR_PICKUP_SUCCESS",
  ORDER_READY_FOR_PICKUP_ERROR: "ORDER_READY_FOR_PICKUP_ERROR",

  ORDER_PICKED: "ORDER_PICKED",
  ORDER_PICKED_SUCCESS: "ORDER_PICKED_SUCCESS",
  ORDER_PICKED_ERROR: "ORDER_PICKED_ERROR",

  CREATE_CART: "CREATE_CART",
  CREATE_CART_SUCCESS: "CREATE_CART_SUCCESS",
  CREATE_CART_ERROR: "CREATE_CART_ERROR",

  ADD_TO_CART: "ADD_TO_CART",
  ADD_TO_CART_SUCCESS: "ADD_TO_CART_SUCCESS",
  ADD_TO_CART_ERROR: "ADD_TO_CART_ERROR",

  GET_CART_SUMMARY: "GET_CART_SUMMARY",
  GET_CART_SUMMARY_SUCCESS: "GET_CART_SUMMARY_SUCCESS",
  GET_CART_SUMMARY_ERROR: "GET_CART_SUMMARY_ERROR",

  REMOVE_CART_PRODUCT: "REMOVE_CART_PRODUCT",
  REMOVE_CART_PRODUCT_SUCCESS: "REMOVE_CART_PRODUCT_SUCCESS",
  REMOVE_CART_PRODUCT_ERROR: "REMOVE_CART_PRODUCT_ERROR",

  GET_MY_ADDRESSES: "GET_MY_ADDRESSES",
  GET_MY_ADDRESSES_SUCCESS: "GET_MY_ADDRESSES_SUCCESS",
  GET_MY_ADDRESSES_ERROR: "GET_MY_ADDRESSES_ERROR",

  ADD_ADDRESSES: "ADD_ADDRESSES",
  ADD_ADDRESSES_SUCCESS: "ADD_ADDRESSES_SUCCESS",
  ADD_ADDRESSES_ERROR: "ADD_ADDRESSES_ERROR",

  PLACE_ORDER: "PLACE_ORDER",
  PLACE_ORDER_SUCCESS: "PLACE_ORDER_SUCCESS",
  PLACE_ORDER_ERROR: "PLACE_ORDER_ERROR",

  PROCEED_TO_PAYMENT: "PROCEED_TO_PAYMENT",
  PROCEED_TO_PAYMENT_SUCCESS: "PROCEED_TO_PAYMENT_SUCCESS",
  PROCEED_TO_PAYMENT_ERROR: "PROCEED_TO_PAYMENT_ERROR",

  PAYMENT_DONE: "PAYMENT_DONE",
  PAYMENT_DONE_SUCCESS: "PAYMENT_DONE_SUCCESS",
  PAYMENT_DONE_ERROR: "PAYMENT_DONE_ERROR",

  CALCULATE_SHIPMENT: "CALCULATE_SHIPMENT",
  CALCULATE_SHIPMENT_SUCCESS: "CALCULATE_SHIPMENT_SUCCESS",
  CALCULATE_SHIPMENT_ERROR: "CALCULATE_SHIPMENT_ERROR",

  UPDATE_CART_ITEM_QUANTITY: "UPDATE_CART_ITEM_QUANTITY",
  UPDATE_CART_ITEM_QUANTITY_SUCCESS: "UPDATE_CART_ITEM_QUANTITY_SUCCESS",
  UPDATE_CART_ITEM_QUANTITY_ERROR: "UPDATE_CART_ITEM_QUANTITY_ERROR",

  GET_LATEST_ORDER: "GET_LATEST_ORDER",
  GET_LATEST_ORDER_SUCCESS: "GET_LATEST_ORDER_SUCCESS",
  GET_LATEST_ORDER_ERROR: "GET_LATEST_ORDER_ERROR",

  GET_ALL_CUSTOMER_ORDERS: "GET_ALL_CUSTOMER_ORDERS",
  GET_ALL_CUSTOMER_ORDERS_SUCCESS: "GET_ALL_CUSTOMER_ORDERS_SUCCESS",
  GET_ALL_CUSTOMER_ORDERS_ERROR: "GET_ALL_CUSTOMER_ORDERS_ERROR",

  CLEAR_CART: "CLEAR_CART",

  DISCARD_CART: "DISCARD_CART",
  DISCARD_CART_SUCCESS: "DISCARD_CART_SUCCESS",
  DISCARD_CART_ERROR: "DISCARD_CART_ERROR",

  VERIFY_COUPON: "VERIFY_COUPON",
  VERIFY_COUPON_SUCCESS: "VERIFY_COUPON_SUCCESS",
  VERIFY_COUPON_ERROR: "VERIFY_COUPON_ERROR",

  REGISTER_CUSTOMER: "REGISTER_COUPON",
  REGISTER_CUSTOMER_SUCCESS: "REGISTER_CUSTOMER_SUCCESS",
  REGISTER_CUSTOMER_ERROR: "REGISTER_CUSTOMER_ERROR",

  CHECK_PINCODE: "CHECK_PINCODE",
  CHECK_PINCODE_SUCCESS: "CHECK_PINCODE_SUCCESS",
  CHECK_PINCODE_ERROR: "CHECK_PINCODE_ERROR",

  UPDATE_DELIVERY_TYPE: "UPDATE_DELIVERY_TYPE",

  TRACK_ORDER: "TRACK_ORDER",
  TRACK_ORDER_SUCCESS: "TRACK_ORDER_SUCCESS",
  TRACK_ORDER_ERROR: "TRACK_ORDER_ERROR",
};

export default orderActionTypes;
