import { all, call, put, select, takeLatest } from "redux-saga/effects";

import {
  getAllLobsSuccess,
  getBaseSubscriptionSuccess,
  getCategoriesSuccess,
  getSubCategoriesSuccess,
  getGovtIdTypesSuccess,
  signUpOwnerSuccess,
  uploadGovtIdDataSuccess,
  getBaseSubscription as getBaseSubscriptionAction,
  getCategories as getCategoriesAction,
  updateDeliveryDetailsError,
  updateStoreDetailsSuccess,
  updateStoreDetailsError,
  updateDeliveryDetailsSuccess,
  updateDpSuccess,
  updateDpError,
  getDp as getDpAction,
  getDpSuccess,
  getDpError,
  addBusinessCategorySuccess,
  addBusinessCategoryError,
  getStoreLobsSuccess,
  getStoreLobsError,
  getStoreDetailsSuccess,
  getStoreDetailsError,
  getDeliveryDetailsSuccess,
  getDeliveryDetailsError,
  saveAllServiceLobs,
  saveAllProductLobs,
  getSubCategoriesSearchSuccess,
  getSubCategoriesSearchError,
  getStoreBrandsSuccess,
  getStoreBrandsError,
  addBrandSuccess,
  addBrandError,
  updateCustomerDetailsSuccess,
  updateCustomerDetailsError,
  getCustomerDetailsSuccess,
  getCustomerDetailsError,
  getHomeProductsSuccess,
  getHomeProductsError,
} from "./onBoardingActions";
import authtypes from "../auth/authActionTypes";
import {
  GET_ALL_LOBS,
  GET_CATEGORIES,
  GET_GOVT_ID_TYPES,
  UPLOAD_GOVT_ID,
  OWNER_SIGNUP,
  GET_BASE_SUBSCRIPTION,
  GET_SUB_CATEGORIES,
  UPDATE_DELIVERY_DETAILS,
  UPDATE_STORE_DETAILS,
  UPDATE_DP,
  GET_DP,
  ADD_BUSINESS_CATEGORY,
  GET_STORE_LOBS,
  GET_STORE_DETAILS,
  GET_DELIVERY_DETAILS,
  GET_SUB_CATEGORIES_SEARCH,
  GET_STORE_BRANDS,
  ADD_BRAND,
  CUSTOMER_COMPLETE_PROFILE,
  GET_CUSTOMER_PROFILE,
  GET_CUSTOMER_HOME_PRODUCTS,
} from "../../utils/ApiList";
import Api from "../../middlewares/Api";
import onBoardingActionTypes from "./onBoardingActionTypes";
import { getUserBasicInfo } from "../rootSelector";

const getGovtIdTypesApiCall = async () => {
  const response = await Api.post(`${GET_GOVT_ID_TYPES}?country=India`);
  return response.data;
};

const uploadGovtIdDetailsApiCall = async (data) => {
  const response = await Api.post(UPLOAD_GOVT_ID, data);
  return response.data;
};

const getAllLobsApiCall = async (type) => {
  const response = await Api.post(`${GET_ALL_LOBS}?type=${type}&dl=true`);
  return response.data;
};

const getCategoriesApiCall = async (payload) => {
  const response = await Api.post(`${GET_CATEGORIES}`, payload);
  return response.data;
};

const getSubCategoriesApiCall = async (payload) => {
  const response = await Api.post(`${GET_SUB_CATEGORIES}`, payload);
  return response.data;
};

const singUpOwnerApiCall = async ({ payload }) => {
  const response = await Api.post(OWNER_SIGNUP, {
    ...payload,
  });
  return response.data;
};

const getBaseSubscriptionApiCall = async ({ payload }) => {
  const response = await Api.post(GET_BASE_SUBSCRIPTION, {
    ...payload,
  });

  return response?.data;
};

const updateDeliveryDetailsApiCall = async (payload) => {
  const response = await Api.post(UPDATE_DELIVERY_DETAILS, {
    ...payload,
  });
  return response.data;
};

const getDeliveryDetailsApiCall = async (payload) => {
  const response = await Api.post(GET_DELIVERY_DETAILS, payload);
  return response.data;
};

const getStoreDetailsApiCall = async (payload) => {
  const response = await Api.post(GET_STORE_DETAILS, {
    ...payload,
  });
  return response.data;
};

const updateStoreDetailsApiCall = async (payload) => {
  const response = await Api.post(UPDATE_STORE_DETAILS, {
    ...payload,
  });
  return response.data;
};

const updateDpApiCall = async (payload) => {
  const response = await Api.post(UPDATE_DP, payload);
  return response.data;
};

const getDpApiCall = async (payload) => {
  const response = await Api.post(GET_DP, payload);
  return response.data;
};

export function* getGovtIdTypes() {
  try {
    const govtIds = yield getGovtIdTypesApiCall();
    yield put(getGovtIdTypesSuccess(govtIds));
  } catch (error) {
    console.log("Error");
  }
}

export function* uploadGovtIdDetails({ payload, action }) {
  try {
    const ownerDetails = yield uploadGovtIdDetailsApiCall(payload);
    yield put(uploadGovtIdDataSuccess(ownerDetails));
    if (action) {
      action.navigate("OnBoardingBusinessDetails");
    }
  } catch (error) {
    console.log("Error");
  }
}

export function* getAllLobs({ payload }) {
  try {
    const basic_info = yield select(getUserBasicInfo);
    if (payload === "Both") {
      const productLobs = yield getAllLobsApiCall("Product");
      const serviceLobs = yield getAllLobsApiCall("Service");
      yield put(getAllLobsSuccess(productLobs));
      yield put(saveAllServiceLobs(serviceLobs));
      yield put(saveAllProductLobs(productLobs));
    } else {
      const lobs = yield getAllLobsApiCall(payload ?? basic_info?.biz_type[0]);
      yield put(getAllLobsSuccess(lobs));
      if (payload === "Service") {
        yield put(saveAllServiceLobs(lobs));
      } else {
        yield put(saveAllProductLobs(lobs));
      }
    }
  } catch (error) {
    console.log("Error");
  }
}

const addBusinessCategoryApiCall = async (payload) => {
  const response = await Api.post(ADD_BUSINESS_CATEGORY, {
    ...payload,
  });
  return response.data;
};

const addBrandApiCall = async (payload) => {
  const response = await Api.post(ADD_BRAND, {
    ...payload,
  });
  return response.data;
};

const getSubCategoriesSearchApiCall = async (body) => {
  const response = await Api.post(`${GET_SUB_CATEGORIES_SEARCH}`, body);
  return response.data;
};

const getStoreLobsApiCall = async (body) => {
  const response = await Api.post(`${GET_STORE_LOBS}`, body);
  return response.data;
};

const getStoreBrandsApiCall = async (body) => {
  const response = await Api.post(`${GET_STORE_BRANDS}`, body);
  return response.data;
};

const getCustomerDetailsApiCall = async (body) => {
  const response = await Api.post(`${GET_CUSTOMER_PROFILE}`, body);
  return response.data;
};

const updateCustomerDetailsApiCall = async (body) => {
  const response = await Api.post(`${CUSTOMER_COMPLETE_PROFILE}`, body);
  return response.data;
};

const getHomeProductsApiCall = async (body) => {
  const response = await Api.post(
    `${GET_CUSTOMER_HOME_PRODUCTS}?page=1&size=16&owner_id=97`,
    {
      id: "10101",
      district: "Gautam Buddha Nagar",
      LoB: "Gifting & Stationary",
      lat: 28.608585,
      lon: 77.427029,
      dp: "aaa",
    }
  );
  return response.data;
};

export function* getCategories({ payload }) {
  try {
    const basic_info = yield select(getUserBasicInfo);
    const categories = yield getCategoriesApiCall({
      lob: payload?.lob ?? basic_info?.LoB,
      biz_type: payload?.biz_type ?? basic_info?.biz_type[0],
    });
    yield put(getCategoriesSuccess(categories));
  } catch (error) {
    console.log("Error");
  }
}

export function* getSubCategories({ payload }) {
  try {
    const basic_info = yield select(getUserBasicInfo);
    const categories = yield getSubCategoriesApiCall({
      category: payload ?? "string",
      lob: basic_info?.LoB,
      biz_type: basic_info?.biz_type[0],
    });
    yield put(getSubCategoriesSuccess(categories));
  } catch (error) {
    console.log("Error");
  }
}

export function* signUpOwner(body) {
  try {
    const ownerDetails = yield singUpOwnerApiCall(body);

    yield put(signUpOwnerSuccess(ownerDetails));
    yield put(
      getBaseSubscriptionAction({
        ...ownerDetails,
      })
    );
    yield put(getCategoriesAction());
    if (body?.action) {
      body?.action?.navigate("StoreCreationSummary");
    }
  } catch (error) {
    console.log("Error", error);
  }
}

export function* getBaseSubscription(data) {
  try {
    const subscriptionDetails = yield getBaseSubscriptionApiCall(data);
    yield put(getBaseSubscriptionSuccess(subscriptionDetails));
  } catch (error) {
    console.log("Error", error);
  }
}

export function* updateDeliveryDetails({ payload, action }) {
  try {
    const ownerDetails = yield select(
      (state) => state?.onBoarding?.ownerDetails?.data
    );
    const user = yield select((state) => state?.auth?.currentUser?.user);
    const data = ownerDetails ?? user;
    yield updateDeliveryDetailsApiCall({
      own: data,
      ...payload,
    });

    yield put(updateDeliveryDetailsSuccess([payload]));
    if (action) {
      action.navigate("Accounts");
    }
  } catch (error) {
    yield put(updateDeliveryDetailsError(error));
  }
}

export function* getDeliveryDetails() {
  try {
    const data = yield select(getUserBasicInfo);
    const response = yield getDeliveryDetailsApiCall(data);

    yield put(getDeliveryDetailsSuccess(response));
  } catch (error) {
    yield put(getDeliveryDetailsError(error));
  }
}

export function* updateStoreDetails({ payload, action }) {
  try {
    const ownerDetails = yield select(
      (state) => state?.onBoarding?.ownerDetails?.data
    );
    const user = yield select((state) => state?.auth?.currentUser?.user);
    const data = ownerDetails ?? user;

    yield updateStoreDetailsApiCall({
      basic_info: data,
      ...payload,
    });
    yield put(updateStoreDetailsSuccess(payload));
    if (action) {
      action.navigate("Accounts");
    }
  } catch (error) {
    yield put(updateStoreDetailsError(error));
  }
}

export function* getStoreDetails() {
  try {
    const data = yield select(getUserBasicInfo);
    const response = yield getStoreDetailsApiCall(data);
    yield put(getStoreDetailsSuccess(response));
  } catch (error) {
    yield put(getStoreDetailsError(error));
  }
}

export function* updateDp({ payload, action }) {
  try {
    const ownerDetails = yield select(
      (state) => state?.onBoarding?.ownerDetails?.data
    );
    const user = yield select((state) => state?.auth?.currentUser?.user);
    const data = ownerDetails ?? user;
    const response = yield updateDpApiCall({
      ...data,
      dp: payload,
    });
    yield put(updateDpSuccess(response));
    yield put(getDpAction());
    if (action) {
      action.navigate("Accounts");
    }
  } catch (error) {
    yield put(updateDpError(error));
  }
}

export function* getDp({}) {
  try {
    const user = yield select(getUserBasicInfo);
    const response = yield getDpApiCall({
      ...user,
    });
    yield put(getDpSuccess(response?.image));
  } catch (error) {
    yield put(getDpError(error));
  }
}

export function* addBusinessCategory({ payload, action }) {
  try {
    const basic_info = yield select(getUserBasicInfo);
    const response = yield addBusinessCategoryApiCall({
      data: basic_info,
      product: { lob: payload },
    });
    yield put(addBusinessCategorySuccess(response));
    if (action) {
      action.navigate("Accounts");
    }
  } catch (error) {
    yield put(addBusinessCategoryError(error));
  }
}

export function* addBrand({ payload, action }) {
  try {
    const basic_info = yield select(getUserBasicInfo);
    const response = yield addBrandApiCall({
      data: basic_info,
      ...payload,
    });
    yield put(addBrandSuccess(response));
    if (action) {
      action.navigate("Accounts");
    }
  } catch (error) {
    yield put(addBrandError(error));
  }
}

export function* getStoreLobs() {
  try {
    const basic_info = yield select(getUserBasicInfo);
    if (basic_info?.biz_type) {
      const response = yield getStoreLobsApiCall(basic_info);
      yield put(getStoreLobsSuccess(response));
    } else {
      yield put(getStoreLobsError({ message: "Biz Type Not Found" }));
    }
  } catch (error) {
    yield put(getStoreLobsError(error));
  }
}

export function* getStoreBrands() {
  try {
    const basic_info = yield select(getUserBasicInfo);
    const response = yield getStoreBrandsApiCall(basic_info);
    yield put(getStoreBrandsSuccess(response));
  } catch (error) {
    yield put(getStoreBrandsError(error));
  }
}

export function* getSubCategoriesSearch({ payload }) {
  try {
    const categories = yield getSubCategoriesSearchApiCall({
      ...payload,
    });
    yield put(getSubCategoriesSearchSuccess(categories));
  } catch (error) {
    yield put(getSubCategoriesSearchError(error));
  }
}

export function* updateCustomerDetails({ payload, action, to }) {
  try {
    const data = yield select(getUserBasicInfo);

    yield updateCustomerDetailsApiCall({
      basic: { ...data, lat: "28.535517", lon: "77.391029" },
      customer_name: "",
      email: "",
      phone: "",
      city: "",
      address: "",
      country: "",
      offer_notification: "True",
      catalog_notification: "True",
      new_launch_notification: "True",
      only_interested_lobs_notification: "True",
      interested_lobs: [],
      ...payload,
    });
    yield put(updateCustomerDetailsSuccess(payload));

    if (action) {
      if (to) {
        action.navigate("Bag");
      } else {
        action.navigate("CustomerAccount");
      }
    }
  } catch (error) {
    yield put(updateCustomerDetailsError(error));
  }
}

export function* getCustomerDetails() {
  try {
    const data = yield select(getUserBasicInfo);
    const response = yield getCustomerDetailsApiCall(data);
    yield put(getCustomerDetailsSuccess(response));
  } catch (error) {
    yield put(getCustomerDetailsError(error));
  }
}

export function* getHomeProducts() {
  try {
    const basic_info = yield select(getUserBasicInfo);
    const response = yield getHomeProductsApiCall(basic_info);
    yield put(getHomeProductsSuccess(response));
  } catch (error) {
    yield put(getHomeProductsError(error));
  }
}

export function* onGetGovtIdTypes() {
  yield takeLatest(
    [
      authtypes.VALIDATE_OTP_SUCCESS,
      authtypes.GENERAL_VALIDATE_OTP_SUCCESS,
      authtypes.CUSTOMER_GENERAL_VALIDATE_OTP_SUCCESS,
      onBoardingActionTypes.GET_GOVT_ID_TYPES,
    ],
    getGovtIdTypes
  );
}

export function* onUploadGovtIdDetails() {
  yield takeLatest(
    onBoardingActionTypes.UPLOAD_GOVT_ID_DATA,
    uploadGovtIdDetails
  );
}

export function* onGetAllLobs() {
  yield takeLatest([onBoardingActionTypes.GET_ALL_LOBS], getAllLobs);
}

export function* onGetCategories() {
  yield takeLatest(onBoardingActionTypes.GET_CATEGORIES, getCategories);
}

export function* onGetSubCategories() {
  yield takeLatest(onBoardingActionTypes.GET_SUB_CATEGORIES, getSubCategories);
}

export function* onSignUpOwner() {
  yield takeLatest(onBoardingActionTypes.OWNER_SIGNUP, signUpOwner);
}

export function* onGetBaseSubscriptionDetails() {
  yield takeLatest(
    onBoardingActionTypes.OWNER_SIGNUP_SUCCESS,
    getBaseSubscription
  );
}

export function* onUpdateDeliveryDetails() {
  yield takeLatest(
    onBoardingActionTypes.UPDATE_DELIVERY_DETAILS,
    updateDeliveryDetails
  );
}

export function* onGetDeliveryDetails() {
  yield takeLatest(
    [
      onBoardingActionTypes.GET_DELIVERY_DETAILS,
      onBoardingActionTypes.UPDATE_DELIVERY_DETAILS_SUCCESS,
    ],
    getDeliveryDetails
  );
}

export function* onUpdateCustomerDetails() {
  yield takeLatest(
    onBoardingActionTypes.UPDATE_CUSTOMER_DETAILS,
    updateCustomerDetails
  );
}

export function* onGetCustomerDetails() {
  yield takeLatest(
    [
      onBoardingActionTypes.GET_CUSTOMER_DETAILS,
      onBoardingActionTypes.UPDATE_CUSTOMER_DETAILS_SUCCESS,
      authtypes.CUSTOMER_GENERAL_VALIDATE_OTP_SUCCESS,
    ],
    getCustomerDetails
  );
}

export function* onGetStoreDetails() {
  yield takeLatest(
    [
      onBoardingActionTypes.GET_STORE_DETAILS,
      onBoardingActionTypes.UPDATE_STORE_DETAILS_SUCCESS,
    ],
    getStoreDetails
  );
}

export function* onUpdateStoreDetails() {
  yield takeLatest(
    onBoardingActionTypes.UPDATE_STORE_DETAILS,
    updateStoreDetails
  );
}

export function* onUpdateDp() {
  yield takeLatest(onBoardingActionTypes.UPDATE_DP, updateDp);
}

export function* onGetDp() {
  yield takeLatest(onBoardingActionTypes.GET_DP, getDp);
}

export function* onAddBusinessCategory() {
  yield takeLatest(
    onBoardingActionTypes.ADD_BUSINESS_CATEGORY,
    addBusinessCategory
  );
}

export function* onAddBrand() {
  yield takeLatest(onBoardingActionTypes.ADD_BRAND, addBrand);
}

export function* onGetStoreLobs() {
  yield takeLatest(
    [
      onBoardingActionTypes.GET_STORE_LOBS,
      onBoardingActionTypes.ADD_BUSINESS_CATEGORY_SUCCESS,
    ],
    getStoreLobs
  );
}

export function* onGetSubCategoriesSearch() {
  yield takeLatest(
    onBoardingActionTypes.GET_SUB_CATEGORIES_SEARCH,
    getSubCategoriesSearch
  );
}

export function* onGetStoreBrands() {
  yield takeLatest(onBoardingActionTypes.GET_STORE_BRANDS, getStoreBrands);
}

export function* onGetHomeProducts() {
  yield takeLatest(
    onBoardingActionTypes.GET_CUSTOMER_HOME_PRODUCTS,
    getHomeProducts
  );
}

export function* onBoardingSagas() {
  yield all([
    call(onGetGovtIdTypes),
    call(onUploadGovtIdDetails),
    call(onGetAllLobs),
    call(onGetCategories),
    call(onGetSubCategories),
    call(onSignUpOwner),
    call(onGetBaseSubscriptionDetails),
    call(onUpdateDeliveryDetails),
    call(onGetStoreDetails),
    call(onUpdateStoreDetails),
    call(onUpdateDp),
    call(onGetDp),
    call(onAddBusinessCategory),
    call(onGetStoreLobs),
    call(onGetDeliveryDetails),
    call(onGetSubCategoriesSearch),
    call(onGetStoreBrands),
    call(onAddBrand),
    call(onUpdateCustomerDetails),
    call(onGetCustomerDetails),
    call(onGetHomeProducts),
  ]);
}
