import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { trackOrder } from "../../store/order/orderActions";
import { getFormattedFullDateWithTime } from "../../utils/Dates";
import orderTrackImg from "../../assets/quick_images/order-track.png";
// import "../../assets/css/order-track.css";
import { Link } from "react-router-dom";

const OrderStatus = () => {
  const [orderID, setOrderID] = useState("");
  const [billingPhone, setBillingPhone] = useState("");
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const dispatch = useDispatch();

  const orderTracking = useSelector(
    (state) => state.order?.trackOrder?.data
  )?.[0];

  return (
    <>
      <section className="breadcrumbs">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="breadcrumbs-wrapper">
                <span>
                  <Link to="/">Home</Link>
                </span>
                <span>/</span>
                <span className="current">Track Your Order</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tracking-section common-padding">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Order Tracking Details</h2>
              <div className="track-no-wrapper">
                <div className="order-id">
                  Order ID- <span>12473405612354223</span>
                </div>
                <div>
                  <a href="">Order Details</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="tracking-detail-section common-padding">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="treack-detail-wrapper">
                <div className="track-image">
                  <img src={orderTrackImg} className="img-fluid" />
                </div>
                <div className="treack-deatils">
                  <h3>Passport Wallet Keychain Sunglass Gift Combo for Men</h3>
                  <div className="track-pro-desc">
                    <p>
                      Box Packing Inclusions 1 Personalized Wallet, 1
                      Personalized Passport Cover, 1 Personalized Sunglass
                      Cover, 1 Personalized Keychain.
                    </p>
                    <p>Color Options - Black Brown</p>
                  </div>
                  <h6>
                    ₹999.00<del>₹1200.00</del>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="track-chart common-padding">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="mainWrapper">
                <div className="statusBar">
                  <span className="pBar"></span>
                  <div className="node n0 done nConfirm0">
                    <span className="text-top t0 done nConfirm0">
                      Order Confirmed
                    </span>
                    <div className="main done m0 done nConfirm0"></div>
                    <span className="text t0 done nConfirm0">
                      Mon, 06th July 2024
                    </span>
                  </div>
                  <div className="node n1 nConfirm1">
                    <span className="text-top t1 nConfirm1">Shipped</span>
                    <div className="main m1 nConfirm1"></div>
                    <span className="text t1 nConfirm1">
                      Tue, 07th July 2024
                    </span>
                  </div>
                  <div className="node n2 nConfirm2">
                    <span className="text-top t2 nConfirm2">
                      Out For Delivery
                    </span>
                    <div className="main m2 nConfirm2"></div>
                    <span className="text t2 nConfirm2">
                      Expected by Mon, 14th July 2024
                    </span>
                  </div>
                  <div className="node n3 nConfirm3">
                    <span className="text-top t3 nConfirm3">Delivered</span>
                    <div className="main m3 nConfirm3"></div>
                    <span className="text t3 nConfirm3">
                      Expected by Mon, 14th July 2024
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="track-table common-padding">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <table>
                <thead>
                  <tr>
                    <th>Date & Time</th>
                    <th>Location</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>13 July 2024, 12:30 Hr</td>
                    <td>Nagpur</td>
                    <td>Shipment received at facility</td>
                  </tr>
                  <tr>
                    <td>07 July 2024, 13:30 Hr</td>
                    <td>Delhi</td>
                    <td>Order Shipped</td>
                  </tr>
                  <tr>
                    <td>07 July 2024, 11:25 Hr</td>
                    <td>Delhi</td>
                    <td>Order Packed</td>
                  </tr>
                  <tr>
                    <td>06 July 2024, 13:05 Hr</td>
                    <td>Delhi</td>
                    <td>Order Confirmed</td>
                  </tr>
                  <tr>
                    <td>06 July 2024, 09:33 Hr</td>
                    <td>Nagpur</td>
                    <td>Order Placed</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OrderStatus;
