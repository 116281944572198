const onBoardingActionTypes = {
  GET_SUB_CATEGORY_SIZES: 'GET_SUB_CATEGORY_SIZES',
  GET_SUB_CATEGORY_SIZES_SUCCESS: 'GET_SUB_CATEGORY_SIZES_SUCCESS',
  GET_SUB_CATEGORY_SIZES_ERROR: 'GET_SUB_CATEGORY_SIZES_ERROR',

  GET_ALL_OPERATIONAL_DISTRICTS: 'GET_ALL_OPERATIONAL_DISTRICTS',
  GET_ALL_OPERATIONAL_DISTRICTS_SUCCESS:
    'GET_ALL_OPERATIONAL_DISTRICTS_SUCCESS',
  GET_ALL_OPERATIONAL_DISTRICTS_ERROR: 'GET_ALL_OPERATIONAL_DISTRICTS_ERROR',

  GET_PRODUCT_METADATA: 'GET_PRODUCT_METADATA',
  GET_PRODUCT_METADATA_SUCCESS: 'GET_PRODUCT_METADATA_SUCCESS',
  GET_PRODUCT_METADATA_ERROR: 'GET_PRODUCT_METADATA_ERROR',

  GET_BANNER_METADATA: 'GET_BANNER_METADATA',
  GET_BANNER_METADATA_SUCCESS: 'GET_BANNER_METADATA_SUCCESS',
  GET_BANNER_METADATA_ERROR: 'GET_BANNER_METADATA_ERROR',

  GET_STORE_METADATA: 'GET_STORE_METADATA',
  GET_STORE_METADATA_SUCCESS: 'GET_STORE_METADATA_SUCCESS',
  GET_STORE_METADATA_ERROR: 'GET_STORE_METADATA_ERROR',
};

export default onBoardingActionTypes;
