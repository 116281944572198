import { combineReducers } from "redux";

import auth from "./auth/authReducer";
import onBoarding from "./onBoarding/onBoardingReducer";
import product from "./product/productReducer";
import catalog from "./catalog/catalogReducer";
import banner from "./banner/bannerReducer";
import insights from "./insights/insightsReducer";
import subscription from "./subscription/subscriptionReducer";
import master from "./master/masterReducer";
import order from "./order/orderReducer";
import app from "./app/appReducer";
import notify from "./notify/notifyReducer";
import review from "./review/reviewReducer";
import follow from "./follow/followReducer";
import store from "./store/storeReducer";
import productsReducer from "./slices/products";
import settingsReducer from "./slices/settings";
import userReducer from "./slices/user";

const appReducer = combineReducers({
  app,
  auth,
  onBoarding,
  product,
  catalog,
  banner,
  insights,
  subscription,
  master,
  order,
  notify,
  review,
  follow,
  store,
  products: productsReducer,
  user: userReducer,
  settings: settingsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "LOG_OUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
