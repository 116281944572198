import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/img/logo.png";
import logoWhite from "../../../assets/img/logo-white.png";
import { MenuData } from "./MenuData";
import NaveItems from "./NaveItems";
import TopHeader from "./TopHeader";
import { useHistory } from "react-router-dom";
import svg from "../../../assets/img/svg/cancel.svg";
import svgsearch from "../../../assets/img/svg/search.svg";
import { searchCatalogs } from "../../../store/catalog/catalogActions";

import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { getAllLobs } from "../../../store/onBoarding/onBoardingActions";
import { pushToDataLayer } from "../../../App";

const Header = ({
  hotProductRef,
  setSelectedSubcategories,
  isHome = false,
  setSelectedType,
  setSelectedCategory,
  setSelectedSubCategory,
  setSelectedCategoryDetails,
}) => {
  const [click, setClick] = useState(false);
  const [show, setShow] = useState("");
  const history = useHistory();
  let carts = useSelector((state) => state.products.carts);
  let favorites = useSelector((state) => state.products.favorites);
  let dispatch = useDispatch();

  const { category, subcategory } = useParams();

  let lobs = useSelector((state) => state?.onBoarding?.productLobs?.data);
  let newLobs = useSelector((state) => state?.onBoarding?.productLobs?.newData);

  const giftingLob = lobs?.find((lob) => lob.lob === "Gifting & Stationary");

  const giftForLife = giftingLob?.categories?.find(
    (c) => c.category === "Gifts for lifetime"
  );
  const lifeStyle = giftingLob?.categories?.find(
    (c) => c.category === "Lifestyle"
  );
  const somethingMemorable = giftingLob?.categories?.find(
    (c) => c.category === "Something Memorable"
  );

  const rmCartProduct = (id) => {
    dispatch({ type: "products/removeCart", payload: { id } });
    pushToDataLayer("remove_from_cart");
  };

  const rmFavProduct = (id) => {
    dispatch({ type: "products/removeFav", payload: { id } });
  };

  const cartTotal = () => {
    return carts.reduce(function (total, item) {
      return total + (item.quantity || 1) * item.discount_price;
    }, 0);
  };

  useEffect(() => {
    if (category && subcategory) {
      // hotProductRef?.current?.scrollIntoView({
      //   behavior: "smooth",
      // });
    }
  }, [category, subcategory]);

  const handleClick = () => {
    if (click) {
      document.querySelector("#offcanvas-add-cart").style =
        "transform: translateX(100%);";
    } else {
      document.querySelector("#offcanvas-add-cart").style =
        "transform: translateX(0%);";
    }
    setClick(!click);
  };
  const handleWish = () => {
    if (click) {
      document.querySelector("#offcanvas-wishlish").style =
        "transform: translateX(100%);";
    } else {
      document.querySelector("#offcanvas-wishlish").style =
        "transform: translateX(0);";
    }
    setClick(!click);
  };

  const handleSearch = () => {
    if (click) {
      document.querySelector("#search").style =
        "transform: translate(-100%, 0); opacity: 0";
    } else {
      document.querySelector("#search").style =
        "transform: translate(0px, 0px); opacity: 1";
    }
    setClick(!click);
  };
  const handleabout = () => {
    if (click) {
      document.querySelector("#offcanvas-about").style =
        "transform: translateX(100%);";
    } else {
      document.querySelector("#offcanvas-about").style =
        "transform: translateX(0%);";
    }
    setClick(!click);
  };
  const handlemenu = (isDeskTop = false) => {
    if (!isDeskTop) {
      if (click) {
        document.querySelector("#mobile-menu-offcanvas").style =
          "transform: translateX(100%);";
      } else {
        document.querySelector("#mobile-menu-offcanvas").style =
          "transform: translateX(0%);";
      }
      setClick(!click);
    }
  };

  const handleShow = (value) => {
    value === show ? setShow("") : setShow(value);
  };

  // Sticky Menu Area
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  useEffect(() => {
    dispatch(getAllLobs());
  }, []);

  const highlights = useSelector((state) => state?.onBoarding?.highlights);

  const isSticky = (e) => {
    const header = document.querySelector(".header-section");
    const scrollTop = window.scrollY;
    scrollTop >= 250
      ? header?.classList?.add("is-sticky")
      : header?.classList?.remove("is-sticky");
  };

  const getProducts = (extraBody, isCategory = false, isDesktop = false) => {
    dispatch(
      searchCatalogs(
        {
          basic_info: {
            id: "10101",
            district: "Gautam Buddha Nagar",
            LoB: "Gifting & Stationary",
            lat: 28.608585,
            lon: 77.427029,
            dp: "aaa",
          },
          brand_name: "",
          color: [],
          description: "",
          distance: -1,
          geog_name: "",
          geog_type: "",
          item_name: "",
          max_discount_price: -1,
          min_discount_percent: -1,
          min_discount_price: -1,
          negotiable: "",
          offer_type: "",
          size: [],
          sort: { discount_percent: "", discount_price: "", distance: "" },
          unit_count: 0,
          ...extraBody,
        },
        undefined,
        { page: 1, size: isCategory ? 16 : 16, top_products: isCategory }
      )
    );
    handlemenu(isDesktop);
    // hotProductRef?.current?.scrollIntoView({
    //   behavior: "smooth",
    // });
  };

  const onCategoryClick = (lob, isDesktop) => {
    typeof setSelectedSubcategories == "function" &&
      setSelectedSubcategories(lob?.sub_categories);
    typeof setSelectedType == "function" && setSelectedType("category");
    typeof setSelectedCategory == "function" && setSelectedCategory(lob?.lob);
    if (lob?.lob === "Same Day") {
      getProducts(
        {
          manu_tat: 15,
          subcategories: lob?.sub_categories,
        },
        false,
        true
      );
    } else if (lob?.lob === "Personalize") {
      getProducts(
        {
          personalized: "True",
          subcategories: lob?.sub_categories,
        },
        false,
        true
      );
    } else {
      getProducts(
        {
          subcategories: lob?.sub_categories,
        },
        true,
        isDesktop
      );
    }
  };

  return (
    <>
      <TopHeader />
      <header className="header-section d-none d-xl-block">
        <div className="header-wrapper">
          <div className="header-bottom header-bottom-color--golden section-fluid sticky-header sticky-color--golden">
            <div className="container">
              <div className="row">
                <div className="col-12 d-flex align-items-center justify-content-between">
                  <div className="header-logo">
                    <div className="logo" style={{ width: "60%" }}>
                      <Link to="/">
                        <img
                          src={
                            "https://test-catalog-bucket1.s3.ap-south-1.amazonaws.com/web/quickgifting_web.png"
                          }
                          alt="logo"
                        />
                      </Link>
                    </div>
                  </div>
                  <div className="main-menu menu-color--black menu-hover-color--golden d-none d-xl-block">
                    <nav>
                      <ul>
                        {newLobs?.map((lob) => {
                          return (
                            <NaveItems
                              isHome={isHome}
                              item={{
                                name: lob?.lob,
                                href: "/",
                                children:
                                  lob?.categories?.map((sc) => {
                                    return {
                                      name: sc?.category,
                                      category: lob?.lob,
                                      onClick: () => {
                                        typeof setSelectedSubCategory ==
                                          "function" &&
                                          setSelectedSubCategory(sc?.category);
                                        typeof setSelectedCategoryDetails ==
                                          "function" &&
                                          setSelectedCategoryDetails(sc);
                                        typeof setSelectedType == "function" &&
                                          setSelectedType("sub-category");
                                        typeof setSelectedCategory ==
                                          "function" &&
                                          setSelectedCategory(
                                            `${lob?.lob} > ${sc?.category}`
                                          );
                                        // if (lob?.lob === "Same Day") {
                                        //   getProducts(
                                        //     {
                                        //       manu_tat: 10,
                                        //     },
                                        //     false,
                                        //     true
                                        //   );
                                        // } else if (lob?.lob === "Personalize") {
                                        //   getProducts(
                                        //     {
                                        //       personalized: "True",
                                        //     },
                                        //     false,
                                        //     true
                                        //   );
                                        // } else {
                                        //   getProducts(
                                        //     {
                                        //       subcategories:
                                        //         sc?.sub_categories?.map(
                                        //           (sc) => sc?.subcat
                                        //         ),
                                        //     },
                                        //     false,
                                        //     true
                                        //   );
                                        // }
                                      },
                                    };
                                  }) ?? [],
                              }}
                              key={0}
                              onCategoryClick={() => {
                                onCategoryClick(lob, true);
                                // if (!isHome) {
                                //   history.push("/");
                                // } else {
                                if (
                                  lob?.lob === "Same Day" ||
                                  lob?.lob === "Personalized"
                                ) {
                                  history.push(
                                    `/gift-giving/${lob?.lob}/${lob?.lob}`
                                  );
                                }
                                // }
                              }}
                            />
                          );
                        })}
                        <li>
                          <Link to="/order-tracking">Track Order</Link>
                        </li>
                      </ul>
                    </nav>
                  </div>

                  <ul className="header-action-link action-color--black action-hover-color--golden">
                    {/* <li>
                      {favorites.length ? (
                        <a
                          href="#offcanvas-wishlish"
                          className="offcanvas-toggle"
                          onClick={handleWish}
                        >
                          <i className="fa fa-heart"></i>
                          <span className="item-count">{favorites.length}</span>
                        </a>
                      ) : (
                        <a
                          href="#offcanvas-wishlish"
                          className="offcanvas-toggle"
                        >
                          <i className="fa fa-heart"></i>
                          <span className="item-count">{favorites.length}</span>
                        </a>
                      )}
                    </li> */}
                    <li>
                      {carts.length ? (
                        <a className="offcanvas-toggle" onClick={handleClick}>
                          <i className="fa fa-shopping-bag"></i>
                          <span className="item-count">{carts.length}</span>
                        </a>
                      ) : (
                        <a href="#!" className="offcanvas-toggle">
                          <i className="fa fa-shopping-bag"></i>
                          <span className="item-count">{carts.length}</span>
                        </a>
                      )}
                    </li>
                    {/* <li>
                      <a
                        href="#search"
                        className="search_width"
                        onClick={handleSearch}
                      >
                        <img src={svgsearch} alt="img" />
                      </a>
                    </li> */}
                    {/* <li>
                      <a
                        href="#offcanvas-about"
                        className="offacnvas offside-about offcanvas-toggle"
                        onClick={handleabout}
                      >
                        <i className="fa fa-bars"></i>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="mobile-header sticky-header sticky-color--golden mobile-header-bg-color--golden section-fluid d-lg-block d-xl-none">
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-between">
              <div className="mobile-header-left">
                <ul className="mobile-menu-logo">
                  <li>
                    <Link to="/">
                      <div className="logo">
                        <img
                          src={
                            "https://test-catalog-bucket1.s3.ap-south-1.amazonaws.com/web/quickgifting_web.png"
                          }
                          alt="logo"
                        />
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>

              <div className="mobile-right-side">
                <ul className="header-action-link action-color--black action-hover-color--golden">
                  <li>
                    {carts.length ? (
                      <a
                        href="#!"
                        className="offcanvas-toggle"
                        onClick={handleClick}
                      >
                        <i className="fa fa-shopping-bag"></i>
                        <span className="item-count">{carts.length}</span>
                      </a>
                    ) : (
                      <a href="#!" className="offcanvas-toggle">
                        <i className="fa fa-shopping-bag"></i>
                        <span className="item-count">{carts.length}</span>
                      </a>
                    )}
                  </li>
                  <li>
                    <a
                      href="#!"
                      className="offcanvas-toggle offside-menu"
                      onClick={() => handlemenu()}
                    >
                      <i className="fa fa-bars"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="mobile-menu-offcanvas"
        className="offcanvas offcanvas-rightside offcanvas-mobile-menu-section"
      >
        <div className="offcanvas-header text-right">
          <button className="offcanvas-close" onClick={() => handlemenu()}>
            <img src={svg} alt="icon" />
          </button>
        </div>
        <div className="offcanvas-mobile-menu-wrapper">
          <div className="mobile-menu-bottom">
            <div className="offcanvas-menu">
              <ul>
                {newLobs?.map((lob) => {
                  return (
                    <li>
                      {/* {isHome ? ( */}
                      <a
                        href="#!"
                        onClick={() => {
                          handleShow(lob?.lob);
                          if (lob?.lob === "Same Day") {
                            // getProducts(
                            //   {
                            //     manu_tat: 10,
                            //   },
                            //   false,
                            //   true
                            // );
                            history.push(
                              `/gift-giving/${lob?.lob}/${lob?.lob}`
                            );
                          } else if (lob?.lob === "Personalized") {
                            // getProducts(
                            //   {
                            //     personalized: "True",
                            //   },
                            //   false,
                            //   true
                            // );
                            history.push(
                              `/gift-giving/${lob?.lob}/${lob?.lob}`
                            );
                          }
                          // else {
                          //   onCategoryClick(lob, true);
                          //   history.push(`/shop/${lob?.lob}`);
                          // }
                        }}
                      >
                        <span>{lob?.lob}</span>
                        {!["Same Day", "Personalized"].includes(show) &&
                          (show === lob?.lob ? (
                            <i
                              className="fa fa-angle-up"
                              style={{ fontSize: 18, marginLeft: 4 }}
                            ></i>
                          ) : (
                            <i
                              className="fa fa-angle-down"
                              style={{ fontSize: 18, marginLeft: 4 }}
                            ></i>
                          ))}
                      </a>
                      {/* // ) : ( // <Link to="/">{lob?.lob}</Link>
                      // )} */}
                      {show === lob?.lob &&
                      !["Same Day", "Personalized"].includes(show) ? (
                        <ul className="mobile-sub-menu">
                          {lob?.categories?.map((sc) => (
                            <li
                              onClick={() => {
                                typeof setSelectedSubCategory == "function" &&
                                  setSelectedSubCategory(sc?.category);
                                typeof setSelectedCategoryDetails ==
                                  "function" && setSelectedCategoryDetails(sc);
                                typeof setSelectedType == "function" &&
                                  setSelectedType("sub-category");
                                typeof setSelectedCategory == "function" &&
                                  setSelectedCategory(
                                    `${lob?.lob} > ${sc?.category}`
                                  );
                                if (sc?.category === "Same Day") {
                                  getProducts(
                                    {
                                      manu_tat: 15,
                                      subcategories: sc?.sub_categories?.map(
                                        (sc) => sc?.subcat
                                      ),
                                    },
                                    false,
                                    true
                                  );
                                } else if (sc?.category === "Personalized") {
                                  getProducts(
                                    {
                                      personalized: "True",
                                      subcategories: sc?.sub_categories?.map(
                                        (sc) => sc?.subcat
                                      ),
                                    },
                                    false,
                                    true
                                  );
                                } else {
                                  getProducts(
                                    {
                                      subcategories: sc?.sub_categories?.map(
                                        (sc) => sc?.subcat
                                      ),
                                    },
                                    false,
                                    false
                                  );
                                }
                              }}
                            >
                              {/* {isHome ? ( */}
                              <Link
                                to={`/gift-giving/${lob?.lob}/${sc?.category}`}
                              >
                                {sc?.category}
                              </Link>
                              {/* // ) : (
                              //   <Link to="/">{sc}</Link>
                              // )} */}
                            </li>
                          ))}
                        </ul>
                      ) : null}
                    </li>
                  );
                })}
                <li>
                  <Link to="/order-tracking">Track Order</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="mobile-contact-info">
            <div className="logo">
              <Link to="/">
                <img
                  src={
                    "https://test-catalog-bucket1.s3.ap-south-1.amazonaws.com/web/quickgifting_web.png"
                  }
                  alt="img"
                />
              </Link>
            </div>
            <address className="address">
              <span>
                Address: Office no. 741, 7th floor, Gaur City Mall, Greater
                Noida West, 201009
              </span>
              <span>
                Call Us:{" "}
                <a href="tel:+919205546825" style={{ color: "white" }}>
                  +91 9205546825
                </a>
              </span>
              <span>
                Email:{" "}
                <a
                  href="mailto:info@quickgifting.com"
                  style={{ color: "white" }}
                >
                  info@quickgifting.com
                </a>
              </span>
            </address>
            <ul className="social-link">
              <li>
                <a
                  href="https://www.facebook.com/profile.php?id=61561955661680"
                  target="_blank"
                >
                  <i className="fa fa-facebook"></i>
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/quickgiftingofficial"
                  target="_blank"
                >
                  <i className="fa fa-instagram"></i>
                </a>
              </li>
            </ul>
            <ul className="user-link">
              {/* <li>
                <Link to="/wishlist">Wishlist</Link>
              </li> */}
              <li>
                <Link to="/cart">Cart</Link>
              </li>
              <li>
                <Link to="/checkout-one">Checkout</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        id="offcanvas-about"
        className="offcanvas offcanvas-rightside offcanvas-mobile-about-section"
      >
        <div className="offcanvas-header text-right">
          <button className="offcanvas-close" onClick={handleabout}>
            <img src={svg} alt="icon" />
          </button>
        </div>
        <div className="mobile-contact-info">
          <address className="address">
            <img
              src={
                "https://test-catalog-bucket1.s3.ap-south-1.amazonaws.com/web/quickgifting_web.png"
              }
              alt="logo"
            />
            <span>
              Address: Office no. 741, 7th floor, Gaur City Mall, Greater Noida
              West, 201009
            </span>
            <span>
              Call Us:{" "}
              <a href="tel:+919205546825" style={{ color: "white" }}>
                +91 9205546825
              </a>
            </span>
            <span>
              Email:{" "}
              <a href="mailto:info@quickgifting.com" style={{ color: "white" }}>
                info@quickgifting.com
              </a>
            </span>
          </address>
          <ul className="social-link">
            <li>
              <a href="#!">
                <i className="fa fa-facebook"></i>
              </a>
            </li>
            <li>
              <a href="#!">
                <i className="fa fa-instagram"></i>
              </a>
            </li>
          </ul>
          <ul className="user-link">
            <li>
              <Link to="/wishlist">Wishlist</Link>
            </li>
            <li>
              <Link to="/cart">Cart</Link>
            </li>
            <li>
              <Link to="/checkout-one">Checkout</Link>
            </li>
          </ul>
        </div>
      </div>

      <div
        id="offcanvas-add-cart"
        className="offcanvas offcanvas-rightside offcanvas-add-cart-section"
      >
        <div className="offcanvas-header text-right">
          <button className="offcanvas-close" onClick={handleClick}>
            <img src={svg} alt="icon" />
          </button>
        </div>
        <div className="offcanvas-add-cart-wrapper">
          <h4 className="offcanvas-title">Shopping Cart</h4>
          <ul className="offcanvas-cart">
            {carts.map((data, index) => (
              <li className="offcanvas-wishlist-item-single" key={index}>
                <div className="offcanvas-wishlist-item-block">
                  <Link
                    to={`/product-details-one/${data.id}`}
                    className="offcanvas-wishlist-item-image-link"
                  >
                    <img
                      src={data.product_images_multiple?.[0]}
                      alt="img"
                      className="offcanvas-wishlist-image"
                    />
                  </Link>
                  <div className="offcanvas-wishlist-item-content">
                    <Link
                      to={`/product-details-one/${data.id}`}
                      className="offcanvas-wishlist-item-link"
                    >
                      {data.title}
                    </Link>
                    <div className="offcanvas-wishlist-item-details">
                      <span className="offcanvas-wishlist-item-details-quantity">
                        {data.quantity || 1} x
                      </span>
                      <span className="offcanvas-wishlist-item-details-price">
                        {" "}
                        ₹{data.discount_price}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="offcanvas-wishlist-item-delete text-right">
                  <a
                    href=""
                    className="offcanvas-wishlist-item-delete"
                    onClick={() => rmCartProduct(data.id)}
                  >
                    <i className="fa fa-trash"></i>
                  </a>
                </div>
              </li>
            ))}
          </ul>
          <div className="offcanvas-cart-total-price">
            <span className="offcanvas-cart-total-price-text">Subtotal:</span>
            <span className="offcanvas-cart-total-price-value">
              ₹{cartTotal()}.00
            </span>
          </div>
          <ul className="offcanvas-cart-action-button">
            <li>
              <Link
                to="/cart"
                className="theme-btn-one btn-black-overlay btn_md"
              >
                View Cart
              </Link>
            </li>
            <li>
              <Link
                to="/checkout-one"
                className="theme-btn-one btn-black-overlay btn_md"
              >
                Checkout
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div
        id="offcanvas-wishlish"
        className="offcanvas offcanvas-rightside offcanvas-add-cart-section"
      >
        <div className="offcanvas-header text-right">
          <button className="offcanvas-close" onClick={handleWish}>
            <img src={svg} alt="icon" />
          </button>
        </div>
        <div className="offcanvas-wishlist-wrapper">
          <h4 className="offcanvas-title">Wishlist</h4>

          <ul className="offcanvas-wishlist">
            {favorites.map((data, index) => (
              <li className="offcanvas-wishlist-item-single" key={index}>
                <div className="offcanvas-wishlist-item-block">
                  <Link
                    to={`/product-details-one/${data.id}`}
                    className="offcanvas-wishlist-item-image-link"
                  >
                    <img
                      src={data.img}
                      alt="img"
                      className="offcanvas-wishlist-image"
                    />
                  </Link>
                  <div className="offcanvas-wishlist-item-content">
                    <Link
                      to={`/product-details-one/${data.id}`}
                      className="offcanvas-wishlist-item-link"
                    >
                      {data.title}
                    </Link>
                    <div className="offcanvas-wishlist-item-details">
                      <span className="offcanvas-wishlist-item-details-quantity">
                        1 x
                      </span>
                      <span className="offcanvas-wishlist-item-details-price">
                        {data.price}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="offcanvas-wishlist-item-delete text-right">
                  <a
                    href="#!"
                    className="offcanvas-wishlist-item-delete"
                    onClick={() => rmFavProduct(data.id)}
                  >
                    <i className="fa fa-trash"></i>
                  </a>
                </div>
              </li>
            ))}
          </ul>
          <ul className="offcanvas-wishlist-action-button">
            <li>
              <Link
                to="/wishlist"
                className="theme-btn-one btn-black-overlay btn_md"
              >
                View wishlist
              </Link>
            </li>
          </ul>
        </div>
      </div>

      <div id="search" className="search-modal">
        <button type="button" className="close" onClick={handleSearch}>
          <img src={svg} alt="icon" />
        </button>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSearch();
            Swal.fire("Success", "Check out the Results", "success");
            history.push("/shop");
          }}
        >
          <input type="search" placeholder="type keyword(s) here" required />
          <button type="submit" className="btn btn-lg btn-main-search">
            Search
          </button>
        </form>
      </div>
    </>
  );
};

export default Header;
