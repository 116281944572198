import types from './insightsActionTypes';

export const getOverallStoreStats = () => {
  return {
    type: types.GET_OVERALL_STORE_STATS,
  };
};

export const getOverallStoreStatsSuccess = body => {
  return {
    type: types.GET_OVERALL_STORE_STATS_SUCCESS,
    payload: body,
  };
};

export const getOverallStoreStatsError = body => {
  return {
    type: types.GET_OVERALL_STORE_STATS_ERROR,
    payload: body,
  };
};

export const getCatalogDailyTrends = body => {
  return {
    type: types.GET_CATALOG_DAILY_TRENDS,
    payload: body,
  };
};

export const getCatalogDailyTrendsSuccess = body => {
  return {
    type: types.GET_CATALOG_DAILY_TRENDS_SUCCESS,
    payload: body,
  };
};

export const getCatalogDailyTrendsError = body => {
  return {
    type: types.GET_CATALOG_DAILY_TRENDS_ERROR,
    payload: body,
  };
};

export const getFinancialDailyTrends = body => {
  return {
    type: types.GET_FINANCIAL_DAILY_TRENDS,
    payload: body,
  };
};

export const getFinancialDailyTrendsSuccess = body => {
  return {
    type: types.GET_FINANCIAL_DAILY_TRENDS_SUCCESS,
    payload: body,
  };
};

export const getFinancialDailyTrendsError = body => {
  return {
    type: types.GET_FINANCIAL_DAILY_TRENDS_ERROR,
    payload: body,
  };
};

export const getBannerDailyTrends = body => {
  return {
    type: types.GET_BANNER_DAILY_TRENDS,
    payload: body,
  };
};

export const getBannerDailyTrendsSuccess = body => {
  return {
    type: types.GET_BANNER_DAILY_TRENDS_SUCCESS,
    payload: body,
  };
};

export const getBannerDailyTrendsError = body => {
  return {
    type: types.GET_CATALOG_DAILY_TRENDS_ERROR,
    payload: body,
  };
};

export const getStoreSocialHealth = body => {
  return {
    type: types.GET_STORE_SOCIAL_HEALTH,
    payload: body,
  };
};

export const getStoreSocialHealthSuccess = body => {
  return {
    type: types.GET_STORE_SOCIAL_HEALTH_SUCCESS,
    payload: body,
  };
};

export const getStoreSocialHealthError = body => {
  return {
    type: types.GET_STORE_SOCIAL_HEALTH_ERROR,
    payload: body,
  };
};

export const getCatalogInsights = body => {
  return {
    type: types.GET_CATALOG_INSIGHTS,
    payload: body,
  };
};

export const getCatalogInsightsSuccess = body => {
  return {
    type: types.GET_CATALOG_INSIGHTS_SUCCESS,
    payload: body,
  };
};

export const getCatalogInsightsError = body => {
  return {
    type: types.GET_CATALOG_INSIGHTS_ERROR,
    payload: body,
  };
};

export const getCatalogTopProducts = body => {
  return {
    type: types.GET_CATALOG_TOP_PRODUCTS,
    payload: body,
  };
};

export const getCatalogTopProductsSuccess = body => {
  return {
    type: types.GET_CATALOG_TOP_PRODUCTS_SUCCESS,
    payload: body,
  };
};

export const getCatalogTopProductsError = body => {
  return {
    type: types.GET_CATALOG_TOP_PRODUCTS_ERROR,
    payload: body,
  };
};

export const getBannerInsights = body => {
  return {
    type: types.GET_BANNER_INSIGHTS,
    payload: body,
  };
};

export const getBannerInsightsSuccess = body => {
  return {
    type: types.GET_BANNER_INSIGHTS_SUCCESS,
    payload: body,
  };
};

export const getBannerInsightsError = body => {
  return {
    type: types.GET_BANNER_INSIGHTS_ERROR,
    payload: body,
  };
};

export const getAllCatalogDailyTrends = body => {
  return {
    type: types.GET_ALL_CATALOG_DAILY_TRENDS,
    payload: body,
  };
};

export const getAllCatalogDailyTrendsSuccess = body => {
  return {
    type: types.GET_ALL_CATALOG_DAILY_TRENDS_SUCCESS,
    payload: body,
  };
};

export const getAllCatalogDailyTrendsError = body => {
  return {
    type: types.GET_ALL_CATALOG_DAILY_TRENDS_ERROR,
    payload: body,
  };
};

export const getAllBannerDailyTrends = body => {
  return {
    type: types.GET_ALL_BANNER_DAILY_TRENDS,
    payload: body,
  };
};

export const getAllBannerDailyTrendsSuccess = body => {
  return {
    type: types.GET_ALL_BANNER_DAILY_TRENDS_SUCCESS,
    payload: body,
  };
};

export const getAllBannerDailyTrendsError = body => {
  return {
    type: types.GET_ALL_BANNER_DAILY_TRENDS_ERROR,
    payload: body,
  };
};

export const getOverallStats = body => {
  return {
    type: types.GET_OVERALL_STATS,
    payload: body,
  };
};

export const getOverallStatsSuccess = body => {
  return {
    type: types.GET_OVERALL_STATS_SUCCESS,
    payload: body,
  };
};

export const getOverallStatsError = body => {
  return {
    type: types.GET_OVERALL_STATS_ERROR,
    payload: body,
  };
};
