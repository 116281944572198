import React from "react";
import Header from "../../component/Common/Header";
import Footer from "../../component/Common/Footer";
import OrderStatus from "../../component/OrderStatus";

const OrderStatusPage = () => {
  return (
    <>
      <Header />
      {/* <Banner title="Order Tracking" /> */}
      <OrderStatus />
      <Footer />
    </>
  );
};

export default OrderStatusPage;
