import { all, call, put, takeLatest } from "redux-saga/effects";

import { GET_ANALYSIS_TYPES } from "../../utils/ApiList";
import Api from "../../middlewares/Api";
import appActionTypes from "./appActionTypes";
import { getAnalysisTypesError, getAnalysisTypesSuccess } from "./appActions";

const getAnalysisTypesApiCall = async () => {
  const response = await Api.get(`${GET_ANALYSIS_TYPES}`);
  return response.data;
};

export function* getAnalysisTypes() {
  try {
    const analysisTypes = yield getAnalysisTypesApiCall();
    yield put(getAnalysisTypesSuccess(analysisTypes));
  } catch (error) {
    yield put(getAnalysisTypesError(error));
  }
}

export function* onGetAnalysisTypes() {
  yield takeLatest(
    [appActionTypes.GET_ANALYSIS_TYPES, appActionTypes.APP_LOAD],
    getAnalysisTypes
  );
}

export function* appSagas() {
  yield all([call(onGetAnalysisTypes)]);
}
