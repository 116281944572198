const onFollowActionTypes = {
  BANNER_CLICKED: 'BANNER_CLICKED',
  BANNER_CLICKED_SUCCESS: 'BANNER_CLICKED_SUCCESS',
  BANNER_CLICKED_ERROR: 'BANNER_CLICKED_ERROR',

  CATALOG_CLICKED: 'CATALOG_CLICKED',
  CATALOG_CLICKED_SUCCESS: 'CATALOG_CLICKED_SUCCESS',
  CATALOG_CLICKED_ERROR: 'CATALOG_CLICKED_ERROR',

  FOLLOW_STORE: 'FOLLOW_STORE',
  FOLLOW_STORE_SUCCESS: 'FOLLOW_STORE_SUCCESS',
  FOLLOW_STORE_ERROR: 'FOLLOW_STORE_ERROR',

  VIEWED_STORE_PROFILE: 'VIEWED_STORE_PROFILE',
  VIEWED_STORE_PROFILE_SUCCESS: 'VIEWED_STORE_PROFILE_SUCCESS',
  VIEWED_STORE_PROFILE_ERROR: 'VIEWED_STORE_PROFILE_ERROR',

  VIEWED_PHONE_NUMBER: 'VIEWED_PHONE_NUMBER',
  VIEWED_PHONE_NUMBER_SUCCESS: 'VIEWED_PHONE_NUMBER_SUCCESS',
  VIEWED_PHONE_NUMBER_ERROR: 'VIEWED_PHONE_NUMBER_ERROR',

  GET_STORE_COUPON: 'GET_STORE_COUPON',
  GET_STORE_COUPON_SUCCESS: 'GET_STORE_COUPON_SUCCESS',
  GET_STORE_COUPON_ERROR: 'GET_STORE_COUPON_ERROR',
};

export default onFollowActionTypes;
