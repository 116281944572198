import {all, call, put, select, takeLatest} from 'redux-saga/effects';

import {
  bannerClickedSuccess,
  bannerClickedError,
  catalogClickedSuccess,
  catalogClickedError,
  followStoreSuccess,
  followStoreError,
  viewedStoreProfileSuccess,
  viewedStoreProfileError,
  viewedPhoneNumberSuccess,
  viewedPhoneNumberError,
  getStoreCouponSuccess,
  getStoreCouponError,
} from './followActions';
import {
  BANNER_CLICKED,
  CATALOG_CLICKED,
  FOLLOW_STORE,
  GET_STORE_COUPON,
  VIEWED_PHONE_NUMBER,
  VIEWED_STORE_PROFILE,
} from '../../utils/ApiList';
import Api from '../../middlewares/Api';
import followActionTypes from './followActionTypes';
import {getUserBasicInfo} from '../rootSelector';

const bannerClickedApiCall = async payload => {
  const response = await Api.post(`${BANNER_CLICKED}`, payload);
  return response.data;
};

const catalogClickedApiCall = async payload => {
  const response = await Api.post(`${CATALOG_CLICKED}`, payload);
  return response.data;
};

const followStoreApiCall = async payload => {
  const response = await Api.post(`${FOLLOW_STORE}`, payload);
  return response.data;
};

const viewedStoreProfileApiCall = async payload => {
  const response = await Api.post(`${VIEWED_STORE_PROFILE}`, payload);
  return response.data;
};

const viewedPhoneNumberApiCall = async payload => {
  const response = await Api.post(`${VIEWED_PHONE_NUMBER}`, payload);
  return response.data;
};

const getStoreCouponApiCall = async payload => {
  const response = await Api.post(`${GET_STORE_COUPON}`, payload);
  return response.data;
};

export function* bannerClicked({payload}) {
  try {
    const basic_info = yield select(getUserBasicInfo);
    const response = yield bannerClickedApiCall({
      cus: {
        ...basic_info,
        LoB: basic_info?.LoB ?? '',
      },
      ...payload,
    });
    yield put(bannerClickedSuccess(response));
  } catch (error) {
    yield put(bannerClickedError(error));
  }
}

export function* catalogClicked({payload}) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const response = yield catalogClickedApiCall({
      cus: {
        ...basicInfo,
        id: basicInfo?.id ?? '',
        district: basicInfo?.district ?? 'Gautam Buddha Nagar',
        LoB: basicInfo?.LoB ?? 'Fashion',
      },
      ...payload,
    });
    yield put(catalogClickedSuccess(response));
  } catch (error) {
    yield put(catalogClickedError(error));
  }
}

export function* followStore({payload}) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    yield followStoreApiCall({
      cus: {
        ...basicInfo,
        id: basicInfo?.id ?? '',
        district: basicInfo?.district ?? 'Gautam Buddha Nagar',
        LoB: basicInfo?.LoB ?? 'Fashion',
      },
      data: payload,
    });
    yield put(followStoreSuccess(payload?.id));
  } catch (error) {
    yield put(followStoreError(error));
  }
}

export function* viewedStoreProfile({payload}) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const response = yield viewedStoreProfileApiCall({
      cus: {
        ...basicInfo,
        id: basicInfo?.id ?? '',
        district: basicInfo?.district ?? '',
        LoB: basicInfo?.LoB ?? '',
      },
      data: payload,
    });
    yield put(viewedStoreProfileSuccess(response));
  } catch (error) {
    yield put(viewedStoreProfileError(error));
  }
}

export function* viewedPhoneNumber({payload}) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const response = yield viewedPhoneNumberApiCall({
      cus: {
        ...basicInfo,
        id: basicInfo?.id ?? '',
        district: basicInfo?.district ?? '',
        LoB: basicInfo?.LoB ?? '',
      },
      data: payload,
    });
    yield put(viewedPhoneNumberSuccess(response));
  } catch (error) {
    yield put(viewedPhoneNumberError(error));
  }
}

export function* getStoreCoupon({payload}) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const response = yield getStoreCouponApiCall({
      cus: {
        ...basicInfo,
        id: basicInfo?.id ?? '',
        district: basicInfo?.district ?? '',
        LoB: basicInfo?.LoB ?? '',
      },
      data: payload,
    });
    yield put(getStoreCouponSuccess(response));
  } catch (error) {
    yield put(getStoreCouponError(error));
  }
}

export function* onCatalogClicked() {
  yield takeLatest(followActionTypes.CATALOG_CLICKED, catalogClicked);
}

export function* onBannerClicked() {
  yield takeLatest(followActionTypes.BANNER_CLICKED, bannerClicked);
}

export function* onFollowStore() {
  yield takeLatest(followActionTypes.FOLLOW_STORE, followStore);
}

export function* onViewedStoreProfile() {
  yield takeLatest(followActionTypes.VIEWED_STORE_PROFILE, viewedStoreProfile);
}

export function* onGetStoreCoupon() {
  yield takeLatest(followActionTypes.GET_STORE_COUPON, getStoreCoupon);
}

export function* onViewedPhoneNumber() {
  yield takeLatest(followActionTypes.VIEWED_PHONE_NUMBER, viewedPhoneNumber);
}

export function* followSagas() {
  yield all([
    call(onBannerClicked),
    call(onCatalogClicked),
    call(onFollowStore),
    call(onViewedStoreProfile),
    call(onGetStoreCoupon),
    call(onViewedPhoneNumber),
  ]);
}
