import types from './productActionTypes';

export const getStoreCategories = (body, navigation) => {
  return {
    type: types.GET_STORE_CATEGORIES,
    payload: body,
    action: navigation,
  };
};

export const getStoreCategoriesSuccess = data => {
  return {
    type: types.GET_STORE_CATEGORIES_SUCCESS,
    payload: data,
  };
};

export const getStoreSubCategories = (body, navigation) => {
  return {
    type: types.GET_STORE_SUB_CATEGORIES,
    payload: body,
    action: navigation,
  };
};

export const getStoreSubCategoriesSuccess = data => {
  return {
    type: types.GET_STORE_SUB_CATEGORIES_SUCCESS,
    payload: data,
  };
};

export const getStoreSubCategoriesError = data => {
  return {
    type: types.GET_STORE_SUB_CATEGORIES_ERROR,
    payload: data,
  };
};

export const saveSelectedSubCategory = body => {
  return {
    type: types.SAVE_SELECTED_SUB_CATEGORY,
    payload: body,
  };
};

export const addGeneralProduct = (body, navigation) => {
  return {
    type: types.ADD_GENERAL_PRODUCT,
    payload: body,
    action: navigation,
  };
};

export const addGeneralProductSuccess = data => {
  return {
    type: types.ADD_GENERAL_PRODUCT_SUCCESS,
    payload: data,
  };
};

export const addGeneralProductError = data => {
  return {
    type: types.ADD_GENERAL_PRODUCT_ERROR,
    payload: data,
  };
};

export const getMyGeneralProducts = body => {
  return {
    type: types.GET_MY_GENERAL_PRODUCTS,
    payload: body,
  };
};

export const getMyGeneralProductsSuccess = data => {
  return {
    type: types.GET_MY_GENERAL_PRODUCTS_SUCCESS,
    payload: data,
  };
};

export const updateGeneralProduct = (body, navigation) => {
  return {
    type: types.UPDATE_GENERAL_PRODUCT,
    payload: body,
    action: navigation,
  };
};

export const updateGeneralProductSuccess = data => {
  return {
    type: types.UPDATE_GENERAL_PRODUCT_SUCCESS,
    payload: data,
  };
};

export const updateGeneralProductAvailability = (body, navigation) => {
  return {
    type: types.UPDATE_GENERAL_PRODUCT_AVAILABILITY,
    payload: body,
    action: navigation,
  };
};

export const updateGeneralProductAvailabilitySuccess = data => {
  return {
    type: types.UPDATE_GENERAL_PRODUCT_AVAILABILITY_SUCCESS,
    payload: data,
  };
};

export const deleteGeneralProduct = (body, navigation) => {
  return {
    type: types.DELETE_GENERAL_PRODUCT,
    payload: body,
    action: navigation,
  };
};

export const deleteGeneralProductSuccess = data => {
  return {
    type: types.DELETE_GENERAL_PRODUCT_SUCCESS,
    payload: data,
  };
};

export const deleteGeneralProductError = data => {
  return {
    type: types.DELETE_GENERAL_PRODUCT_ERROR,
    payload: data,
  };
};

export const searchGeneralProducts = body => {
  return {
    type: types.SEARCH_GENERAL_PRODUCTS,
    payload: body,
  };
};

export const searchGeneralProductsSuccess = body => {
  return {
    type: types.SEARCH_GENERAL_PRODUCTS_SUCCESS,
    payload: body,
  };
};

export const searchGeneralProductsError = body => {
  return {
    type: types.SEARCH_GENERAL_PRODUCTS_ERROR,
    payload: body,
  };
};

export const getQuickProducts = (body, navigation) => {
  return {
    type: types.GET_QUICK_PRODUCTS,
    payload: body,
    action: navigation,
  };
};

export const getQuickProductsSuccess = data => {
  return {
    type: types.GET_QUICK_PRODUCTS_SUCCESS,
    payload: data,
  };
};

export const getQuickProductsError = data => {
  return {
    type: types.GET_QUICK_PRODUCTS_ERROR,
    payload: data,
  };
};
