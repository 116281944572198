import types from './bannerActionTypes';

export const getBannerTemplates = body => {
  return {
    type: types.GET_BANNER_TEMPLATES,
    payload: body,
  };
};

export const getBannerTemplatesSuccess = data => {
  return {
    type: types.GET_BANNER_TEMPLATES_SUCCESS,
    payload: data,
  };
};

export const getBannerTemplatesError = error => {
  return {
    type: types.GET_BANNER_TEMPLATES_ERROR,
    payload: error,
  };
};

export const saveDraftBanner = body => {
  return {
    type: types.SAVE_DRAFT_BANNER,
    payload: body,
  };
};

export const saveDraftBannerSuccess = data => {
  return {
    type: types.SAVE_DRAFT_BANNER_SUCCESS,
    payload: data,
  };
};

export const saveDraftBannerError = error => {
  return {
    type: types.SAVE_DRAFT_BANNER_ERROR,
    payload: error,
  };
};

export const createBanner = (body, action) => {
  return {
    type: types.CREATE_BANNER,
    payload: body,
    action: action,
  };
};

export const createBannerSuccess = body => {
  return {
    type: types.CREATE_BANNER_SUCCESS,
    payload: body,
  };
};

export const createBannerError = body => {
  return {
    type: types.CREATE_BANNER_ERROR,
    payload: body,
  };
};

export const getMyBanners = body => {
  return {
    type: types.GET_MY_BANNERS,
    payload: body,
  };
};

export const getMyBannersSuccess = body => {
  return {
    type: types.GET_MY_BANNERS_SUCCESS,
    payload: body,
  };
};

export const getMyBannersError = body => {
  return {
    type: types.GET_MY_BANNERS_ERROR,
    payload: body,
  };
};

export const getOfferingTypes = () => {
  return {
    type: types.GET_OFFERRING_TYPES,
  };
};

export const getOfferingTypesSuccess = body => {
  return {
    type: types.GET_OFFERRING_TYPES_SUCCESS,
    payload: body,
  };
};

export const getOfferingTypesError = body => {
  return {
    type: types.GET_OFFERRING_TYPES_ERROR,
    payload: body,
  };
};

export const getOfferKind = () => {
  return {
    type: types.GET_OFFER_KIND,
  };
};

export const getOfferKindSuccess = body => {
  return {
    type: types.GET_OFFER_KIND_SUCCESS,
    payload: body,
  };
};

export const getOfferKindError = body => {
  return {
    type: types.GET_OFFER_KIND_ERROR,
    payload: body,
  };
};

export const updateBanner = (body, action) => {
  return {
    type: types.UPDATE_BANNER_DETAILS,
    payload: body,
    action: action,
  };
};

export const updateBannerSuccess = body => {
  return {
    type: types.UPDATE_BANNER_DETAILS_SUCCESS,
    payload: body,
  };
};

export const updateBannerError = body => {
  return {
    type: types.UPDATE_BANNER_DETAILS_ERROR,
    payload: body,
  };
};

export const deleteBanner = (body, action) => {
  return {
    type: types.DELETE_BANNER,
    payload: body,
    action: action,
  };
};

export const deleteBannerSuccess = body => {
  return {
    type: types.DELETE_BANNER_SUCCESS,
    payload: body,
  };
};

export const deleteBannerError = body => {
  return {
    type: types.DELETE_BANNER_ERROR,
    payload: body,
  };
};

export const enableBanner = (body, action) => {
  return {
    type: types.ENABLE_BANNER,
    payload: body,
    action: action,
  };
};

export const enableBannerSuccess = body => {
  return {
    type: types.ENABLE_BANNER_SUCCESS,
    payload: body,
  };
};

export const enableBannerError = body => {
  return {
    type: types.ENABLE_BANNER_ERROR,
    payload: body,
  };
};

export const disableBanner = (body, action) => {
  return {
    type: types.DISABLE_BANNER,
    payload: body,
    action: action,
  };
};

export const disableBannerSuccess = body => {
  return {
    type: types.DISABLE_BANNER_SUCCESS,
    payload: body,
  };
};

export const disableBannerError = body => {
  return {
    type: types.DISABLE_BANNER_ERROR,
    payload: body,
  };
};

export const searchBanners = (payload, ownerId) => {
  return {
    type: types.SEARCH_BANNERS,
    payload: {payload, ownerId},
  };
};

export const searchBannersSuccess = body => {
  return {
    type: types.SEARCH_BANNERS_SUCCESS,
    payload: body,
  };
};

export const searchBannersError = body => {
  return {
    type: types.SEARCH_BANNERS_ERROR,
    payload: body,
  };
};
