import axios from "axios";
import { all, call, put, select, takeLatest } from "redux-saga/effects";

import {
  customerGeneralValidateOtpError,
  customerGeneralValidateOtpSuccess,
  generalValidateOtpError,
  generalValidateOtpSuccess,
  getCustomerDistrictError,
  getCustomerDistrictSuccess,
  getCustomerOtpError,
  getCustomerOtpSuccess,
  getOtpSuccess,
  logInFailure,
  logInSuccess,
  refreshTokenError,
  refreshTokenSuccess,
  registerFailure,
  registerSuccess,
  signUpBOfromCustomerError,
  signUpBOfromCustomerSuccess,
  signUpCustomerFromBOError,
  signUpCustomerFromBOSuccess,
  socialSignUpError,
  socialSignUpSuccess,
  validateCustomerOtpError,
  validateCustomerOtpSuccess,
  validateOtpSuccess,
  getCustomerDistrict as getCustomerDistrictAction,
  getOtpError,
} from "./authActions";
import types from "./authActionTypes";
import {
  CUSTOMER_GENERAL_VALIDATE_OTP,
  CUSTOMER_REFRESH_TOKEN,
  GENERAL_VALIDATE_OTP,
  GET_CUSTOMER_DISTRICT,
  GET_CUSTOMER_OTP,
  GET_OTP,
  REFRESH_TOKEN,
  SIGNUP_BO_FROM_CUSTOMER,
  SIGNUP_CUSTOMER_FROM_BO,
  SOCIAL_SIGNUP,
  VALIDATE_CUSTOMER_OTP,
  VALIDATE_OTP,
} from "../../utils/ApiList";
import Api from "../../middlewares/Api";
import { getCurrentUser, getRole, getUserBasicInfo } from "../rootSelector";
import { clearOwnerDetails } from "../onBoarding/onBoardingActions";
import CryptoJS from "crypto-js";

const getOtp = async (payload) => {
  try {
    const response = await Api.post(GET_OTP, payload);
    return { id: response.data.id };
  } catch (error) {
    console.log(error);
  }
};

const getCustomerOtpApiCall = async (payload) => {
  try {
    const response = await Api.post(GET_CUSTOMER_OTP, payload);
    return { id: response.data.id };
  } catch (error) {
    console.log(error);
  }
};

const socialSignUpApiCall = async (payload) => {
  try {
    const response = await Api.post(SOCIAL_SIGNUP, payload);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const validateOtp = async ({ payload }) => {
  const response = await Api.get(
    `${VALIDATE_OTP}?id=${encodeURIComponent(
      payload.id
    )}&otp=${encodeURIComponent(payload.otp)}`
  );
  return {
    access_token: response.data.access_token,
    refresh_token: response.data.refresh_token,
    ...response.data,
  };
};

const validateCustomerOtpApiCall = async ({ payload }) => {
  const response = await Api.get(
    `${VALIDATE_CUSTOMER_OTP}?id=${encodeURIComponent(
      payload.id
    )}&otp=${encodeURIComponent(payload.otp)}`
  );
  return {
    access_token: response.data.access_token,
    refresh_token: response.data.refresh_token,
    ...response.data,
  };
};

const generalValidateOtpApiCall = async (payload) => {
  const response = await Api.get(
    `${GENERAL_VALIDATE_OTP}?id=${encodeURIComponent(
      payload.id
    )}&otp=${encodeURIComponent(payload.otp)}`
  );
  return response.data;
};

const customerGeneralValidateOtpApiCall = async (payload) => {
  const response = await Api.get(
    `${CUSTOMER_GENERAL_VALIDATE_OTP}?id=${encodeURIComponent(
      payload.id
    )}&otp=${encodeURIComponent(payload.otp)}`
  );
  return response.data;
};

const logIn = async (email, password) => {
  const response = await axios.post("/login", {
    email,
    password,
  });
  return { token: response.data.access_token };
};

const register = async (email, password) => {
  await axios.post("/register", {
    email,
    password,
  });
};

const refreshTokenApiCall = async (role, payload) => {
  try {
    const response = await Api.post(
      role === "BusinessOwner" ? REFRESH_TOKEN : CUSTOMER_REFRESH_TOKEN,
      payload
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const signUpCustomerFromBOApiCall = async (payload) => {
  try {
    const response = await Api.post(SIGNUP_CUSTOMER_FROM_BO, payload);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const signUpBOFromCustomerApiCall = async (payload) => {
  try {
    const response = await Api.post(SIGNUP_BO_FROM_CUSTOMER, payload);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

const getCustomerDistrictApiCall = async (payload) => {
  try {
    const response = await Api.post(GET_CUSTOMER_DISTRICT, payload);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export function* logInWithCredentials({ payload: { email, password } }) {
  try {
    const user = yield logIn(email, password);
    yield put(logInSuccess(user));
  } catch (error) {
    yield put(logInFailure(error));
  }
}

export function* registerWithCredentials({ payload: { email, password } }) {
  try {
    yield register(email, password);
    yield put(registerSuccess({ email, password }));
  } catch (error) {
    yield put(registerFailure(error));
  }
}

export function* logInAfterRegister({ payload: { email, password } }) {
  yield logInWithCredentials({ payload: { email, password } });
}

export function* getOtpWithCredentials({ payload, action }) {
  try {
    const user = yield getOtp(payload);
    yield put(getOtpSuccess(user));
    action.navigate("Otp");
  } catch (error) {
    yield put(logInFailure(error));
    yield put(getOtpError(error));
  }
}

export function* getCustomerOtp({ payload, action }) {
  try {
    const user = yield getCustomerOtpApiCall(payload);
    yield put(getCustomerOtpSuccess(user));
    action.navigate("Otp");
  } catch (error) {
    yield put(getCustomerOtpError(error));
  }
}

export function* validateOtpWithCredentials(payload) {
  try {
    const user = yield validateOtp(payload);
    yield put(validateOtpSuccess(user));
  } catch (error) {
    yield put(logInFailure(error));
  }
}

export function* validateCustomerOtp(payload) {
  try {
    const user = yield validateCustomerOtpApiCall(payload);
    yield put(validateCustomerOtpSuccess(user));
  } catch (error) {
    yield put(validateCustomerOtpError(error));
  }
}

export function* refreshToken() {
  try {
    const basic_info = yield select(getUserBasicInfo);
    const role = yield select(getRole);
    const user = yield refreshTokenApiCall(role, basic_info);
    yield put(refreshTokenSuccess(user));
  } catch (error) {
    yield put(refreshTokenError(error));
  }
}

export function* generalValidateOtp({ payload }) {
  try {
    const user = yield generalValidateOtpApiCall(payload);
    if (user) {
      var Base64CBC = user.owner.user;
      var iv = CryptoJS.enc.Utf8.parse(user.owner.db_verify[1]);
      var key = user.owner.db_verify[0]; //key used in Python
      key = CryptoJS.enc.Utf8.parse(key);
      var decrypted = CryptoJS.AES.decrypt(Base64CBC, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
      });
      decrypted = decrypted.toString(CryptoJS.enc.Utf8);
      user.owner.user = JSON.parse(decrypted);

      var ivCustomer = CryptoJS.enc.Utf8.parse(user.customer.db_verify[1]);
      var keyCustomer = user.customer.db_verify[0]; //key used in Python
      keyCustomer = CryptoJS.enc.Utf8.parse(keyCustomer);
      let decryptedCustomer = CryptoJS.AES.decrypt(
        user.customer.user,
        keyCustomer,
        {
          iv: ivCustomer,
          mode: CryptoJS.mode.CBC,
        }
      );
      decryptedCustomer = decryptedCustomer.toString(CryptoJS.enc.Utf8);
      user.customer.user = JSON.parse(decryptedCustomer);

      yield put(generalValidateOtpSuccess(user));
    }
  } catch (error) {
    yield put(generalValidateOtpError(error));
  }
}

export function* customerGeneralValidateOtp({ payload, action }) {
  try {
    const user = yield customerGeneralValidateOtpApiCall(payload);

    var Base64CBC = user.owner.user;
    var ivOwner = CryptoJS.enc.Utf8.parse(user.owner.db_verify[1]);
    var keyOwner = user.owner.db_verify[0]; //key used in Python
    keyOwner = CryptoJS.enc.Utf8.parse(keyOwner);
    var decrypted = CryptoJS.AES.decrypt(Base64CBC, keyOwner, {
      iv: ivOwner,
      mode: CryptoJS.mode.CBC,
    });
    decrypted = decrypted.toString(CryptoJS.enc.Utf8);
    user.owner.user = JSON.parse(decrypted);

    var iv = CryptoJS.enc.Utf8.parse(user.customer.db_verify[1]);
    var key = user.customer.db_verify[0]; //key used in Python
    key = CryptoJS.enc.Utf8.parse(key);

    let decryptedCustomer = CryptoJS.AES.decrypt(user.customer.user, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
    });
    decryptedCustomer = decryptedCustomer.toString(CryptoJS.enc.Utf8);
    user.customer.user = JSON.parse(decryptedCustomer);

    yield put(customerGeneralValidateOtpSuccess(user));
    if (action) {
      action.push("/");
    }
  } catch (error) {
    yield put(customerGeneralValidateOtpError(error));
  }
}

export function* socialSignUp({ payload }) {
  try {
    const user = yield socialSignUpApiCall(payload);
    yield put(socialSignUpSuccess(user));
  } catch (error) {
    yield put(socialSignUpError(error));
  }
}

export function* signUpCustomerFromBO() {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const phoneNumber = yield select(getCurrentUser);
    // const token = yield messaging().getToken();
    const user = yield signUpCustomerFromBOApiCall({
      signup: {
        name: "",
        image_url: "",
        signup_email_handle: phoneNumber?.mobile_number + "",
        signup_method: "Phone",
        device_token: "",
      },
      data: basicInfo,
    });

    yield put(clearOwnerDetails());
    user.salt = user.salt.slice(2, user.salt.length - 1);
    var Base64CBC = user.user.slice(2, user.user.length - 1);
    user.db_verify = user.db_verify.map((byteObject) =>
      byteObject.substring(2, byteObject.length - 1)
    );

    var iv = CryptoJS.enc.Utf8.parse(user.db_verify[1]);
    var key = user.db_verify[0];
    key = CryptoJS.enc.Utf8.parse(key);
    var decrypted = CryptoJS.AES.decrypt(Base64CBC, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
    });
    decrypted = decrypted.toString(CryptoJS.enc.Utf8);
    user.user = JSON.parse(decrypted);
    yield put(signUpCustomerFromBOSuccess(user));
  } catch (error) {
    yield put(signUpCustomerFromBOError(error));
  }
}

export function* signUpBOFromCustomer() {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const user = yield signUpBOFromCustomerApiCall({ data: basicInfo });
    user.salt = user.salt.slice(2, user.salt.length - 1);
    var Base64CBC = user.user.slice(2, user.user.length - 1);
    const stringRepresentation = user.db_verify;
    const content = stringRepresentation.substring(
      1,
      stringRepresentation.length - 1
    );
    const byteObjects = content.split(", ");
    user.db_verify = byteObjects.map((byteObject) =>
      byteObject.substring(2, byteObject.length - 1)
    );

    var iv = CryptoJS.enc.Utf8.parse(user.db_verify[1]);
    var key = user.db_verify[0];
    key = CryptoJS.enc.Utf8.parse(key);
    var decrypted = CryptoJS.AES.decrypt(Base64CBC, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
    });
    decrypted = decrypted.toString(CryptoJS.enc.Utf8);
    user.user = JSON.parse(decrypted);
    yield put(signUpBOfromCustomerSuccess(user));
  } catch (error) {
    console.log("Error :-", error);
    yield put(signUpBOfromCustomerError(error));
  }
}

export function* getCustomerDistrict({ payload }) {
  try {
    const basicInfo = yield select(getUserBasicInfo);
    const customerDistrict = yield getCustomerDistrictApiCall({
      ...basicInfo,
      lat: payload?.lat ?? 0,
      log: payload?.lon ?? 0,
    });
    yield put(getCustomerDistrictSuccess(customerDistrict));
  } catch (error) {
    yield put(getCustomerDistrictError(error));
  }
}

export function* onGetOtp() {
  yield takeLatest(types.GET_OTP, getOtpWithCredentials);
}

export function* onGetCustomerOtp() {
  yield takeLatest(types.GET_CUSTOMER_OTP, getCustomerOtp);
}

export function* onValidateOtp() {
  yield takeLatest(types.VALIDATE_OTP, validateOtpWithCredentials);
}

export function* onValidatCustomereOtp() {
  yield takeLatest(types.VALIDATE_CUSTOMER_OTP, validateCustomerOtp);
}

export function* onLogInStart() {
  yield takeLatest(types.LOG_IN_START, logInWithCredentials);
}

export function* onRegisterStart() {
  yield takeLatest(types.REGISTER_START, registerWithCredentials);
}

export function* onRegisterSuccess() {
  yield takeLatest(types.REGISTER_SUCCESS, logInAfterRegister);
}

export function* onGetOtpSuccess() {
  yield takeLatest(types.GET_OTP_SUCCESS, onGetOtp);
}

export function* onRefreshToken() {
  yield takeLatest(types.REFRESH_TOKEN, refreshToken);
}

export function* onGeneralValidateOtp() {
  yield takeLatest(types.GENERAL_VALIDATE_OTP, generalValidateOtp);
}

export function* onCustomerGeneralValidateOtp() {
  yield takeLatest(
    types.CUSTOMER_GENERAL_VALIDATE_OTP,
    customerGeneralValidateOtp
  );
}

export function* onSocialSignUp() {
  yield takeLatest(types.SOCIAL_SIGNUP, socialSignUp);
}

export function* onSignUpCustomerFromBO() {
  yield takeLatest(types.SIGNUP_CUSTOMER_FROM_BO, signUpCustomerFromBO);
}

export function* onSignUpBOFromCustomer() {
  yield takeLatest(types.SIGNUP_BO_FROM_CUSTOMER, signUpBOFromCustomer);
}

export function* onGetCustomerDistrict() {
  yield takeLatest(types.GET_CUSTOMER_DISTRICT, getCustomerDistrict);
}

export function* authSagas() {
  yield all([
    call(onGetOtp),
    call(onValidateOtp),
    call(onLogInStart),
    call(onRegisterStart),
    call(onRegisterSuccess),
    call(onGetCustomerOtp),
    call(onValidatCustomereOtp),
    call(onRefreshToken),
    call(onGeneralValidateOtp),
    call(onCustomerGeneralValidateOtp),
    call(onSocialSignUp),
    call(onSignUpCustomerFromBO),
    call(onSignUpBOFromCustomer),
    call(onGetCustomerDistrict),
  ]);
}
