const catalogActionTypes = {
  CREATE_CATALOG: "CREATE_CATALOG",
  CREATE_CATALOG_SUCCESS: "CREATE_CATALOG_SUCCESS",
  CREATE_CATALOG_ERROR: "CREATE_CATALOG_ERROR",
  ADD_CATALOG_PRODUCT: "ADD_CATALOG_PRODUCT",
  ADD_CATALOG_PRODUCT_SUCCESS: "ADD_CATALOG_PRODUCT_SUCCESS",
  ADD_CATALOG_PRODUCT_ERROR: "ADD_CATALOG_PRODUCT_ERROR",
  GET_MY_CATALOGS: "GET_MY_CATALOGS",
  GET_MY_CATALOGS_SUCCESS: "GET_MY_CATALOGS_SUCCESS",
  GET_MY_CATALOGS_ERROR: "GET_MY_CATALOGS_ERROR",
  UPDATE_CATALOG_PRODUCT: "UPDATE_CATALOG_PRODUCT",
  UPDATE_CATALOG_PRODUCT_SUCCESS: "UPDATE_CATALOG_PRODUCT_SUCCESS",
  UPDATE_CATALOG_PRODUCT_ERROR: "UPDATE_CATALOG_PRODUCT_ERROR",
  UPDATE_CATALOG_PRODUCT_AVAILABILITY: "UPDATE_CATALOG_PRODUCT_AVAILABILITY",
  UPDATE_CATALOG_PRODUCT_AVAILABILITY_SUCCESS:
    "UPDATE_CATALOG_PRODUCT_AVAILABILITY_SUCCESS",
  UPDATE_CATALOG_PRODUCT_AVAILABILITY_ERROR:
    "UPDATE_CATALOG_PRODUCT_AVAILABILITY_ERROR",
  EDIT_CATALOG_SUBCATEGORY: "EDIT_CATALOG_SUBCATEGORY",
  EDIT_CATALOG_SUBCATEGORY_SUCCESS: "EDIT_CATALOG_SUBCATEGORY_SUCCESS",
  EDIT_CATALOG_SUBCATEGORY_ERROR: "EDIT_CATALOG_SUBCATEGORY_ERROR",

  ENABLE_CATALOG: "ENABLE_CATALOG",
  ENABLE_CATALOG_SUCCESS: "ENABLE_CATALOG_SUCCESS",
  ENABLE_CATALOG_ERROR: "ENABLE_CATALOG_ERROR",

  DISABLE_CATALOG: "DISABLE_CATALOG",
  DISABLE_CATALOG_SUCCESS: "DISABLE_CATALOG_SUCCESS",
  DISABLE_CATALOG_ERROR: "DISABLE_CATALOG_ERROR",

  DELETE_CATALOG_PRODUCT: "DELETE_CATALOG_PRODUCT",
  DELETE_CATALOG_PRODUCT_SUCCESS: "DELETE_CATALOG_PRODUCT_SUCCESS",
  DELETE_CATALOG_PRODUCT_ERROR: "DELETE_CATALOG_PRODUCT_ERROR",

  DELETE_CATALOG: "DELETE_CATALOG",
  DELETE_CATALOG_SUCCESS: "DELETE_CATALOG_SUCCESS",
  DELETE_CATALOG_ERROR: "DELETE_CATALOG_ERROR",

  SEARCH_CATALOGS: "SEARCH_CATALOGS",
  SEARCH_CATALOGS_SUCCESS: "SEARCH_CATALOGS_SUCCESS",
  SEARCH_CATALOGS_ERROR: "SEARCH_CATALOGS_ERROR",

  GET_NEARBY_STORES: "GET_NEARBY_STORES",
  GET_NEARBY_STORES_SUCCESS: "GET_NEARBY_STORES_SUCCESS",
  GET_NEARBY_STORES_ERROR: "GET_NEARBY_STORES_ERROR",

  GET_CUSTOMER_CATALOG_PRODUCTS: "GET_CUSTOMER_CATALOG_PRODUCTS",
  GET_CUSTOMER_CATALOG_PRODUCTS_SUCCESS:
    "GET_CUSTOMER_CATALOG_PRODUCTS_SUCCESS",
  GET_CUSTOMER_CATALOG_PRODUCTS_ERROR: "GET_CUSTOMER_CATALOG_PRODUCTS_ERROR",

  GET_CUSTOMER_CATALOG_PRODUCT: "GET_CUSTOMER_CATALOG_PRODUCT",
  GET_CUSTOMER_CATALOG_PRODUCT_SUCCESS: "GET_CUSTOMER_CATALOG_PRODUCT_SUCCESS",
  GET_CUSTOMER_CATALOG_PRODUCT_ERROR: "GET_CUSTOMER_CATALOG_PRODUCT_ERROR",

  SET_SEARCH_STRING: "SET_SEARCH_STRING",
};

export default catalogActionTypes;
