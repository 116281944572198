import types from './bannerActionTypes';

const INITIAL_STATE = {
  templates: [],
};

const bannerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_BANNER_TEMPLATES:
      return {
        ...state,
        templates: {
          isLoading: true,
          data: [],
          error: null,
        },
      };
    case types.GET_BANNER_TEMPLATES_SUCCESS:
      return {
        ...state,
        templates: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.GET_BANNER_TEMPLATES_ERROR:
      return {
        ...state,
        templates: {
          isLoading: false,
          data: [],
          error: action.payload,
        },
      };
    case types.SAVE_DRAFT_BANNER:
      return {
        ...state,
        draftBanner: {
          data: action.payload,
        },
      };
    case types.CREATE_BANNER:
      return {
        ...state,
        banner: {
          isLoading: true,
          data: action.payload,
          error: null,
        },
      };
    case types.CREATE_BANNER_SUCCESS:
      return {
        ...state,
        banner: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.CREATE_BANNER_ERROR:
      return {
        ...state,
        banner: {
          isLoading: false,
          error: action.payload,
        },
      };
    case types.GET_MY_BANNERS:
      return {
        ...state,
        myBanners: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_MY_BANNERS_SUCCESS:
      return {
        ...state,
        myBanners: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.GET_MY_BANNERS_ERROR:
      return {
        ...state,
        banner: {
          isLoading: false,
          error: action.payload,
        },
      };
    case types.GET_OFFERRING_TYPES:
      return {
        ...state,
        offerringTypes: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_OFFERRING_TYPES_SUCCESS:
      return {
        ...state,
        offerringTypes: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.GET_OFFERRING_TYPES_ERROR:
      return {
        ...state,
        offerringTypes: {
          isLoading: false,
          error: action.payload,
        },
      };
    case types.GET_OFFER_KIND:
      return {
        ...state,
        offerKind: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_OFFER_KIND_SUCCESS:
      return {
        ...state,
        offerKind: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.GET_OFFER_KIND_ERROR:
      return {
        ...state,
        offerKind: {
          ...state?.offerKind,
          isLoading: false,
          error: action.payload,
        },
      };
    case types.SEARCH_BANNERS:
      return {
        ...state,
        customer: {
          banners: {
            isLoading: true,
            error: null,
          },
        },
      };
    case types.SEARCH_BANNERS_SUCCESS:
      return {
        ...state,
        customer: {
          banners: {
            isLoading: false,
            data: action.payload,
            error: null,
          },
        },
      };
    case types.SEARCH_BANNERS_ERROR:
      return {
        ...state,
        customer: {
          banners: {
            isLoading: false,
            error: action.payload,
          },
        },
      };
    default:
      return state;
  }
};

export default bannerReducer;
