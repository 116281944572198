const bannerActionTypes = {
  GET_BANNER_TEMPLATES: 'GET_BANNER_TEMPLATES',
  GET_BANNER_TEMPLATES_SUCCESS: 'GET_BANNER_TEMPLATES_SUCCESS',
  GET_BANNER_TEMPLATES_ERROR: 'GET_BANNER_TEMPLATES_ERROR',

  SAVE_DRAFT_BANNER: 'SAVE_CREATE_BANNER',
  SAVE_DRAFT_BANNER_SUCCESS: 'SAVE_CREATE_BANNER_SUCCESS',
  SAVE_DRAFT_BANNER_ERROR: 'SAVE_CREATE_BANNER_ERROR',

  CREATE_BANNER: 'CREATE_BANNER',
  CREATE_BANNER_SUCCESS: 'CREATE_BANNER_SUCCESS',
  CREATE_BANNER_ERROR: 'CREATE_BANNER_ERROR',

  ADD_BANNER_DP: 'ADD_BANNER_DP',
  ADD_BANNER_DP_SUCCESS: 'ADD_BANNER_DP_SUCCESS',

  PUBLISH_BANNER: 'PUBLISH_BANNER',
  PUBLISH_BANNER_SUCCESS: 'PUBLISH_BANNER_SUCCESS',

  GET_MY_BANNERS: 'GET_MY_BANNERS',
  GET_MY_BANNERS_SUCCESS: 'GET_MY_BANNERS_SUCCESS',
  GET_MY_BANNERS_ERROR: 'GET_MY_BANNERS_ERROR',

  GET_BANNER_DP: 'GET_BANNER_DP',
  GET_BANNER_DP_SUCCESS: 'GET_BANNER_DP_SUCCESS',

  UPDATE_BANNER_DETAILS: 'UPDATE_BANNER_DETAILS',
  UPDATE_BANNER_DETAILS_SUCCESS: 'UPDATE_BANNER_DETAILS_SUCCESS',
  UPDATE_BANNER_DETAILS_ERROR: 'UPDATE_BANNER_DETAILS_ERROR',

  DELETE_BANNER: 'DELETE_BANNER',
  DELETE_BANNER_SUCCESS: 'DELETE_BANNER_SUCCESS',
  DELETE_BANNER_ERROR: 'DELETE_BANNER_ERROR',

  ENABLE_BANNER: 'ENABLE_BANNER',
  ENABLE_BANNER_SUCCESS: 'ENABLE_BANNER_SUCCESS',
  ENABLE_BANNER_ERROR: 'ENABLE_BANNER_ERROR',

  DISABLE_BANNER: 'DISABLE_BANNER',
  DISABLE_BANNER_SUCCESS: 'DISABLE_BANNER_SUCCESS',
  DISABLE_BANNER_ERROR: 'DISABLE_BANNER_ERROR',

  GET_OFFERRING_TYPES: 'GET_OFFERRING_TYPES',
  GET_OFFERRING_TYPES_SUCCESS: 'GET_OFFERRING_TYPES_SUCCESS',
  GET_OFFERRING_TYPES_ERROR: 'GET_OFFERRING_TYPES_ERROR',

  GET_OFFER_KIND: 'GET_OFFER_KIND',
  GET_OFFER_KIND_SUCCESS: 'GET_OFFER_KIND_SUCCESS',
  GET_OFFER_KIND_ERROR: 'GET_OFFER_KIND_ERROR',

  SEARCH_BANNERS: 'SEARCH_BANNERS',
  SEARCH_BANNERS_SUCCESS: 'SEARCH_BANNERS_SUCCESS',
  SEARCH_BANNERS_ERROR: 'SEARCH_BANNERS_ERROR',
};

export default bannerActionTypes;
