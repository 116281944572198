import types from './insightsActionTypes';

const INITIAL_STATE = {
  templates: [],
};

const insightsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.GET_OVERALL_STORE_STATS:
      return {
        ...state,
        overallStoreStats: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_OVERALL_STORE_STATS_SUCCESS:
      return {
        ...state,
        overallStoreStats: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.GET_OVERALL_STORE_STATS_ERROR:
      return {
        ...state,
        overallStoreStats: {
          ...state?.overallStoreStats,
          isLoading: false,
          error: action.payload,
        },
      };
    case types.GET_CATALOG_DAILY_TRENDS:
      return {
        ...state,
        catalogDailyTrends: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_CATALOG_DAILY_TRENDS_SUCCESS:
      return {
        ...state,
        catalogDailyTrends: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.GET_CATALOG_DAILY_TRENDS_ERROR:
      return {
        ...state,
        catalogDailyTrends: {
          ...state?.catalogDailyTrends,
          isLoading: false,
          error: action.payload,
        },
      };
    case types.GET_FINANCIAL_DAILY_TRENDS:
      return {
        ...state,
        financialDailyTrends: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_FINANCIAL_DAILY_TRENDS_SUCCESS:
      return {
        ...state,
        financialDailyTrends: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.GET_FINANCIAL_DAILY_TRENDS_ERROR:
      return {
        ...state,
        financialDailyTrends: {
          ...state?.financialDailyTrends,
          isLoading: false,
          error: action.payload,
        },
      };
    case types.GET_BANNER_DAILY_TRENDS:
      return {
        ...state,
        bannerDailyTrends: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_BANNER_DAILY_TRENDS_SUCCESS:
      return {
        ...state,
        bannerDailyTrends: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.GET_BANNER_DAILY_TRENDS_ERROR:
      return {
        ...state,
        bannerDailyTrends: {
          ...state?.bannerDailyTrends,
          isLoading: false,
          error: action.payload,
        },
      };
    case types.GET_STORE_SOCIAL_HEALTH:
      return {
        ...state,
        storeSocialHealth: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_STORE_SOCIAL_HEALTH_SUCCESS:
      return {
        ...state,
        storeSocialHealth: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.GET_STORE_SOCIAL_HEALTH_ERROR:
      return {
        ...state,
        storeSocialHealth: {
          ...state?.storeSocialHealth,
          isLoading: false,
          error: action.payload,
        },
      };
    case types.GET_CATALOG_INSIGHTS:
      return {
        ...state,
        catalogInsights: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_CATALOG_INSIGHTS_SUCCESS:
      return {
        ...state,
        catalogInsights: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.GET_CATALOG_INSIGHTS_ERROR:
      return {
        ...state,
        catalogInsights: {
          ...state?.catalogInsights,
          isLoading: false,
          error: action.payload,
        },
      };
    case types.GET_CATALOG_TOP_PRODUCTS:
      return {
        ...state,
        catalogTopProducts: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_CATALOG_TOP_PRODUCTS_SUCCESS:
      return {
        ...state,
        catalogTopProducts: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.GET_CATALOG_TOP_PRODUCTS_ERROR:
      return {
        ...state,
        catalogTopProducts: {
          ...state?.catalogTopProducts,
          isLoading: false,
          error: action.payload,
        },
      };
    case types.GET_BANNER_INSIGHTS:
      return {
        ...state,
        bannerInsights: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_BANNER_INSIGHTS_SUCCESS:
      return {
        ...state,
        bannerInsights: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.GET_BANNER_INSIGHTS_ERROR:
      return {
        ...state,
        bannerInsights: {
          ...state?.bannerInsights,
          isLoading: false,
          error: action.payload,
        },
      };
    case types.GET_ALL_CATALOG_DAILY_TRENDS:
      return {
        ...state,
        allCatalogDailyTrends: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_ALL_CATALOG_DAILY_TRENDS_SUCCESS:
      return {
        ...state,
        allCatalogDailyTrends: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.GET_ALL_CATALOG_DAILY_TRENDS_ERROR:
      return {
        ...state,
        allCatalogDailyTrends: {
          ...state?.allCatalogDailyTrends,
          isLoading: false,
          error: action.payload,
        },
      };
    case types.GET_ALL_BANNER_DAILY_TRENDS:
      return {
        ...state,
        allBannerDailyTrends: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_ALL_BANNER_DAILY_TRENDS_SUCCESS:
      return {
        ...state,
        allBannerDailyTrends: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.GET_ALL_BANNER_DAILY_TRENDS_ERROR:
      return {
        ...state,
        allBannerDailyTrends: {
          ...state?.allBannerDailyTrends,
          isLoading: false,
          error: action.payload,
        },
      };
    case types.GET_OVERALL_STATS:
      return {
        ...state,
        overallStats: {
          isLoading: true,
          error: null,
        },
      };
    case types.GET_OVERALL_STATS_SUCCESS:
      return {
        ...state,
        overallStats: {
          isLoading: false,
          data: action.payload,
          error: null,
        },
      };
    case types.GET_OVERALL_STATS_ERROR:
      return {
        ...state,
        overallStats: {
          ...state?.overallStats,
          isLoading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default insightsReducer;
